export const checkUserPlanAccess = (planName, planDetails, featureName, featureCount) => {
	if (planName) {
		if (featureCount) {
			if (planDetails.features[featureName] < 0) {
				return 2;
			} else {
				return featureCount < planDetails.features[featureName];
			}
		} else {
			return planDetails.features[featureName];
		}
	} else {
		return 2;
	}
}

export const checkCompanyFeatureAccess = (companyFeatures, featureName, featureCount) => {
	if (companyFeatures.hasOwnProperty(featureName)) {
		if (featureCount) {
			if (companyFeatures[featureName] < 0) {
				return 2;
			} else {
				return featureCount < companyFeatures[featureName];
			}
		} else {
			return companyFeatures[featureName];
		}
	} else {
		return 2;
	}
}