import { createSelector } from 'reselect';
const selectApp = (state) => state;

export const getConvoList = () => createSelector(selectApp, (appState) => appState.get('convo').toJS());

export const getCompanyBots = () => createSelector(selectApp, (appState) => appState.get('companyBots').toJS());

export const getFunnelData = () => createSelector(selectApp, (appState) => appState.get('funnelData').toJS());

export const getUserName = () => createSelector(selectApp, (appState) => appState.get('userName'));

export const getUserRole = () => createSelector(selectApp, (appState) => appState.get('userRole'));

export const getUserEmail = () => createSelector(selectApp, (appState) => appState.get('userEmail'));

export const getCandidatesList = () => createSelector(selectApp, (appState) => appState.get('candidates').toJS());

export const getDriveInvitedList = () => createSelector(selectApp, (appState) => appState.get('driveInvitedCount'));

export const getDriveSubmittedList = () => createSelector(selectApp, (appState) => appState.get('driveSubmittedCount'));

export const getSuccessMsg = () => createSelector(selectApp, (appState) => appState.get('successMsg'));

export const getRerender = () => createSelector(selectApp, (appState) => appState.get('rerender'));

export const getIsAuthenticated = () => createSelector(selectApp, (appState) => appState.get('isAuthenticated'));

export const getAuthLoading = () => createSelector(selectApp, (appState) => appState.get('authLoading'));

export const getAuthErrorMsg = () => createSelector(selectApp, (appState) => appState.get('authErrorMsg'));

export const getAuthSuccessMsg = () => createSelector(selectApp, (appState) => appState.get('authSuccessMsg'));

export const getIsReportModelOpen = () => createSelector(selectApp, (appState) => appState.get('isReportModelOpen'));

export const getNotificationList = () => createSelector(selectApp, (appState) => appState.get('notifications').toJS());

export const getSettingsLoading = () => createSelector(selectApp, (appState) => appState.get('settingsLoading'));

export const getShowVerificationSuccess = () => createSelector(selectApp, (appState) => appState.get('showVerificationSuccess'));

export const getUserVerificationLoading = () => createSelector(selectApp, (appState) => appState.get('userVerificationLoading'));

export const getAssessmentHistory = () => createSelector(selectApp, (appState) => appState.get('assessmentHistory'));

export const getSettings = () => createSelector(selectApp, (appState) => appState.get('settings').toJS());

export const getDriveDetails = () => createSelector(selectApp, (appState) => appState.get('driveDetails').toJS());

export const getUserPlan = () => createSelector(selectApp, (appState) => appState.get('userPlan').toJS());

export const getTotalAssessments = () => createSelector(selectApp, (appState) => appState.get('totalAssessments'));

export const getSuperUserList = () => createSelector(selectApp, (appState) => appState.get('superUser').toJS());

export const getCompaniesList = () => createSelector(selectApp, (appState) => appState.get('companies').toJS());

export const getFeedbackList = () => createSelector(selectApp, (appState) => appState.get('feedback').toJS());

export const getMyDetails = () => createSelector(selectApp, (appState) => appState.get('myDetails').toJS());

export const getPreScreeningBots = () => createSelector(selectApp, (appState) => appState.get('preScreeningBots').toJS());

export const getPreScreeningChatData = () => createSelector(selectApp, (appState) => appState.get('preScreeningChatData').toJS());

export const getCurrentBotQuestion = () => createSelector(selectApp, (appState) => appState.get('currentBotQuestion'));

export const getSaasPlanList = () => createSelector(selectApp, (appState) => appState.get('saasPlanList').toJS());

export const getSaasPlanDetails = () => createSelector(selectApp, (appState) => appState.get('saasPlanDetails').toJS());

export const getDashboardLogo = () => createSelector(selectApp, (appState) => appState.get('dashboardLogo'));

export const getJobRoleDetails = () => createSelector(selectApp, (appState) => appState.get('jobRoleDetails').toJS());

export const getCreateJobLoading = () => createSelector(selectApp, (appState) => appState.get('createJobLoading'));

export const getTrialsUsersList = () => createSelector(selectApp, (appState) => appState.get('trialsUsersList').toJS());

export const getCompanyDetails = () => createSelector(selectApp, (appState) => appState.get('companyDetails').toJS());

export const getCompanyFeatures = () => createSelector(selectApp, (appState) => appState.get('companyFeatures').toJS());

export const getCurrentWalkthroughStep = () => createSelector(selectApp, (appState) => appState.get('currentWalkthroughStep'));
