import React from 'react'
import Navbar2 from '../components/Navbar2'
import DemoSignIn from '../components/DemoSignIn'
import { useParams } from 'react-router-dom';

const SignInPage = () => {
  const {workflowId}=useParams()
  return (
    <div style={{background: "#fff", minHeight:"100vh", height:"100%", width:"100%"}}>
      {/* <Navbar2/> */}
      <DemoSignIn workflowId={workflowId}/>
    </div>
  )
}

export default SignInPage
