import React from 'react'
import Navbar2 from '../components/Navbar2'
import SignUp from '../components/SignUp'

const SignUpPage = () => {
  return (
    <div style={{background: "whitesmoke", minHeight:"100vh", height:"100%"}}>
      <Navbar2/>
      <SignUp/>
    </div>
  )
}

export default SignUpPage
