import React, { useState } from 'react';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { useTour } from '@reactour/tour';

import { getUserName, getUserRole } from '../../selectors/app';
import { logoutUser as logoutUserRequest } from '../../actions/app';

import changepasswordicon from '../../assets/changepasswordicon.svg';
import knowledgebaseicon from '../../assets/knowledgebaseicon.svg';
import logouticon from '../../assets/logouticon.svg';
import quickguideicon from '../../assets/quickguideicon.svg';
import userIcon from '../../assets/User_fill.svg';

import './accountmenu.scss';

const AccountMenu = (props) => {
	const { userName, userRole, logoutUser, startTutorial } = props;

	const { setIsOpen, setCurrentStep, steps } = useTour();
	const location = useLocation();
	const history = useHistory();

	const [anchorEl, setAnchorEl] = useState(null);

	const openEl = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onLogout = () => {
		logoutUser({ userType: null, history });
	};

	return (
		<>
			<IconButton onClick={(event) => handleClick(event)} aria-label="notification" id="testLogoutFlow-selector-2">
				<img src={userIcon} alt="" width={24} height={24} />
			</IconButton>
			<Menu
				id="fade-menu"
				MenuListProps={{
					'aria-labelledby': 'fade-button',
				}}
				anchorEl={anchorEl}
				open={openEl}
				onClose={handleClose}
				TransitionComponent={Fade}
				PaperProps={{
					style: {
						minWidth: '350px',
						borderRadius: '30px'
					},
				}}
			>
				<div className='accountmenu'>
					<div className='accountmenu-title'>
						<div>
							<h2>{userName}</h2>
							<h4>{userRole}</h4>
						</div>
						<div>
							<PersonIcon style={{ width: 50, height: 50, color: "#479BD2" }} />
						</div>
					</div>
					<ul className='accountmenu-ul'>
						<li>
							<Link to={'/my-account'} style={{ display: 'flex', textDecoration: 'none', color: 'inherit' }}>
								<PersonIcon style={{ width: 22, height: 22, color: "#334D6E", marginLeft: -2, marginBottom: -2, marginRight: -2 }} />
								<p>My Account</p>
							</Link>
						</li>
						{/* <li
							id="testDashboardTourFlow-selector-11"
							style={{ cursor: 'pointer' }}
							onClick={() => {
								// if (steps.length == 0) return;

								// if (location.pathname !== '/workflows') history.push("/workflows");
								handleClose();
								// setCurrentStep(0);
								// setIsOpen(true);
								// startTutorial('demo');
								updateCurrentWalkthroughStep(1);
							}}>
							<img src={knowledgebaseicon} alt='' />
							<p>Quick guide</p>
						</li> */}
						{/* <li>
							<img src={quickguideicon} alt='' />
							<p>Knowledge base</p>
						</li> */}
						<li style={{ cursor: 'pointer' }} onClick={onLogout} id="testLogoutFlow-selector-3">
							<img src={logouticon} alt='' />
							<p>Logout</p>
						</li>
					</ul>
					<div className='accountmenu-footer'>
						<p>write to us:</p>
						<p><a href="mailto:recruiter-support@hirequotient.com">recruiter-support@hirequotient.com</a></p>
					</div>
				</div>
			</Menu>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	userName: getUserName(),
	userRole: getUserRole()
});

const mapDispatchToProps = (dispatch) => ({
	logoutUser: (data) => dispatch(logoutUserRequest(data)),
	// updateCurrentWalkthroughStep: (data) => dispatch(updateCurrentWalkthroughStepRequest(data))
});

AccountMenu.propTypes = {
	userName: PropTypes.string,
	userRole: PropTypes.string,
	logoutUser: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
