import { Button, Snackbar } from '@material-ui/core';
import { Alert } from '@mui/material';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';

import '../../styles/bookDemoModal.scss';

const BookDemoModal = (props) => {
	const { requestDemoModal, setRequestDemoModal, userName, userEmail } = props;

	const [name, setName] = useState(userName);
	const [company, setCompany] = useState('');
	const [email, setEmail] = useState(userEmail);
	const [errSnackOpen, setErrSnackOpen] = useState(false);
	const [successSnackOpen, setSuccessSnackOpen] = useState(false);
	const [snackErrMsg, setSnackErrMsg] = useState('');
	const [snackSuccessMsg, setSnackSuccessMsg] = useState('');
	const [loading, setLoading] = useState(false);

	const handleErrSnackClick = () => {
		setErrSnackOpen(true);
	};

	const handleSuccessSnackClick = () => {
		setSuccessSnackOpen(true);
	};

	const handleSuccessSnackClose = () => {
		setSuccessSnackOpen(false);
	};

	const handleErrSnackClose = (event, reason) => {
		setErrSnackOpen(false);
	};

	const onSubmit = () => {
		if (name === '') {
			handleErrSnackClick();
			setSnackErrMsg('Enter valid name');
		} else if (company === '') {
			handleErrSnackClick();
			setSnackErrMsg('Enter valid company');
		} else if (email === '') {
			handleErrSnackClick();
			setSnackErrMsg('Enter valid email');
		} else {
			setLoading(true);
			const data = new FormData();
			data.append('Name', name);
			data.append('Company', company);
			data.append('Email', email);
			data.append('Form Name', 'requestDemoFreeTrial');
			data.append('UTM Source', 'direct');
			const action = "https://script.google.com/macros/s/AKfycbxHTxrcKZ0ARSSEM84i-m6_rdTkqi3ZY9wAKso1wtdRZQGkLMzR10GqyljeJRQ7ToBt/exec";
			fetch(action, {
				method: 'POST',
				body: data,
				mode: 'no-cors'
			})
				.then(() => {
					handleSuccessSnackClick();
					setSnackSuccessMsg('Your query sent successfully');
					setEmail('');
					setCompany('');
					setName('');
					setLoading(false);
				})
		}
	}

	return (
		<>
			<Modal open={requestDemoModal} onClose={() => setRequestDemoModal(false)}>
				<div className='book-demo-modal'>
					<div style={{ color: '#5C5C5C', fontSize: 24, fontWeight: '700' }}>Book your demo slot here</div>
					{/* <div style={{ marginTop: 30 }}>
						<input type='text' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} style={{ padding: '10px 20px', outline: 'none', width: '80%', backgroundColor: '#f2f2f2', borderRadius: 10, border: 'none' }} />
					</div>
					<div style={{ marginTop: 30 }}>
						<input type='text' placeholder='Company' value={company} onChange={(e) => setCompany(e.target.value)} style={{ padding: '10px 20px', outline: 'none', width: '80%', backgroundColor: '#f2f2f2', borderRadius: 10, border: 'none' }} />
					</div>
					<div style={{ marginTop: 30 }}>
						<input type='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} style={{ padding: '10px 20px', outline: 'none', width: '80%', backgroundColor: '#f2f2f2', borderRadius: 10, border: 'none' }} />
					</div>
					<div style={{ textAlign: 'right', position: 'relative', marginTop: 30 }}>
						<Button className='send-action btn-primary' onClick={onSubmit} size="small" variant="contained" disabled={loading}>Send</Button>
						{
							loading ? (
								<div className='send-action-loader'></div>
							) : ''
						}
					</div> */}
					<iframe
						title={'hubspot'}
						style={{
							width: '100%',
							height: 610,
							overflowY: 'scroll',
							backgroundColor: '#fafafa',
							border: 'none',
							marginTop: 20
						}}
						src={'https://meetings-eu1.hubspot.com/smarthveer'}
					/>
				</div>
			</Modal>
			<Snackbar open={successSnackOpen} autoHideDuration={2000} onClose={handleSuccessSnackClose}>
				<Alert onClose={handleSuccessSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					{snackSuccessMsg ? snackSuccessMsg : ''}
				</Alert>
			</Snackbar>
			<Snackbar open={errSnackOpen} autoHideDuration={2500} onClose={handleErrSnackClose}>
				<Alert onClose={handleErrSnackClose} variant="filled" severity="error" sx={{ width: '100%' }}>
					{snackErrMsg ? snackErrMsg : ''}
				</Alert>
			</Snackbar>
		</>
	)
};

export default BookDemoModal;