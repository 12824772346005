import React, { useEffect, useState } from 'react';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IconButton } from '@material-ui/core';

import AllNotificationTab from './AllNotificationTab';
// import UnreadNotificationTab from './UnreadNotificationTab';
import UpdatesNotificationTab from './UpdatesNotificationTab';
import { getDriveInvitedList, getDriveSubmittedList, getNotificationList } from '../../selectors/app';
import { fetchNotifications as fetchNotificationsRequest, updateNotification as updateNotificationRequest } from '../../actions/app';

import notificationBell from '../../assets/Icons/notificationBell.png';

import './notificationBell.css';
import { useHistory } from 'react-router-dom';

const NotificationBell = (props) => {
	const { notification, fetchNotifications, notificationList, updateNotification } = props;

	const history = useHistory();

	const [anchorEl, setAnchorEl] = useState(null);
	const [currentTab, setCurrentTab] = useState(0);
	const [notifications, setNotifications] = useState([]);

	const openEl = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		fetchNotifications({ history });
	}, []);

	useEffect(() => {
		const newNotificationList = [];
		notificationList.forEach((item) => {
			const newItem = {...item};
			if(!newItem.notificationId) {
				newItem.notificationId = item._id;
			}
			newNotificationList.push(newItem);
		});
				
		setNotifications(newNotificationList);
	}, [notificationList]);

	useEffect(() => {
		if (notification && notification.type) {
			if (notification.type == 'inviteCount' || notification.type == 'submitCount') {
				const newNotificationList = notificationList.filter((item) => item.type != notification.type);
				newNotificationList.push(notification);
				setNotifications(newNotificationList);
			} else {
				const newNotificationList = [...notificationList];
				newNotificationList.push(notification);
				setNotifications(newNotificationList);
			}
		}
	}, [notification]);

	const updateNotificationStatus = (notificationId) => {
		const notificationData = {
			notificationId,
			status: 'Read'
		};

		updateNotification({ notificationData, notifications, history });
	}

	return (
		<>
			<IconButton onClick={(event) => handleClick(event)} aria-label="notification">
				<img src={notificationBell} alt="" width={27} height={27} />
			</IconButton>
			<Menu
				id="fade-menu"
				MenuListProps={{
					'aria-labelledby': 'fade-button',
				}}
				anchorEl={anchorEl}
				open={openEl}
				onClose={handleClose}
				TransitionComponent={Fade}
				PaperProps={{
					style: {
						width: 450,
						borderRadius: 30
					},
				}}
			>
				<div style={{ padding: 20 }}>
					<div>
						<div className='notification-header'>
							<h2>Notification</h2>
						</div>
						<div className='notification-tabs'>
							<div className={currentTab == 0 ? 'active-notification-tab' : ''} onClick={() => setCurrentTab(0)}>All</div>
							{/* <div className={currentTab == 1 ? 'active-notification-tab' : ''} onClick={() => setCurrentTab(1)}>Unread</div> */}
							<div className={currentTab == 2 ? 'active-notification-tab' : ''} onClick={() => setCurrentTab(2)}>Updates</div>
						</div>
					</div>
					<div className='notification-body scroll-blue'>
						{currentTab == 0 ? <AllNotificationTab {...props} notifications={notifications} updateNotificationStatus={updateNotificationStatus} /> : ""}
						{/* {currentTab == 1 ? <UnreadNotificationTab /> : ""} */}
						{currentTab == 2 ? <UpdatesNotificationTab notifications={notifications} updateNotificationStatus={updateNotificationStatus} /> : ""}
					</div>
				</div>
			</Menu>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	driveInvitedCount: getDriveInvitedList(),
	driveSubmittedCount: getDriveSubmittedList(),
	notificationList: getNotificationList()
});

const mapDispatchToProps = (dispatch) => ({
	fetchNotifications: (data) => (dispatch(fetchNotificationsRequest(data))),
	updateNotification: (data) => (dispatch(updateNotificationRequest(data)))
});

NotificationBell.propTypes = {
	notification: PropTypes.object,
	fetchNotifications: PropTypes.func,
	updateNotification: PropTypes.func,
	notificationList: PropTypes.array
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBell);
