import Validator from 'validator'
import isEmpty from './is-empty'

// SIGN UP VALIDATION
export const validateSignUpInput = (data) => {
	let errors = {}

	data.firstName = !isEmpty(data.firstName) ? data.firstName : ''
	data.lastName = !isEmpty(data.lastName) ? data.lastName : ''
	data.institution = !isEmpty(data.institution) ? data.institution : ''
	data.email = !isEmpty(data.email) ? data.email : ''
	data.password = !isEmpty(data.password) ? data.password : ''
	// data.phone = !isEmpty(data.phone) ? data.phone : ''

	if (Validator.isEmpty(data.firstName)) {
		errors.firstName = 'First name is required'
	}
	if (Validator.isEmpty(data.lastName)) {
		errors.lastName = 'Last name is required'
	}

	if (!Validator.isEmail(data.email)) {
		errors.email = 'Email is invalid'
	}

	if (Validator.isEmpty(data.email)) {
		errors.email = 'Email field is required'
	}

	// if (!Validator.isLength(data.password, { min: 8 })) {
	//   errors.password = 'Password must be at least 8 characters'
	// }

	if (Validator.isEmpty(data.password)) {
		errors.password = 'Password field is required'
	}

	// if (!Validator.isMobilePhone(data.phone)) {
	//   errors.phone = 'Phone Number is Invalid'
	// }

	// if (Validator.isEmpty(data.phone, [0 - 9])) {
	//   errors.phone = 'Phone Number is required'
	// }

	if (Validator.isEmpty(data.institution)) {
		errors.institution = 'Institution is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

// SIGN IN VALIDATION
export const validateSignInInput = (data) => {
	let errors = {}

	data.email = !isEmpty(data.email) ? data.email : '';
	data.password = !isEmpty(data.password) ? data.password : '';
	if(process.env.ENV !== 'development') {
		data.captchaValue = !isEmpty(data.captchaValue) ? data.captchaValue : '';
	} 

	if (!Validator.isEmail(data.email)) {
		errors.email = 'Email is invalid'
	}

	if (Validator.isEmpty(data.email)) {
		errors.email = 'Email field is required'
	}

	if (Validator.isEmpty(data.password)) {
		errors.password = 'Name field is required'
	}

	if (process.env.NODE_ENV !== 'development' && Validator.isEmpty(data.captchaValue)) {
		errors.captchaValue = 'Captcha field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateDemoInput = (data) => {
	let errors = {}

	data.name = !isEmpty(data.name) ? data.name : ''
	data.email = !isEmpty(data.email) ? data.email : ''

	if (!Validator.isEmail(data.email)) {
		errors.email = 'Email is invalid'
	}

	if (Validator.isEmpty(data.email)) {
		errors.email = 'Email field is required'
	}

	if (Validator.isEmpty(data.name)) {
		errors.name = 'Name field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateFreeSigninInput = (data) => {
	let errors = {}

	data.email = !isEmpty(data.email) ? data.email : ''
	data.password = !isEmpty(data.password) ? data.password : ''

	if (!Validator.isEmail(data.email)) {
		errors.email = 'Email is invalid'
	}

	if (Validator.isEmpty(data.email)) {
		errors.email = 'Email field is required'
	}

	if (Validator.isEmpty(data.password)) {
		errors.password = 'Password field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}


// FORGOT PASSWORD VALIDATION
export const validateEmail = (email) => {
	let errors = {}

	email = !isEmpty(email) ? email : ''

	if (!Validator.isEmail(email)) {
		errors.email = 'Email is invalid'
	}

	if (Validator.isEmpty(email)) {
		errors.email = 'Email field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

// RESET FORM VALIDATION
export const validateResetInput = (data) => {
	let errors = {};

	data.firstName = !isEmpty(data.firstName) ? data.firstName : '';
	data.lastName = !isEmpty(data.lastName) ? data.lastName : '';
	data.institution = !isEmpty(data.institution) ? data.institution : '';

	if (Validator.isEmpty(data.firstName)) {
		errors.firstName = 'First name is required';
	}
	if (Validator.isEmpty(data.lastName)) {
		errors.lastName = 'Last name is required';
	}

	if (Validator.isEmpty(data.institution)) {
		errors.institution = 'Institution is required';
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validatePassword = (password) => {
	let errors = {};

	password = !isEmpty(password) ? password : '';

	if (Validator.isEmpty(password)) {
		errors.password = 'Password field is required';
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateSpoofText = (val) => {
	return /^(?!\s)[\w\s-]*$/.test(val)
}
