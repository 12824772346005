import React from 'react';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import '../../styles/featureLockedModal.scss';
import {
    updateTrackerInfo as updateTrackerInfoRequest
} from '../../actions/app'

const FeatureLockedModal = (props) => {
    const { modalOpen, onClose,updateTrackerInfo} = props;
    const goToSaas=(e)=>{
        console.log('clicked upgrade')
        updateTrackerInfo({
                        heading: "CLICK_UPGRADE_PAYMENT",
                        rawRequest : JSON.stringify({}),
                        detail : ""
                    })
        const x = window.open('https://hirequotient.com/pricing/saas','_blank')

    }
    return (
        <Modal open={modalOpen} onClose={onClose} center>
            <div className='feature-locked-modal'>
                <div>This feature is not available in the free trial. Upgrade your plan to enable it.</div>
                <div onClick = {(e)=>goToSaas(e)} className="lockedfeature" style={{ display: 'inline-block' }} rel='noreferrer'>Upgrade</div>
            </div>
        </Modal>
    );
}
const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
    updateTrackerInfo: (data) => dispatch(updateTrackerInfoRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureLockedModal);
