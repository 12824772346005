import React from 'react';
import PropTypes from 'prop-types';

const Message = ({ msg }) => {
  return (
    <div className='alert alert-info alert-dismissible fade show' role='alert' style={{textAlign: 'center', color: '#eee'}}>
      {msg}
      <button
      style={{
         outline: 'none',
         border: 'none',
         borderRadius: '15px',
         background: '#fff',
         color: '#8b8b8b',
         marginLeft: '8px'
      }}
        type='button'
        className='close'
        data-dismiss='alert'
        aria-label='Close'
      >
        <span style={{fontSize: '16px',fontWeight: 'bold', lineHeight: '16px', height: '16px'}} aria-hidden='true'>&times;</span>
      </button>
    </div>
  );
};

Message.propTypes = {
  msg: PropTypes.string.isRequired
};

export default Message;
