import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { Modal } from 'react-responsive-modal';
import { useLocation } from 'react-router-dom';
import { useSpeechRecognition } from 'react-speech-kit';
import { withStyles } from '@material-ui/core';

import axios from '../../../axios';
import { chatMic, chatMicOff, sendChatInput, sendChatInputOff } from '../../../utils/svgImages';

const CustomTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: "#333",
		color: '#fff',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip);

const ChatInput = (props) => {
	const { messages, addToMessageBox, setNextQues, setProgress, placeholder = "Enter Your Text" } = props;

	let location = useLocation();

	const [newMessage, setNewMessage] = useState("");
	const [micOn, setMicOn] = useState(false);
	const [warningModal, setWarningModal] = useState(false);

	const { listen, listening, stop } = useSpeechRecognition({
		onResult: (result) => {
			setNewMessage(result);
		},
	});

	const onSendMessage = (message) => {
		let convoMssg = {
			q: message,
			isMyMessage: true
		};
		const url = 'userconvo';
		let getData = {
			user_id: location.state.code,
			comp: location.state.comp,
			message1: messages.messages[0].qid,
			message2: message,
			mode: 0
		};

		addToMessageBox([convoMssg]);
		setNewMessage("");
		axios
			.post(url, getData, { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				let parsedMssg = res.data.msg1[0].map((ele, index) => {
					return ({
						q: ele,
						ref: "na"
					})
				});
				parsedMssg[parsedMssg.length - 1]["qtype"] = res.data.msg1[1];
				parsedMssg[parsedMssg.length - 1]["options"] = res.data.msg1[2];
				parsedMssg[parsedMssg.length - 1]["qid"] = res.data.msg1[3];
				parsedMssg[parsedMssg.length - 1]["exhibit"] = res.data.msg1[4];
				parsedMssg[parsedMssg.length - 1]["progress"] = res.data.msg1[5];
				let parsedRespMssg = res.data.msg2.split(";;").map((ele) => {
					return ({
						q: ele,
						ref: "na"
					});
				});
				let finalQues = [...parsedRespMssg.reverse()];

				sendToChatBox(finalQues, finalQues.length - 1);
				setTimeout(() => { setNextQues(parsedMssg.reverse()) }, (finalQues.length) * 1500);
				setProgress(parseInt(res.data.msg1[5], 10));
			})
			.catch((err) => {
				console.log("this is err : ", err);
			});
	}

	const sendToChatBox = (msglist, x) => {
		addToMessageBox([msglist[x]]);

		if (x > 0)
			setTimeout(() => { sendToChatBox(msglist, x - 1) }, 1500);
	}

	// const onPressEnter = (event) => {
	// 	const { key } = event;

	// 	if (key === "Enter") {
	// 		event.preventDefault(); // prevent Enter behaviour
	// 		sendButtonClick();
	// 	} else {
	// 		return null;
	// 	}
	// }

	const letslisten = () => {
		if (listening) {
			stop();
			setMicOn(false);
		} else {
			listen();
			setMicOn(true);
		}
	}

	const onOpenWarningModal = () => setWarningModal(true);

	const onCloseWarningModal = () => setWarningModal(false);

	const sendButtonClick = () => {
		let trimmedNewMessage = newMessage.trim();

		if (messages.messages[0].qtype === "txt2") {
			if (trimmedNewMessage.includes('.')) {
				let sentence = trimmedNewMessage.split('.');

				if (sentence.length > 1 && sentence[0].length >= 1 && sentence[1].length >= 1) {
					onSendMessage(trimmedNewMessage);
				} else {
					onOpenWarningModal();
				}
			} else {
				onOpenWarningModal();
			}
		} else if (messages.messages[0].qtype === "txt3") {
			if (trimmedNewMessage.includes('.')) {
				let sentence = trimmedNewMessage.split('.');

				if (sentence.length >= 3 && sentence[0].length >= 1 && sentence[1].length >= 1 && sentence[2].length >= 1) {
					onSendMessage(trimmedNewMessage);
				} else {
					onOpenWarningModal();

				}
			} else {
				onOpenWarningModal()
			}
		} else if (messages.messages[0].qtype === "txt5") {


			if (trimmedNewMessage.includes('.')) {
				let sentence = trimmedNewMessage.split('.');

				if (sentence.length >= 5 && sentence[0].length >= 1 && sentence[1].length >= 1 && sentence[2].length >= 1 && sentence[3].length >= 1 && sentence[4].length >= 1) {
					onSendMessage(trimmedNewMessage);
				}
				else {
					onOpenWarningModal();

				}
			}
			else {
				onOpenWarningModal()
			}
		} else {
			if (trimmedNewMessage.length >= 1) {
				onSendMessage(trimmedNewMessage);
			} else {
				return null;
			}
		}
	}

	return (
		<div id="chatInput" style={{ position: "fixed", height: "8vh", borderTopRightRadius: "16px", borderTopLeftRadius: "16px", display: "block", marginTop: "5vh" }}>
			<textarea rows="1"
				placeholder={placeholder}
				style={{
					float: 'left',
					boxSizing: 'border-box',
					color: "",
					width: "calc(100%)",
					height: "6vh",
					borderRadius: "25px",
					fontSize: "1.2rem",
					padding: "5px 3vw",
					border: "none",
					background: placeholder != "Enter Your Text" ? "#F8F8F8" : "aliceblue",
					boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)"
				}}
				value={newMessage}
				// onKeyUp={onPressEnter}
				onChange={(e) => setNewMessage(e.target.value)}
				disabled={placeholder != "Enter Your Text"}
			/>
			<Button
				style={{
					float: 'right',
					width: '70px',
					transform: 'translate(0,-4vh)',
					height: '0',
					overflow: 'visible'
				}}
				onClick={sendButtonClick}
				disabled={placeholder != "Enter Your Text"}
			>
				<CustomTooltip title="Send">
					<img src={placeholder == "Enter Your Text" ? sendChatInput : sendChatInputOff} style={{ height: "4vh" }} alt='' />
				</CustomTooltip>
			</Button>
			<Button
				style={{
					float: 'left',
					width: '70px',
					transform: 'translate(-100%,-4vh)',
					height: '0',
					overflow: 'visible'
				}}
				onClick={letslisten}
				disabled={placeholder != "Enter Your Text"}
			>
				<CustomTooltip title={micOn ? "Stop Recording" : "Start Recording"}>
					<img src={micOn || placeholder != "Enter Your Text" ? chatMicOff : chatMic} style={{ height: "5vh" }} alt='' />
				</CustomTooltip>
			</Button>
			<Modal open={warningModal} onClose={onCloseWarningModal} center>
				<p className="modal-text">
					Are you sure you want to submit this question?
				</p>
				<div className="button-div">
					<Button
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onSendMessage(newMessage.trim());
							onCloseWarningModal()
						}}>
						Ok
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseWarningModal();
						}}>
						Cancel
					</Button>
				</div>
			</Modal>
		</div>
	)
}

export default ChatInput;
