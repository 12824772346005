import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';

import AddCustomAssessment from './addNewDrive/AddCustomAssessment';
import AddDefaultDrive from './addNewDrive/AddDefaultDrive';
import InterviewerGuide from '../common/InterviewerGuide';
import SelectAssessmentType from './addNewDrive/SelectAssessmentType';

import '../../styles/addNewDriveModal.scss';
import AddVideoQuestions from './addNewDrive/AddVideoQuestions';
import AddCustomFields from './addNewDrive/AddCustomFields';
import { customAssessmentData, workFunctionData } from './newDriveData';
import WalkthroughOverlay from '../common/WalkthroughOverlay';

const AddNewDrive = (props) => {
    const { open, onClose, change, setChange, handleSnackClick, setSnackErr, companyName, selectBotMenu, currentWalkthroughStep, updateCurrentWalkthroughStep } = props;

    const [showSelectType, setShowSelectType] = useState(false);
    const [showDefaultForm, setShowDefaultForm] = useState(false);
    const [showCustomForm, setShowCustomForm] = useState(false);
    const [showInterviewGuide, setShowInterviewGuide] = useState(false);
    const [driveRole, setDriveRole] = useState('');
    const [bot, setBot] = useState();
    const [videoQuestions, setVideoQuestions] = useState([]);
    const [showVideoQuestion, setShowVideoQuestion] = useState(false);
    const [addVideoCount, setAddVideoCount] = useState(0);
    const [showCreateCustomAssessment, setShowCreateCustomAssessment] = useState(false);
    const [customAssessmentProblemStatement, setCustomAssessmentProblemStatement] = useState('');
    const [customFieldsData, setCustomFieldsData] = useState({});
    const [workfunctionSelected, setWorkfunctionSelected] = useState([]);
    const [workfunctionOption, setWorkfunctionOption] = useState([]);
    const [jobRole, setJobRole] = useState('');
    const [keyTask, setKeyTask] = useState({});
    const [finalVal, setFinalVal] = useState({});
    const [functionalSkill, setFunctionalSkill] = useState([]);
    const [showKeyTask, setShowKeyTask] = useState(false);
    const [salesStages, setSalesStages] = useState([]);
    const [coreSkill, setCoreSkill] = useState([]);
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [industry, setIndustry] = useState('');
    const [workflowFixed, setWorkflowFixed] = useState(true);
    const [showCustomAssessmentEditBtn, setShowCustomAssessmentEditBtn] = useState(false);
    const [showWalkthroughNextBtn, setShowWalkthroughNextBtn] = useState(false);
    const [nextWalkthroughStep, setNextWalkthroughStep] = useState(0);

    const openDefaultForm = (val) => {
        setBot(val);
        setName('');
        setDesc('');
        setShowSelectType(false);
        setShowDefaultForm(true);
    }

    const openCustomForm = () => {
        setDriveRole('');
        setShowKeyTask(false);
        setName('');
        setDesc('');
        setSalesStages([]);
        setShowSelectType(false);
        setShowCustomForm(true);
        if (currentWalkthroughStep > 0) {
            updateCurrentWalkthroughStep(3);
        }
    }

    const openInterviewGuide = () => {
        setShowCustomForm(false);
        setShowInterviewGuide(true);
    }

    const openCustomAssessmentForm = () => {
        setShowCreateCustomAssessment(true);
        setShowCustomForm(false);
    }

    const closeCustomAssessmentForm = () => {
        setShowCreateCustomAssessment(false);
        setShowCustomForm(true);
    }

    const goBackCustomForm = () => {
        setShowCustomForm(true);
        setShowInterviewGuide(false);
        setShowVideoQuestion(false);
    }

    const openVideoQuestionForm = () => {
        if (videoQuestions.length === 0 || (videoQuestions.length !== addVideoCount)) {
            const videoQuesArr = [];
            for (let i = 0; i < addVideoCount; i++) {
                const videoQuesObj = {
                    question: ''
                };
                videoQuesArr.push(videoQuesObj);
            }
            setVideoQuestions([...videoQuesArr]);
        }
        if (addVideoCount > 0) {
            setShowCustomForm(false);
            setShowVideoQuestion(true);
        }
    }

    const goBack = () => {
        setShowCustomForm(false);
        setShowDefaultForm(false);
        setShowSelectType(true);
    }

    useEffect(() => {
        if (open) {
            // if (selectBotMenu.length > 0) {
            //     setShowSelectType(true);
            //     setShowCustomForm(false);
            // } else {
            openCustomForm();
            // }
            setShowDefaultForm(false);
            setShowInterviewGuide(false);
            setDriveRole('');
            setShowVideoQuestion(false);
            setShowCreateCustomAssessment(false);
            setVideoQuestions([]);
            setAddVideoCount(0);
        }
    }, [open]);

    const onChangeCustomAssessment = (val) => {
        if (val) {
            openCustomAssessmentForm();
        } else {
            closeCustomAssessmentForm();
        }
    }

    useEffect(() => {
        if (!driveRole) return;

        setDefaultSettings();
    }, [driveRole]);

    useEffect(() => {
        const selectedRole = jobRole ? jobRole : driveRole;
        let tempKeyTask = {};
        let tempfinalVal = {};

        workfunctionSelected.map((val) => {
            tempKeyTask = { ...tempKeyTask, [val.value]: workFunctionData[selectedRole][val.value] };
            tempfinalVal[driveRole] = { ...tempfinalVal[driveRole], [val.value]: workFunctionData[selectedRole][val.value] };
        })

        setKeyTask(tempKeyTask);
        setFinalVal(tempfinalVal);
    }, [workfunctionSelected]);

    useEffect(() => {
        if (!jobRole) return;

        if (jobRole != "Custom") {
            if (currentWalkthroughStep > 0) {
                setNextWalkthroughStep(6);
                updateCurrentWalkthroughStep(5);
            }
            let arr = [];

            Object.keys(workFunctionData[jobRole]).map((val) => {
                arr.push({ value: val, label: val })
            });

            setWorkfunctionOption(arr);
            setWorkfunctionSelected(arr);
        } else {
            setShowKeyTask(false);
            setWorkfunctionOption([]);
            setWorkfunctionSelected([]);
            setSalesStages([]);
        }
    }, [jobRole])

    useEffect(() => {
        if (!driveRole) return;

        if (driveRole == "Associate consultant" || driveRole == "Consultant") {
            let arr = {
                [driveRole]: [...functionalSkill]
            };

            setFinalVal(arr);

            const newCoreSkill = [
                { value: 'Structuring', label: 'Structuring', weightage: 20 },
                { value: 'Insights', label: 'Insights', weightage: 20 },
                { value: 'Synthesis', label: 'Synthesis', weightage: 20 },
                { value: 'Quantitative', label: 'Quantitative', weightage: 20 },
                { value: 'Framing', label: 'Framing', weightage: 20 },
            ];
            setCoreSkill([...newCoreSkill]);
        }
    }, [functionalSkill]);

    const setDefaultSettings = () => {
        if (driveRole != "Associate consultant" && driveRole != "Consultant" && driveRole != "Sales and Marketing") {
            let arr = [];

            Object.keys(workFunctionData[driveRole]).map((val) => {
                const wfObj = {
                    value: val,
                    label: val
                };

                if (driveRole == 'Associate consultant' || driveRole == 'Consultant') {
                    wfObj.weightage = 20;
                }

                arr.push(wfObj)
            });

            setWorkfunctionOption(arr);
            setWorkfunctionSelected(arr);
        }

        if (driveRole == "Product Manager" || driveRole == "Project Manager") {
            if (currentWalkthroughStep > 0) {
                setNextWalkthroughStep(6);
                updateCurrentWalkthroughStep(5);
            }
            let customAssessmentProbStatement = customAssessmentData[driveRole].problemStatement;
            customAssessmentData[driveRole].customFields.forEach((cf) => {
                const regex = new RegExp(`{{${cf.key}}}`, "g");
                if (customFieldsData[cf.label]) {
                    customAssessmentProbStatement = customAssessmentProbStatement.replace(regex, `<b>${customFieldsData[cf.label]}</b>`);
                } else {
                    customAssessmentProbStatement = customAssessmentProbStatement.replace(regex, `<b>[${cf.label}]</b>`);
                }
            });
            setCustomAssessmentProblemStatement(customAssessmentProbStatement);
        }

        if (driveRole == "Associate consultant" || driveRole == "Consultant") {
            if (currentWalkthroughStep > 0) {
                setNextWalkthroughStep(6);
                updateCurrentWalkthroughStep(5);
            }
        }
    }

    useEffect(() => {
        if (currentWalkthroughStep === 6 && showCustomForm) {
            document.getElementById('walkthroughStep6').scrollIntoView();
        }
    }, [showCustomForm])

    return (
        <Modal open={open} onClose={onClose} center closeOnOverlayClick={false}>
            <div id="addNewDriveModal">
                {/* {
                    showSelectType ? (
                        <SelectAssessmentType
                            selectBotMenu={selectBotMenu}
                            openDefaultForm={openDefaultForm}
                            openCustomForm={openCustomForm}
                            currentWalkthroughStep={currentWalkthroughStep}
                        />
                    ) : ''
                } */}
                {
                    showDefaultForm ? (
                        <AddDefaultDrive
                            goBack={goBack}
                            bot={bot}
                            companyName={companyName}
                            change={change}
                            setChange={setChange}
                            onClose={onClose}
                            handleSnackClick={handleSnackClick}
                            setSnackErr={setSnackErr}
                        />
                    ) : ''
                }
                {
                    showCustomForm ? (
                        <AddCustomAssessment
                            goBack={goBack}
                            videoQuestions={videoQuestions}
                            openInterviewGuide={openInterviewGuide}
                            driveRole={driveRole}
                            setDriveRole={setDriveRole}
                            customFieldsData={customFieldsData}
                            setAddVideoCount={setAddVideoCount}
                            openVideoQuestionForm={openVideoQuestionForm}
                            onChangeCustomAssessment={onChangeCustomAssessment}
                            handleSnackClick={handleSnackClick}
                            setSnackErr={setSnackErr}
                            addVideoCount={addVideoCount}
                            onClose={onClose}
                            companyName={companyName}
                            setDefaultSettings={setDefaultSettings}
                            openCustomAssessmentForm={openCustomAssessmentForm}
                            change={change}
                            setChange={setChange}
                            workfunctionSelected={workfunctionSelected}
                            setWorkfunctionSelected={setWorkfunctionSelected}
                            workfunctionOption={workfunctionOption}
                            jobRole={jobRole}
                            setJobRole={setJobRole}
                            keyTask={keyTask}
                            setKeyTask={setKeyTask}
                            finalVal={finalVal}
                            setFinalVal={setFinalVal}
                            functionalSkill={functionalSkill}
                            setFunctionalSkill={setFunctionalSkill}
                            setShowKeyTask={setShowKeyTask}
                            showKeyTask={showKeyTask}
                            salesStages={salesStages}
                            setSalesStages={setSalesStages}
                            coreSkill={coreSkill}
                            setCoreSkill={setCoreSkill}
                            name={name}
                            setName={setName}
                            desc={desc}
                            setDesc={setDesc}
                            workflowFixed={workflowFixed}
                            setWorkflowFixed={setWorkflowFixed}
                            industry={industry}
                            setIndustry={setIndustry}
                            showCreateCustomAssessment={showCreateCustomAssessment}
                            setShowCustomAssessmentEditBtn={setShowCustomAssessmentEditBtn}
                            showCustomAssessmentEditBtn={showCustomAssessmentEditBtn}
                            currentWalkthroughStep={currentWalkthroughStep}
                            setShowWalkthroughNextBtn={setShowWalkthroughNextBtn}
                            setNextWalkthroughStep={setNextWalkthroughStep}
                            updateCurrentWalkthroughStep={updateCurrentWalkthroughStep}
                        />
                    ) : ''
                }
                {
                    showInterviewGuide ? (
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
                                <div className='back-btn' onClick={goBackCustomForm}>
                                    <div></div>
                                </div>
                            </div>
                            <InterviewerGuide productId={driveRole} />
                        </div>
                    ) : ''
                }
                {
                    showVideoQuestion ? (
                        <AddVideoQuestions
                            goBackCustomForm={goBackCustomForm}
                            videoQuestions={videoQuestions}
                            setVideoQuestions={setVideoQuestions}
                            currentWalkthroughStep={currentWalkthroughStep}
                        />
                    ) : ''
                }
                {
                    showCreateCustomAssessment ? (
                        <AddCustomFields
                            closeCustomAssessmentForm={closeCustomAssessmentForm}
                            driveRole={driveRole}
                            customFieldsData={customFieldsData}
                            setCustomFieldsData={setCustomFieldsData}
                            setCustomAssessmentProblemStatement={setCustomAssessmentProblemStatement}
                            customAssessmentProblemStatement={customAssessmentProblemStatement}
                        />
                    ) : ''
                }
            </div>
            {
                currentWalkthroughStep > 1 ? (
                    <WalkthroughOverlay
                        // onClickSkip={() => updateCurrentWalkthroughStep(2)}
                        onClickExit={() => updateCurrentWalkthroughStep(0)}
                        setShowWalkthroughNextBtn={setShowWalkthroughNextBtn}
                        showWalkthroughNextBtn={showWalkthroughNextBtn && currentWalkthroughStep !== 4}
                        onClickDone={() => { updateCurrentWalkthroughStep(nextWalkthroughStep); setNextWalkthroughStep(nextWalkthroughStep + 1); }}
                        currentWalkthroughStep={currentWalkthroughStep}
                    />
                ) : ''
            }
        </Modal>
    )
}

export default AddNewDrive;