import React, { useEffect, useRef, useState } from "react";
import Zoom from 'react-medium-image-zoom';

import backwardarrow from '../../../assets/backarrow.png';
import forwardarrow from '../../../assets/arrow.png';

import "./VerticalExhibits.scss";

const VerticalCarousel = (props) => {
	const { exhibits } = props;

	const itemHeight = 150;

	const [currTransform, setCurrentTransform] = useState(0);
	const [showNext, setShowNext] = useState(false);
	const [showPrev, setShowPrev] = useState(false);

	const wrapperRef = useRef(null);
	const stageRef = useRef(null);

	const movePrev = function () {
		if (currTransform < (stageRef.current.clientHeight - wrapperRef.current.clientHeight)) return;
		setCurrentTransform(currTransform - itemHeight);
	}

	const moveNext = function () {
		if (currTransform >= 0) return;
		if(currTransform + itemHeight < 0) {
			setCurrentTransform(0);
		} else {
			setCurrentTransform(currTransform + itemHeight);
		}
	}

	useEffect(() => {
		setTimeout(() => {
			if (wrapperRef.current && stageRef.current && wrapperRef.current.clientHeight > stageRef.current.clientHeight) {
				setShowNext(true);
				setShowPrev(true);
			} else {
				setShowNext(false);
				setShowPrev(false);
			}
		}, 0);
	}, [exhibits]);

	return (
		<div className="verticalCarousel">
			{
				showNext ? (
					<div className="verticalCarousel__next">
						<img
							src={forwardarrow}
							alt="next"
							onClick={moveNext}
						/>
					</div>
				) : ''
			}
			<div className="verticalCarousel__stage" ref={stageRef}>
				<div className="verticalCarousel__wrapper" ref={wrapperRef} style={{ transform: `translate(0,${currTransform}px)` }}>
					{exhibits.map((exhibit, i) => (
						<div className="verticalCarousel__item" key={i}>
							<Zoom>
								<img src={exhibit} alt='' />
							</Zoom>
						</div>
					))}
				</div>
			</div>
			{
				showPrev ? (
					<div className="verticalCarousel__prev">
						<img
							src={forwardarrow}
							alt="prev"
							onClick={movePrev}
						/>
					</div>
				) : ''
			}
		</div>
	);
};

export default VerticalCarousel;