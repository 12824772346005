import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-responsive-modal';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import { useLocation } from 'react-router-dom'

import axios from '../../../../axios';

import "./spreadsheet.css";

const EsyncSpreadSheet = (props) => {
	const { messages, addToMessageBox, setNextQues, setProgress, addToExhibitObjs, isWorkableExcel, startTime, startT, message, totalChatTime } = props;

	let location = useLocation();

	const [ss, setSS] = useState(1);
	const [excelData, setExcelData] = useState({});
	const [excelQuestion, setExcelQuestion] = useState('');
	const [spredSheetModalOpen, setSpredSheetModalOpen] = useState(false);

	const spread = useRef();

	useEffect(() => {
		let newData = {};
		if (isWorkableExcel && message.q.Workbook) {
			newData = { ...message.q };
		} else {
			newData = { ...messages.messages[0].options };
		}
		newData.Workbook.sheets[0].columns[0].width = 50;
		setExcelData({ ...newData });


		if (messages.messages) {
			if (messages.messages[0].q === '') {
				setExcelQuestion(messages.messages[2].q);
			} else {
				setExcelQuestion(messages.messages[0].q);
			}
		} else {
			for (let i = 0; i < messages.old.messages.length; i++) {
				if (messages.old.messages[i].q !== '' && !messages.old.messages[i].q.Workbook && messages.old.messages[i].qid === message.qid) {
					setExcelQuestion(messages.old.messages[i].q);
					break;
				}
			}
		}
	}, []);

	const handleSpreadSheetModalOpen = () => {
		setSS(ss + 1);
		setSpredSheetModalOpen(true);
	}

	const handleSpreadSheetModalClose = () => {
		spread.current.saveAsJson()
			.then(json => {
				setExcelData(json.jsonObject);
			});
		setSpredSheetModalOpen(false)
	};

	const sendToChatBox = (msglist, x) => {
		addToMessageBox([msglist[x]]);

		if (x > 0)
			setTimeout(() => { sendToChatBox(msglist, x - 1) }, 1500);
	}

	const onSubmit = () => {
		let optionsSelected = [{
			q: 'Excel submitted.',
			isMyMessage: true,
			ref: ""
		}];
		const url = '/userconvo';
		spread.current.saveAsJson()
			.then(json => {
				let getData = {
					user_id: location.state.code,
					comp: location.state.comp,
					message1: messages.messages[0].qid,
					message2: json.jsonObject,
					mode: 0
				};

				addToMessageBox(optionsSelected);
				axios
					.post(url, getData, { headers: { 'Content-Type': 'application/json' } })
					.then((res) => {
						let parsedMssg = res.data.msg1[0].map((ele, index) => {
							return ({
								q: ele,
								ref: "na"
							});
						});
						parsedMssg[parsedMssg.length - 1]["qtype"] = res.data.msg1[1];
						parsedMssg[parsedMssg.length - 1]["options"] = res.data.msg1[2];
						parsedMssg[parsedMssg.length - 1]["qid"] = res.data.msg1[3];
						parsedMssg[parsedMssg.length - 1]["exhibit"] = res.data.msg1[4];
						parsedMssg[parsedMssg.length - 1]["progress"] = res.data.msg1[5];
						let parsedRespMssg = res.data.msg2.split(";;").map((ele) => {
							return ({
								q: ele,
								ref: "na"
							});
						});
						let finalQues = [...parsedRespMssg.reverse()];
						sendToChatBox(finalQues, finalQues.length - 1);
						setTimeout(() => { setNextQues(parsedMssg.reverse()) }, (finalQues.length) * 1500);
						setProgress(parseInt(res.data.msg1[5], 10));
						addToExhibitObjs(res.data.msg1);;
					})
					.catch((err) => {
						console.log(err)
					})
			})

	}

	useEffect(() => {
		if (spread.current) {
			spread.current.openFromJson({ file: excelData });
		} else {
			if (spredSheetModalOpen) {
				setSS(ss + 1);
			}
		}
	}, [ss]);

	const renderTime = ({ remainingTime }) => {
		var timerhours = Math.floor(remainingTime / 3600);
		var timerminutes = -timerhours * 60 + Math.floor(remainingTime / 60);
		var timerseconds = -timerminutes * 60 - timerhours * 3600 + remainingTime;
		if (remainingTime === 0) {
			timerminutes = 0;
			timerseconds = 0;
		}
		return (
			<div className="timer" style={{ fontSize: "1rem", display: "flex", color: timerminutes >= 5 ? "#868686" : "red", fontWeight: "600", margin: "auto" }}>
				<div className="minutesvalue">{timerminutes < 10 ? `0${timerminutes}` : timerminutes}:</div>
				<div className="secondsvalue">{timerseconds < 10 ? `0${timerseconds}` : timerseconds}</div>
			</div>
		);
	};

	return (
		<div>
			<Button
				className="spreadSheetBtn"
				size="small"
				variant="contained"
				onClick={handleSpreadSheetModalOpen}
				style={{
					background: '#479BD2',
					fontWeight: 'bold',
					fontFamily: "Poppins",
					letterSpacing: "0.01em",
					borderRadius: '25px',
					padding: '5px 12px',
					fontSize: '16px',
					textTransform: 'none',
					marginLeft: isWorkableExcel ? 64 : 0
				}}
			>
				{
					isWorkableExcel ? 'Smart Editor' : 'Click here to open excel'
				}
			</Button>

			<Modal
				open={spredSheetModalOpen}
				onClose={handleSpreadSheetModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				center={true}
				classNames={{
					modal: 'syncSpreadSheetModal',
				}}
			>
				<div style={{ paddingRight: 80, display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ boxShadow: '0px 4px 4px 0px #00000040', padding: '10px 25px', borderRadius: 36, flex: 1, backgroundColor: '#fafafa', alignItems: 'center', marginRight: 40 }}>
						{
							excelQuestion.split('::').map(function (item, key) {
								return (
									<span key={key}>
										{item.split('***').map(function (item2, key2) {
											return (<span key={key2}>{item2.split('**')[0]}<b>{item2.split('**')[1]}</b></span>)

										})}
										<br />
									</span>
								)
							})
						}
					</div>
					<div>
						<CountdownCircleTimer
							isPlaying={true}
							duration={totalChatTime * 60}
							colors={[
								['#42B6D6', 1]
							]}
							strokeWidth={5}
							size={60}
							initialRemainingTime={totalChatTime * 60 - startT - (new Date().getTime() - startTime.getTime()) / 1000}
							trailColor="#C4C4C4"
							style={{ margin: "auto" }}
						>
							{renderTime}
						</CountdownCircleTimer>
					</div>
				</div>
				<div className='spreadSheet' style={{ margin: '25px 0 0 0', height: 'calc(100vh - 15rem' }}>
					<SpreadsheetComponent ref={spread}></SpreadsheetComponent>
				</div>
				{
					!isWorkableExcel ? (
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
							<div style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								color: 'red',
								fontStyle: 'italic'
							}}>
								Note: After writing the final answer, first press 'Enter' and then click on 'Submit'.
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center'
								}}>
								<Button
									size="small"
									variant="contained"
									onClick={onSubmit}
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 12px',
										fontSize: '16px',
										textTransform: 'none'
									}}
								>
									Submit
								</Button>
							</div>
						</div>
					) : ''
				}
			</Modal>
		</div>
	)
}

export default EsyncSpreadSheet;
