import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { Button } from '@mui/material';

import axios from '../../axios';

const ChangePassword = (props) => {
	const { open, onClose, handleGreenSnackClick, setSuccessMsg } = props;

	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [showChangePwdErr, setShowChangePwdErr] = useState('false');
	const [changePwdErrMsg, setChangePwdErrMsg] = useState('');
	const [changePwdErrors, setChangePwdErrors] = useState({
		newPassword: false,
		oldPassword: false
	});

	const onChangeOldPassword = (e) => {
		const newChangePwdErrors = { ...changePwdErrors };
		newChangePwdErrors.oldPassword = false;

		setOldPassword(e.target.value);
		setChangePwdErrors(newChangePwdErrors);
	}

	const onChangeNewPassword = (e) => {
		const newChangePwdErrors = { ...changePwdErrors };
		newChangePwdErrors.newPassword = false;

		setNewPassword(e.target.value);
		setChangePwdErrors(newChangePwdErrors);
	}

	const changePassword = () => {
		setChangePwdErrMsg('');

		if (validateChangePwdForm()) {
			const changePwdData = {
				newPassword,
				oldPassword
			};

			axios.post('/auth/change-password', changePwdData)
				.then(() => {
					setOldPassword('');
					setNewPassword('');
					setShowChangePwdErr(false);
					handleGreenSnackClick();
					setSuccessMsg('Password Updated Successfully');
					onClose();
				})
				.catch((err) => {
					setShowChangePwdErr(true);
					setChangePwdErrMsg('Error in updating pssword');
				})

		}
	}

	const validateChangePwdForm = () => {
		let validation = true;
		const newChangePwdErrors = { ...changePwdErrors };

		Object.keys(changePwdErrors).forEach((val) => {
			switch (val) {
				case 'oldPassword': if (oldPassword === '') {
					newChangePwdErrors.oldPassword = true;
					validation = false;
				}
					break;
				case 'newPassword': if (newPassword === '') {
					newChangePwdErrors.newPassword = true;
					validation = false;
				}
					break;
				default: newChangePwdErrors[val] = false;
			}
		});

		setChangePwdErrors(newChangePwdErrors);
		return validation;
	}

	return (
		<Modal open={open} onClose={onClose} classNames={{ modal: 'customModal-change-pwd', closeIcon: 'closeIcon' }}>
			<div className="modal-heading-workflow" style={{ fontFamily: "Mulish", "fontWeight": "600", "color": "#000000" }}>
				Change Password
			</div>
			{
				showChangePwdErr ? (
					<div style={{ color: 'red', fontSize: 14, marginTop: 15 }}>{changePwdErrMsg}</div>
				) : null
			}
			<div className="input-div-workflow">
				<input
					id="settingsFlow-selector-3"
					type="text"
					placeholder="Old Password"
					onChange={onChangeOldPassword}
					style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
					required
					value={oldPassword}
				/>
				<br />
				{
					changePwdErrors.oldPassword ? (
						<div style={{ color: 'red', fontSize: 14, marginLeft: 24 }}>Please Enter Old Password</div>
					) : null
				}
				<input
					id="settingsFlow-selector-4"
					type="text"
					placeholder="New Password"
					onChange={onChangeNewPassword}
					style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
					required
					value={newPassword}
				/><br />
				{
					changePwdErrors.newPassword ? (
						<div style={{ color: 'red', fontSize: 14, marginLeft: 24 }}>Please Enter New Password</div>
					) : null
				}
			</div>
			<div className="button-div">
				<Button
					id="settingsFlow-selector-5"
					style={{
						background: '#479BD2',
						fontWeight: 'bold',
						fontFamily: "Poppins",
						letterSpacing: "0.01em",
						borderRadius: '25px',
						padding: '5px 12px',
						fontSize: '16px',
						margin: '0 10px',
						color: '#fff'
					}}
					size="small"
					variant="contained"
					onClick={() => changePassword()}
				>
					Change
				</Button>
				<Button
					style={{
						background: '#479BD2',
						fontWeight: 'bold',
						fontFamily: "Poppins",
						letterSpacing: "0.01em",
						borderRadius: '25px',
						padding: '5px 12px',
						fontSize: '16px',
						margin: '0 10px',
						color: '#fff'
					}}
					size="small"
					variant="contained"
					onClick={onClose}
				>
					Cancel
				</Button>
			</div>
		</Modal>
	)
}

export default ChangePassword;
