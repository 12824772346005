import React from 'react';
import Modal from 'react-responsive-modal';
import { Button } from '@mui/material';

const EditPreScreeningBotNameModal = (props) => {
	const { editNameModal, onCloseEditNameModal, selectedRow, setEditName, editName, nameEdit } = props;

	const onChangeEditName = (e) => {
		setEditName(e.target.value);
	}

	return (
		<Modal open={editNameModal} onClose={onCloseEditNameModal} center>
			<p className="modal-text">
				Edit Pre Screening bot's name
			</p>
			{selectedRow.length === 1 ?
				(<>
					<div className="input-div-workflow">
						<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
						<input
							type="text"
							onChange={onChangeEditName}
							value={editName}
							placeholder="Name"
							style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
							required
						/>
					</div>
				</>)
				: 'Loading'}
			<div className="button-div">
				<Button
					className="modal-button"
					style={{
						background: '#479BD2',
						fontWeight: 'bold',
						fontFamily: "Poppins",
						letterSpacing: "0.01em",
						borderRadius: '25px',
						padding: '5px 12px',
						fontSize: '16px'
					}}
					size="small"
					variant="contained"
					onClick={() => nameEdit(selectedRow[0])}>
					<span style={{ fontSize: '16px' }}>Ok</span>
				</Button>
				<Button
					className="cancel-modal-button"
					style={{
						background: '#479BD2',
						fontWeight: 'bold',
						fontFamily: "Poppins",
						letterSpacing: "0.01em",
						borderRadius: '25px',
						padding: '5px 12px',
						fontSize: '16px'
					}}
					size="small"
					variant="contained"
					onClick={onCloseEditNameModal}>
					<span style={{ fontSize: '16px' }}>Cancel</span>
				</Button>
			</div>
		</Modal>
	)
}

export default EditPreScreeningBotNameModal;
