import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import AccountMenu from "./accountMenu/AccountMenu";
import NotificationBell from "./notificationBell/notificationBell";
import { getDriveDetails } from '../selectors/app';

import '../styles/driveDetails.scss';

const DriveDetails = (props) => {
	const { driveDetails, notification } = props;

	const location = useLocation();

	const [selectedWorkFunction, setSelectedWorkFunction] = useState('');

	useEffect(() => {
		if (driveDetails.productId && driveDetails.productId.workfunction) {
			setSelectedWorkFunction(driveDetails.productId.workfunction[0].value);
		}
	}, [driveDetails]);

	return (
		<div className="driveDetails">
			<div className="inner-div" style={{ marginBottom: '15px' }}>
				<div className='top-icon-box'>
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div>
			</div>
			<div className="userListTitleContainer" style={{ padding: "20px 20px 0" }}>
				<div className="inner-div-details">
					<h2>Drive Details</h2>
				</div>
			</div>
			<div style={{ background: "#fff", borderRadius: 33, boxShadow: '7px 9px 9px rgba(0, 0, 0, 0.25)', margin: '0 15px', padding: 40 }}>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 40 }}>
					<div style={{ fontSize: 24, fontWeight: '400', color: '#8b8b8b' }}>{location.state.workflowName}</div>
					<div style={{ fontSize: 12, fontWeight: '600', color: '#8b8b8b' }}>Date: {new Date(driveDetails.createdDate).toLocaleDateString()}</div>
				</div>
				<div style={{ fontSize: 16, color: '#999', marginBottom: 50, fontWeight: '700' }}>{driveDetails.description}</div>
				{
					driveDetails.productId && driveDetails.productId.workfunction ? (
						<>
							<div style={{ color: "676767", fontWeight: '700', fontSize: 20, marginBottom: 40 }}>
								Role: {driveDetails.productId.role}
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 40 }}>
								<div style={{ fontWeight: '700', fontSize: 16 }}>Critical work function: </div>
								{
									driveDetails.productId && driveDetails.productId.workfunction ? driveDetails.productId.workfunction.map((item) => (
										<div
											style={{
												marginLeft: 15,
												padding: '5px 10px',
												borderRadius: 20,
												fontSize: 14,
												color: (item.value === selectedWorkFunction ? '#fff' : '#000'),
												backgroundColor: (item.value === selectedWorkFunction ? '#479BD2' : '#F5F5F5'),
												cursor: 'pointer'
											}}
											onClick={() => setSelectedWorkFunction(item.value)}
											key={item.value}
										>
											{item.label}
										</div>
									)) : ''
								}
							</div>
							<div style={{ backgroundColor: '#F8F8F8', padding: '20px 24px', borderRadius: 40 }}>
								{
									driveDetails.productId && driveDetails.productId.keyTask && selectedWorkFunction ? driveDetails.productId.keyTask[driveDetails.productId.role][selectedWorkFunction].map((item) => (
										<div className='keyTask' key={item}>{item}</div>
									)) : ''
								}
							</div>
						</>
					) : ''
				}
			</div>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	driveDetails: getDriveDetails()
});


const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DriveDetails);
