import React, { useEffect, useState } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Modal from 'react-responsive-modal';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Collapse, IconButton, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { styled } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';

import axios from '../../axios';
import EmptyTab from "../reports/EmptyTab";
import InterviewerGuide from './InterviewerGuide';
import PdfReport from '../PdfReport';
import ReportTab from '../reports/ReportTab';
import VideoCard from '../../pages/videoSubmission/videoCard';
import { getCompanyFeatures } from '../../selectors/app';
import { redirectUnauthorisedUser } from '../../helper/helperFunction';

import downloadactiveicon from '../../assets/downloadactive.png';
import { checkCompanyFeatureAccess } from '../../utils/featureAccess';
import FeatureLockedWrapper from './FeatureLockedWrapper';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const AnalyseModal = (props) => {
    const { open, onClose, chat, changeStatus, doughnutRef, barChartRef, isOpen, companyFeatures } = props;

    const history = useHistory();
    const location = useLocation();

    const [currentReportTab, setCurrentReportTab] = useState(0);
    const [expanded, setExpanded] = useState("");
    const [videoCardData, setVideoCardData] = useState([]);

    const handleReportScroll = () => {
        if (currentReportTab != 0) return;

        const scrollDown = document.getElementById('scroll-down-animation');

        if (scrollDown) {
            scrollDown.style.display = "none";
        }
    }

    const getQuestionText = (row, cond) => {
        let txt = ''

        if (Array.isArray(row.ques)) {
            let ele = row.ques[row.ques.length - 1].split('***::')
            txt = ele[ele.length - 1]
        }
        else if (typeof (row.ques) == 'string') {
            txt = row.ques.split('***::')[row.ques.split('***::').length - 1]
        }

        if (!cond && txt.length > 200) {
            txt = txt.substring(0, 200) + '...'
        }
        return txt.replaceAll(';;', ' ').replaceAll('::', ' ')
    }

    const handleExpandClick = index => {
        if (expanded === index) {
            setExpanded("")
        } else {
            setExpanded(index)
        }
    }

    const getCandVideo = (key) => {
        axios.post('/cand-video', { key }).then((res) => {
            window.open(res.data.signedUrl)
        }).catch((err) => {
            redirectUnauthorisedUser(err, history);
        })
    }

    useEffect(() => {
        if (!chat.test) return;

        async function addVideoCardData() {
            let tempArr = [];

            for (let i = 0; i < chat.test.chat.length; i++) {
                let val = chat.test.chat[i];

                if (val.ans && !val.ans.Workbook && val.ans.includes('.mp4')) {
                    let res = await axios.post('/cand-video', { key: val.ans })
                    let tempData = { ques: val.ques, score: val.score, src: res.data.signedUrl, cid: val.cid, notes: val.notes };
                    tempArr.push(tempData);
                }
            }

            setVideoCardData(tempArr);
        }
        addVideoCardData();
    }, [chat]);

    useEffect(() => {
        if (companyFeatures['reports']) {
            setCurrentReportTab(0);
        } else if (companyFeatures['videoSubmissions']) {
            setCurrentReportTab(1);
        } else if (companyFeatures['transcripts']) {
            setCurrentReportTab(2);
        } else if (companyFeatures['interviewersGuide']) {
            setCurrentReportTab(3);
        } else {
            setCurrentReportTab(-1);
        }
    }, [companyFeatures]);

    return (
        <Modal open={open} onClose={onClose} center classNames={{ modal: 'customModalChats', closeIcon: 'closeIcon-large' }} styles={{ modal: { width: 900 } }}>
            <div className="container-team" style={{ padding: "0 20px 20px", margin: 0, height: 625 }}>
                <div className="heading-team">
                    <div>
                        <span style={{ fontSize: "25px", fontWeight: "600", color: "#479BD2" }}>{chat.name}</span>
                        {chat.test ?
                            <span style={{ borderLeft: "1px solid rgb(150, 150, 150)", marginLeft: "10px", paddingLeft: "10px", fontSize: "16px", fontWeight: "500" }}>
                                Time taken: {Math.floor(((Math.abs(new Date(chat.test.endDate) - new Date(chat.test.createdDate))) / 1000) / 60)} mins
                            </span>
                            : ""}
                    </div>
                    <div>
                        <Tooltip title="Shortlist"><IconButton><CheckCircleOutlineOutlinedIcon onClick={(e) => { e.stopPropagation(); changeStatus('Shortlisted', [chat]) }} className="check-btn" /></IconButton></Tooltip>
                        <Tooltip title="Reject"><IconButton><CancelOutlinedIcon onClick={(e) => { e.stopPropagation(); changeStatus('Rejected', [chat]) }} className="cancel-btn" /></IconButton></Tooltip>
                        <Tooltip title="Keep in view"><IconButton><RemoveRedEyeOutlinedIcon onClick={(e) => { e.stopPropagation(); changeStatus('Kept In View', [chat]) }} className="visibility-btn" /></IconButton></Tooltip>
                    </div>
                </div>
                <div>
                    <div className="report-tabs-container">
                        <div className="report-tabs dashboard-forth-step">
                            {
                                companyFeatures['reports'] ? (
                                    <div
                                        className={currentReportTab == 0 ? 'active-report-tab' : ''}
                                        onClick={() => setCurrentReportTab(0)}>
                                        Report
                                    </div>
                                ) : ''
                            }
                            {
                                companyFeatures['videoSubmissions'] ? (
                                    <div
                                        className={currentReportTab == 1 ? 'active-report-tab' : ''}
                                        onClick={() => setCurrentReportTab(1)}>
                                        Video submissions
                                    </div>
                                ) : ''
                            }
                            {
                                companyFeatures['transcripts'] ? (
                                    <div
                                        className={currentReportTab == 2 ? 'active-report-tab' : ''}
                                        onClick={() => setCurrentReportTab(2)}>
                                        Chats
                                    </div>
                                ) : ''
                            }
                            {
                                companyFeatures['interviewersGuide'] ? (
                                    <div
                                        className={currentReportTab == 3 ? 'active-report-tab' : ''}
                                        onClick={() => setCurrentReportTab(3)}>
                                        Interviewer's guide
                                    </div>
                                ) : ''
                            }
                        </div>

                        {
                            !isOpen && checkCompanyFeatureAccess(companyFeatures, 'downloadReport') > 1 ? (
                                <PDFDownloadLink document={<PdfReport doughnutRef={doughnutRef} barChartRef={barChartRef} candidateData={chat} />} fileName={chat.name ? `${chat.name.split(' ').join('_')}_${location.state.workflowName.split(' ').join('_')}.pdf` : 'report.pdf'}>
                                    {({ blob, url, loading, error }) =>
                                        loading ? (
                                            <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                                <img src={downloadactiveicon} alt="" style={{ width: "24px", height: "24px" }} />
                                                <p>Download</p>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                                <img src={downloadactiveicon} alt="" style={{ width: "24px", height: "24px" }} />
                                                <p>Download</p>
                                            </div>
                                        )
                                    }
                                </PDFDownloadLink>
                            ) : ''
                        }
                    </div>
                </div>
                <div className="scrollblue" onScroll={handleReportScroll} style={{ marginTop: "20px", maxHeight: "450px", overflowY: "auto", overflowX: "hidden", padding: "10px 10px 10px 20px" }}>
                    {
                        currentReportTab == 2 ?
                            chat?.test?.chat?.length > 0 ? chat.test.chat.map((row, index) => {
                                return <div className="row-team">
                                    <div style={{ display: "flex", marginBottom: "15px" }}>
                                        <p style={{ flex: "1", fontWeight: "600" }}>
                                            Q.{index + 1} &nbsp;
                                            {getQuestionText(row, index === expanded)}
                                        </p>
                                        <div style={{ width: "50px" }}>
                                            <ExpandMore
                                                expand={index === expanded}
                                                onClick={() => { handleExpandClick(index) }}
                                                aria-expanded={index === expanded}
                                                aria-label="expand"
                                            >
                                                <ExpandMoreIcon />
                                            </ExpandMore>
                                        </div>
                                    </div>
                                    <Collapse in={index === expanded} timeout="auto">
                                        {(typeof row.ans === 'string' || row.ans instanceof String) ? <div style={{ color: "rgb(110,110,110)" }}>
                                            <b>Answer- </b> {row.ans.includes('.mp4') ? <a style={{ color: 'inherit' }} href="javascript:void(0)" target="_blank" onClick={(e) => { e.preventDefault(); getCandVideo(row.ans) }}>Click Here</a> : row.ans.replaceAll('::', ". ")}
                                        </div> : null}
                                    </Collapse>
                                </div>
                            })
                                : <EmptyTab text={"Couldn't find chats for this candidate"} />
                            : ""
                    }
                    {
                        currentReportTab == 1 ?
                            chat?.test?.chat?.length > 0 ?
                                (<div>
                                    <div className="videobox-wrapper">
                                        {videoCardData.length == 0 ? 'No Video' : videoCardData.map((val) => <VideoCard ques={val.ques} src={val.src} score={val.score} cid={val.cid} notes={val.notes} candidateData={chat} />)}
                                    </div>
                                </div>)
                                : <EmptyTab text={"Couldn't find video submission for this candidate"} />
                            : ""
                    }
                    {
                        currentReportTab == 0 ?
                            chat?.test?.chat?.length > 0 ?
                                <>
                                    {
                                        checkCompanyFeatureAccess(companyFeatures, 'reports') === 1 ? (
                                            <div style={{ position: 'relative' }}>
                                                <div style={{ filter: 'blur(4px)' }}>
                                                    <ReportTab candidateData={chat} setCurrentReportTab={setCurrentReportTab} />
                                                </div>
                                                <FeatureLockedWrapper />
                                            </div>
                                        ) : (
                                            <ReportTab candidateData={chat} setCurrentReportTab={setCurrentReportTab} />
                                        )
                                    }
                                </>
                                : <EmptyTab text={"Couldn't find report for this candidate"} />
                            : ""
                    }
                    {
                        currentReportTab == 3 ? (
                            <>
                                {
                                    checkCompanyFeatureAccess(companyFeatures, 'interviewersGuide') === 1 ? (
                                        <div style={{ position: 'relative' }}>
                                            <div style={{ filter: 'blur(4px)' }}>
                                                <InterviewerGuide productId={chat.productId} />
                                            </div>
                                            <FeatureLockedWrapper />
                                        </div>
                                    ) : (
                                        <InterviewerGuide productId={chat.productId} />
                                    )
                                }
                            </>
                        ) : ""
                    }
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = createStructuredSelector({
    companyFeatures: getCompanyFeatures()
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseModal);
