import React, { useState, useEffect, useRef } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import D3Funnel from 'd3-funnel';
import { useLocation, useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useTour } from '@reactour/tour';

import axios from "../../axios";
import BarC from './BarChart.jsx';
import NotificationBell from "../notificationBell/notificationBell";
import AccountMenu from "../accountMenu/AccountMenu";
import Funnel from '../../components/dashboard/Funnel';
import { redirectUnauthorisedUser } from '../../helper/helperFunction';

import './Analytics.css';


function QuickMenu(props) {
	const { notification } = props;

	const { isOpen } = useTour();
	const location = useLocation();
	const history = useHistory();

	const [workflow, setWorkflow] = useState();
	const [isLoading, setLoading] = useState(true);
	const [finalData, setFinalData] = useState();
	const [rating, setRating] = useState('--');
	const [rows1, setRows1] = useState([]);
	const [rows2, setRows2] = useState([]);
	const [funnelData, setFunnelData] = useState();

	const fin = useRef();

	useEffect(() => {
		if (finalData)
			setLoading(false)
	}, [finalData])

	useEffect(() => {
		axios.post("/getfeedback/", { workflow: location.state.workflowId })
			.then((res) => {
				if (res.data > 0.0)
					setRating(res.data.toFixed(2))
			}).catch((err) => {
				console.log(err)
				redirectUnauthorisedUser(err, history);
			});
	}, [])


	useEffect(() => {
		axios.get(("/workflow/" + location.state.workflowId))
			.then((res) => {
				setWorkflow(res.data)
				setFinalData([
					<tr>
						<td>
							<div style={{ width: "15px", height: "15px", backgroundColor: '#4FCA64', borderRadius: "50%" }}></div>
						</td>
						<td style={{ fontSize: '16px' }}>Invited</td>
						<td style={{ fontSize: '16px' }}>{res.data.candidates.length}</td>
						<td><Link style={{ marginLeft: '10px', textDecoration: "none", width: "100%", color: "black", fontSize: '16px', fontWeight: '600' }} to={{ pathname: "/applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "quickLook" } }} id="analyticsFlow-selector-1">View full list <ArrowForwardIcon style={{ height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#4FCA64` }} /></Link></td>
					</tr>,
					<tr>
						<td>
							<div style={{ width: "15px", height: "15px", backgroundColor: `#54C5EB`, borderRadius: "50%" }}></div>
						</td>
						<td style={{ fontSize: '16px' }}>Submitted</td>
						<td style={{ fontSize: '16px' }}>{res.data.hired.length + res.data.rejected.length + res.data.shortlisted.length + res.data.review.length + res.data.kiv.length}</td>
						<td><Link style={{ marginLeft: '10px', textDecoration: "none", width: "100%", color: "black", fontSize: '16px', fontWeight: '600' }} to={{ pathname: "/review-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "quickLook" } }} id="analyticsFlow-selector-2">View full list <ArrowForwardIcon style={{ height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#54C5EB` }} /></Link></td>
					</tr>,
					<tr>
						<td>
							<div style={{ width: "15px", height: "15px", backgroundColor: `#FFB74A`, borderRadius: "50%" }}></div>
						</td>
						<td style={{ fontSize: '16px' }}>Shortlisted</td>
						<td style={{ fontSize: '16px' }}>{res.data.shortlisted.length}</td>
						<td><Link style={{ marginLeft: '10px', textDecoration: "none", width: "100%", color: "black", fontSize: '16px', fontWeight: '600' }} to={{ pathname: "/finalized", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "quickLook" } }} id="analyticsFlow-selector-3">View full list <ArrowForwardIcon style={{ height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#FFB74A` }} /></Link></td>
					</tr>,
					<tr>
						<td>
							<div style={{ width: "15px", height: "15px", backgroundColor: `#22242C`, borderRadius: "50%" }}></div>
						</td>
						<td style={{ fontSize: '16px' }}>Hired</td>
						<td style={{ fontSize: '16px' }}>{res.data.hired.length}</td>
					</tr>]);
				const dataf = [
					{ label: 'Invited', value: 100 },
					{ label: 'Submitted', value: ((res.data.review.length + res.data.shortlisted.length + res.data.hired.length + res.data.rejected.length + res.data.kiv.length) * 100.0 / res.data.candidates.length).toFixed() },
					{ label: 'Shortlisted', value: (res.data.shortlisted.length * 100.0 / res.data.candidates.length).toFixed() },
					{ label: 'Hired', value: (res.data.hired.length * 100.0 / res.data.candidates.length).toFixed() },
				];
				const optionf = {
					block: {
						dynamicHeight: true,
						dynamicSlope: false,
						minHeight: 10,
						fill: {
							type: 'gradient',
							scale: ['#4FCA64', '#54C5EB', '#FFB74A', '#22242C']
						}
					},
					tooltip: {
						enabled: true,
						format: '{l} : {f}%'
					},
					label: { enabled: false },
					chart: {
						curve: {
							height: 15,
							enabled: true
						},
						totalCount: 200,
						inverted: false
					}
				};
				setFunnelData(<div className="chart" ref={fin} id='chart1' style={{ height: "150px" }}></div>)
				const chart = new D3Funnel(fin.current)
				chart.draw(dataf, optionf)
			})
			.catch((err) => {
				console.log(err)
				redirectUnauthorisedUser(err, history);
			})
		let datax = {
			status: "shortlisted",
			id: location.state.workflowId
		}
		let datay = {
			status: "candidates",
			id: location.state.workflowId
		}
		axios.post("/gettype", datax)
			.then((res) => {
				setRows2(res.data)
			})
			.catch(err => {
				redirectUnauthorisedUser(err, history);
			})
		axios.post("/gettype", datay)
			.then((res) => {
				if (res.data.length > 3)
					setRows1(res.data)
				else
					setRows1(res.data)
			})
			.catch(err => {
				redirectUnauthorisedUser(err, history);
			})
	}, []);

	function renderData() {
		return finalData;
	}

	function renderFunnelData() {
		return funnelData;
	}

	return (
		<div className='quick-menu-handler'>
			<div className="inner-div" style={{ marginBottom: '10px' }}>
				<div className='top-icon-box'>
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div>
			</div>
			<div style={{ padding: "20px 20px 0" }}>
				<div className="inner-div-details" style={{ marginBottom: '10px' }}>
					<h2>Analytics</h2>
					<p>{!isLoading ? workflow.name : null}</p>
				</div>
			</div>
			<div className="container-div" style={{ boxSizing: "border-box", overflowY: "auto", height: "100%" }}>
				<div className="chart-outer-div" style={{ height: "max-content" }}>
					<div className="chart-left-div">
						<div className="chart-inner-div">
							<div className="title">
								<h3>Overview</h3>
							</div>
						</div>
						{renderFunnelData()}
						{/* {workflow && !isOpen ? (
							<div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
								<Funnel
									width={400}
									invited={workflow.candidates.length}
									submitted={workflow.review.length}
									shortlisted={workflow.shortlisted.length}
									hired={workflow.hired.length}
								/>
							</div>
						) :
							(<div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
								<Funnel
									width={400}
									invited={50}
									submitted={32}
									shortlisted={23}
									hired={15}
								/>
							</div>)} */}
					</div>
					<div className="chart-right-div">
						<table>
							<tbody>
								{!isOpen ? "" :
									<>
										<tr>
											<td>
												<div style={{ width: "15px", height: "15px", backgroundColor: '#4FCA64', borderRadius: "50%" }}></div>
											</td>
											<td style={{ fontSize: '16px' }}>Invited</td>
											<td style={{ fontSize: '16px' }}>{50}</td>
											<td><Link style={{ marginLeft: '10px', textDecoration: "none", width: "100%", color: "black", fontSize: '16px', fontWeight: '600' }} to={{ pathname: "/applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "quickLook" } }}>View full list <ArrowForwardIcon style={{ height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#4FCA64` }} /></Link></td>
										</tr>
										<tr>
											<td>
												<div style={{ width: "15px", height: "15px", backgroundColor: `#54C5EB`, borderRadius: "50%" }}></div>
											</td>
											<td style={{ fontSize: '16px' }}>Submitted</td>
											<td style={{ fontSize: '16px' }}>{32}</td>
											<td><Link style={{ marginLeft: '10px', textDecoration: "none", width: "100%", color: "black", fontSize: '16px', fontWeight: '600' }} to={{ pathname: "/review-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "quickLook" } }}>View full list <ArrowForwardIcon style={{ height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#54C5EB` }} /></Link></td>
										</tr>
										<tr>
											<td>
												<div style={{ width: "15px", height: "15px", backgroundColor: `#FFB74A`, borderRadius: "50%" }}></div>
											</td>
											<td style={{ fontSize: '16px' }}>Shortlisted</td>
											<td style={{ fontSize: '16px' }}>{23}</td>
											<td><Link style={{ marginLeft: '10px', textDecoration: "none", width: "100%", color: "black", fontSize: '16px', fontWeight: '600' }} to={{ pathname: "/finalized", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "quickLook" } }}>View full list <ArrowForwardIcon style={{ height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#FFB74A` }} /></Link></td>
										</tr>
										<tr>
											<td>
												<div style={{ width: "15px", height: "15px", backgroundColor: `#22242C`, borderRadius: "50%" }}></div>
											</td>
											<td style={{ fontSize: '16px' }}>Hired</td>
											<td style={{ fontSize: '16px' }}>{15}</td>
										</tr>
									</>
								}
								{isOpen ? "" : renderData()}
							</tbody>
						</table>
					</div>
				</div>

				<div className="chart-outer-div2" style={{ marginTop: "20px" }}>
					<div className="chart-left-div">
						<div className="chart-inner-div">
							<div className="title">
								<h3>Insights</h3>
							</div>
						</div>
						<div >{workflow ? <BarC workflow={workflow} style={{ height: "30vh" }} /> : ''}</div>
					</div>
					<div className="chart-right-div">
						<table style={{ fontSize: "14px" }}>
							<tbody>
								<tr>
									<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginBottom: "5vh" }}>
										<div style={{ width: "8vh", height: "8vh", borderRadius: "50%", background: 'rgba(52, 188, 212, 1)', color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1.8vh" }}>
											{process.env.REACT_APP_BASE_URL.includes('test.hirequotient.org') ? "$1100" : "$"}
										</div>
										<div style={{ textAlign: "center", fontWeight: "600", color: "#334D6E" }}>
											&nbsp;&nbsp;Saved&nbsp;
										</div>
										<div style={{ textAlign: "center", color: "#888" }}>
											so far
										</div>
									</div>
								</tr>
								<tr>
									<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginBottom: "5vh" }}>
										<div style={{ width: "8vh", height: "8vh", borderRadius: "50%", background: 'rgba(52, 188, 212, 1)', color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1.8vh" }}>
											{process.env.REACT_APP_BASE_URL.includes('test.hirequotient.org') ? "4.7" : rating}
										</div>
										<div style={{ textAlign: "center", fontWeight: "600", color: "#334D6E" }}>
											&nbsp;&nbsp;Overall rating&nbsp;
										</div>
										<div style={{ textAlign: "center", color: "#888" }}>
											given by candidates globally
										</div>
									</div>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
};

export default QuickMenu;
