import React, { useState } from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import axios from '../axios';
import Loader from '../components/common/Loader';
import Error from '../components/common/Error';
import { validateDemoInput } from '../utils/validation/validateUtils';

import logo from '../assets/Logo.png';
import typing from '../assets/typing.png';

import '../styles/signIn.css';
import '../styles/btn.css';
import '../styles/demoSignIn.scss';

const DemoSignIn = (props) => {
	const { workflowId } = props;

	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	let history = useHistory();

	const handleSignIn = (e) => {
		e.preventDefault();

		setIsError(false);
		setIsLoading(true);
		
		const data = {
			email,
			name,
			workflowId
		};
		const errorResult = validateDemoInput(data);

		if (!errorResult.isValid) {
			setIsLoading(false);
			setIsError(true);
			Object.keys(errorResult.errors).forEach((item) => {
				if (item) {
					setErrorMsg(errorResult.errors[item]);
					return;
				}
			});
			
			return;
		}

		axios
			.post('/democandidate', data)
			.then((response) => {
				setIsLoading(false);

				history.push(response.data.message);
			})
			.catch((error) => {
				setIsError(true);
				setErrorMsg(error.response.data.message);
				setIsLoading(false);
				console.log(error)
			});
	}
	return (
		<div id="demoSignInPage">
			<form style={{ width: "100%", flex: "1" }} onSubmit={handleSignIn}>
				<div className='demo-sign-in-wrapper' style={{ height: "100%", padding: "0 20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
					<div className='demo-sign-in-left'>
						<div style={{ marginBottom: "50px" }}>
							<img
								src={logo}
								alt="logo"
								style={{
									maxWidth: "150px",
									marginBottom: "10px"
								}}
							/>
							<h1
								style={{
									fontSize: '30px',
									color: '#008095',
									marginBottom: '15px',
									fontWeight: '800'
								}}
							>
								Start interview
							</h1>
						</div>
						<div>
							<div style={{ marginBottom: "20px" }}>
								<input
									style={{ background: "transparent", borderBottom: "1.5px solid rgba(0,0,0,0.2)", borderRadius: "0" }}
									required
									className="signinInput"
									placeholder='Full name'
									type='text'
									value={name}
									onChange={(e) => setName(e.target.value)}
								></input>
							</div>
							<div style={{ marginBottom: "20px" }}>
								<input
									style={{ background: "transparent", borderBottom: "1.5px solid rgba(0,0,0,0.2)", borderRadius: "0" }}
									required
									placeholder='Email Address'
									className="signinInput"
									type='email'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								></input>
							</div>
							<div style={{ marginTop: "40px" }}>
								<Button
									size="small"
									variant="contained"
									style={{
										background: '#008095',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '15px',
										padding: '5px 50px',
										fontSize: '16px',
										backgroundImage: "linear-gradient(to right, #23597C, #008095)"
									}}
									type='submit'
									disabled={isLoading}
								>
									Start
								</Button>
							</div>
							<div style={{ minHeight: "55px", margin: "1rem" }}>
								{isLoading && <Loader />}
								{isError && <Error errorMsg={errorMsg} />}
							</div>
						</div>
					</div>
					<div className='demo-sign-in-right' style={{ maxWidth: "50%" }}>
						<img
							src={typing}
							alt="logo"
							style={{ width: "100%", maxWidth: "500px" }}
						/>
					</div>
				</div>
			</form>
			<div style={{ width: "100%", height: "20px", backgroundColor: "#23597C" }}></div>
		</div>
	)
}

DemoSignIn.propTypes = {
	workflowId: PropTypes.string
}

export default DemoSignIn;
