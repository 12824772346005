import React, { useState, useEffect, useRef } from "react";
import Alert from '@mui/material/Alert';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@material-ui/core/TextField';
import { Button } from "@material-ui/core";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CSVLink } from "react-csv";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import { useLocation, useHistory } from 'react-router-dom';
import { useTour } from '@reactour/tour';

import AccountMenu from "../accountMenu/AccountMenu";
import NotificationBell from "../notificationBell/notificationBell";
import {
	getCandidatesByType as getCandidatesByTypeRequest,
	editCandidateName as editCandidateNameRequest,
	resetSuccessMsg as resetSuccessMsgRequest,
	resetRerender as resetRerenderRequest,
	deleteCandidate as deleteCandidateRequest
} from '../../actions/app';
import { getCandidatesList, getRerender, getUserRole } from '../../selectors/app';
import { useWindowDimensions } from '../../utils/windowUtils';

import Edit from '../../assets/Icons/Edit.png';
import emptyRowImg from '../../assets/emptyrow2.png';
import InfoIcon from '../../assets/info-roles.png';
import Subtract from '../../assets/Icons/Subtract.png';
import CandidateCVIcon from '../../assets/candidate-cv.png';

import "./Applicants.css";

const escapeRegExp = (value) => {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const QuickSearchToolbar = (props) => {
	const { value, onChange, clearSearch } = props;

	return (
		<div className="root">
			<div>
				<GridToolbarFilterButton />
			</div>
			<TextField
				variant="standard"
				value={value}
				onChange={onChange}
				placeholder="Search…"
				InputProps={{
					startAdornment: <SearchIcon fontSize="small" />,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{ visibility: value ? 'visible' : 'hidden' }}
							onClick={clearSearch}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					),
				}}
			/>
		</div>
	);
}

QuickSearchToolbar.propTypes = {
	clearSearch: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};

const Applicants = (props) => {
	const {
		candidates,
		getCandidatesByType,
		rerender,
		editCandidateName,
		resetRerender,
		deleteCandidate,
		notification,
		userRole
	} = props;

	const { isOpen } = useTour();
	const location = useLocation();
	const history = useHistory();

	const editNameRef = useRef(null);

	const [deleteModal, setDeleteModal] = useState(false);
	const [change, setChange] = useState(0);
	const [data, setData] = useState([]);
	const [rows, setRows] = useState(data);
	const [CSVData, setCSVData] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [selectionModel, setSelectionModel] = useState([]);
	const [deleteCandId] = useState();
	const [pageSize, setPageSize] = useState(15);
	const [loading] = useState(false);
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackErr, setSnackErr] = useState(null);
	const [selectedRow, setSelectedRow] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [successMsg, setSuccessMsg] = useState(null);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [showStatusModal, setShowStatusModal] = useState(false);

	const onCloseDeleteModal = () => setDeleteModal(false);

	useEffect(() => {
		let temp = [];

		if (rows.length == 0) return;

		rows.map((val) => {
			let x = { name: val.name, email: val.email, id: val.id, status: val.status, score: val.score }
			temp.push(x);
		});

		setCSVData(temp);
	}, [rows])

	useEffect(() => {
		const reqData = {
			status: "candidates",
			id: location.state.workflowId
		};

		getCandidatesByType({ reqData, history });
	}, [change])

	useEffect(() => {
		if (!candidates) return;

		setData(candidates);
		setRows(candidates);
	}, [candidates])

	const nameEdit = function (row) {
		editCandidateName({ row, newName: editNameRef.current.value, history })
	}

	const handleDelete = (rowSelected) => {
		const IDs = [];
		rowSelected.map((val) => {
			IDs.push(val._id);
		});

		deleteCandidate({ id: IDs, history });
	};

	useEffect(() => {
		if (!rerender) return;

		setChange(change + 1);
		resetRerender();

		if (editModal) onCloseEditModal();
		if (deleteModal) onCloseDeleteModal();

	}, [rerender]);

	const getScore = function (params) {
		var score = 0.0;
		var index = 0;

		try {
			if (params.row.test && params.row.test.result && params.row.test.result.hasOwnProperty('total'))
				score = params.row.test.result.total
			else {
				if (params.row.productId.toLowerCase().includes('sale')) {
					if (params.row.test.result.dab)
						score += 0.2 * params.row.test.result.dab
					if (params.row.test.result.lpg)
						score += 0.2 * params.row.test.result.lpg
					if (params.row.test.result.stk)
						score += 0.1 * params.row.test.result.stk
					if (params.row.test.result.ema)
						score += 0.5 * params.row.test.result.ema
				}
				else {
					for (let keys in params.row.test.result) {
						score += params.row.test.result[keys]
						index += 1
					}
					if (index > 0) score = score / index
				}
			}
			if (score > 0) score *= 100
		} catch {
			console.log("Error in scores");
		}
		return score;
	}

	const setScore = function (params) {
		var score = 0.0;
		var index = 0;

		try {
			if (params.row.test && params.row.test.result && params.row.test.result.hasOwnProperty('total'))
				score = params.row.test.result.total
			else {
				if (params.row.productId.toLowerCase().includes('sale')) {
					if (params.row.test.result.dab)
						score += 0.2 * params.row.test.result.dab
					if (params.row.test.result.lpg)
						score += 0.2 * params.row.test.result.lpg
					if (params.row.test.result.stk)
						score += 0.1 * params.row.test.result.stk
					if (params.row.test.result.ema)
						score += 0.5 * params.row.test.result.ema
				}
				else {
					for (let keys in params.row.test.result) {
						score += params.row.test.result[keys]
						index += 1
					}
					if (index > 0) score = score / index
				}
			}
			if (score > 0) score *= 100
		}
		catch {
			console.log("Error in scores");
		}
		return { ...params.row, score: score }
	}

	const columns = [
		{
			field: "id",
			headerName: "ID",
			width: useWindowDimensions().width / 14,
			headerAlign: 'center',
			align: 'center',
			type: 'number',
			sortable: true,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ marginRight: "5ch", width: "100%" }}>
						{params.row.id}
					</div>
				)
			}
		},
		{
			field: "name",
			headerName: "Name",
			minWidth: useWindowDimensions().width / 6,
			flex: 0.5,
			renderCell: (params) => {
				return (
					<div className="productListUser" style={{ fontSize: 16, fontWeight: "400", display: 'flex', alignItems: 'center' }}>
						<div>{params.row.name}</div>
						{
							params.row.resume ? (
								<div style={{ marginLeft: 15, display: 'flex' }}><a onClick={(e) => { e.stopPropagation() }} href={`https://allhqclientpics.s3.ap-southeast-1.amazonaws.com/${params.row.resume}`} download={`CV_${params.row.name.split(' ').join('_')}`}><img src={CandidateCVIcon} style={{ height: 20 }} alt='' /></a></div>
							) : ''
						}
					</div>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			minWidth: 100,
			flex: 0.5,
			renderCell: (params) => {
				return (
					<div className="productListUser" style={{ fontSize: "16px", fontWeight: "400" }}>
						{params.row.email}
					</div>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			minWidth: useWindowDimensions().width / 12,
			headerAlign: 'center',
			align: 'center',
			flex: 0.5,
			renderHeader: (params) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<strong>
						{'Status'}
					</strong>
					<div onClick={(e) => { e.stopPropagation(); setShowStatusModal(true) }} style={{ display: 'flex', marginLeft: 10 }}>
						<img src={InfoIcon} alt='' style={{ height: 20 }} />
					</div>
				</div>
			),
			renderCell: (params) => {
				if (!isNaN(params.value)) {
					return (
						<div className="userListUser" style={{ marginRight: "4ch", width: "100%" }}>
							{params.value}% completed
						</div>
					);
				} else {
					return (
						<div className="userListUser" style={{ marginRight: "4ch", width: "100%" }}>
							{params.value === 'Complete' ? 'Submitted' : (params.value === 'In Progress' ? 'Opened (0% completed)' : params.value)}
						</div>
					);
				}
			}
		},
		{
			field: "score",
			headerName: "Score",
			headerAlign: "center",
			align: 'center',
			type: 'number',
			sortable: true,
			minWidth: useWindowDimensions().width / 12,
			flex: 0.5,
			valueSetter: setScore,
			valueGetter: getScore,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ marginRight: "4ch", width: "100%" }}>
						{params.value.toFixed()}%
					</div>
				);
			},
		},
	];

	const handleSnackClick = () => {
		setSnackOpen(true);
	};

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	useEffect(() => {
		if (selectionModel.length == 0) setSelectedRow([]);
		if (selectionModel.length == 1) setSelectedRow(rows.filter((row) => selectionModel[0] == row.id));
	}, [selectionModel]);

	const onOpenEditModal = () => {
		if (selectionModel.length == 1) setEditModal(true);
		else {
			setSnackErr('Please select atleast one candidate');
			handleSnackClick();
		}
	};

	const onCloseEditModal = () => setEditModal(false);

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setGreenSnackOpen(false);
		setSuccessMsg(null);
	};

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);

		if (searchValue == '') {
			setRows(data);
		} else {
			const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
			const filteredRows = data.filter((row) => {
				return Object.keys(row).some((field) => {
					return searchRegex.test(row[field].toString());
				});
			});

			setRows(filteredRows);
		}
	};

	const NoRowsOverlay = () => {
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					textAlign: "center"
				}}
			>
				<img
					src={emptyRowImg}
					alt="no rows"
					style={{
						height: "350px",
						width: "auto"
					}}
				/>
				<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
			</div>
		)
	}


	return (
		<div className="productList">
			<div className="inner-div">
				<div className='top-icon-box'>
					{
						userRole === 'Admin' ? (
							<IconButton aria-label="edit" onClick={onOpenEditModal} id="applicantsFlow-selector-3">
								<img src={Edit} alt="" width={30} height={30} />
							</IconButton>
						) : ''
					}
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div>
			</div>
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
				<p className="modal-text">
					Do you want to Delete the candidate from Workflow
				</p>
				<div className="button-div">
					<Button className="modal-button" onClick={() => {
						onCloseDeleteModal()
						handleDelete(deleteCandId)
					}}>
						Ok
					</Button>
					<Button className="cancel-modal-button" onClick={() => {
						onCloseDeleteModal()
					}}>
						Cancel
					</Button>
				</div>
			</Modal>
			<div className="userListTitleContainer" style={{ padding: "20px 20px 0" }}>
				<div className="inner-div-details">
					<h2>All Applicants</h2>
					<p style={{ wordBreak: 'break-all' }}>{location.state.workflowName}</p>
				</div>
				<div className="search-box">
					<input type="search" placeholder="Search" onChange={(event) => requestSearch(event.target.value)} />
				</div>
				<div>
					<Button
						className="inner-div-btn"
						id="btn1"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 20px'
						}}
						size="small"
						variant="contained"
					>
						{CSVData ? <CSVLink filename="Data.csv" data={CSVData} target="_blank" style={{ color: "white", textDecoration: "none", fontSize: '16px', whiteSpace: 'nowrap' }} id="applicantsFlow-selector-2">Export <img src={Subtract} alt="" style={{ height: "18px", width: "18px", transform: `translate(3px, 4px)` }} /></CSVLink> : ''}
					</Button>
				</div>
			</div>
			<div style={{ minHeight: "500px", height: 'max-content', padding: '10px', background: '#fff', borderRadius: '25px', overflow: 'hidden' }}>
				<DataGrid
					sortingOrder={['desc', 'asc']}
					rows={!isOpen ? rows : [{
						"_id": "61789a40333ed5317facc044",
						"name": "yA",
						"email": [
							"y@s.com"
						],
						"id": "3",
						"code": "2a10Rz2E5CIIkAEVqZCG5U2bwqLadsZBbTxEhi7HftAb8WBXZXnui",
						"status": "Shortlist",
						"lastActive": "2021-10-27T00:25:06.399Z",
						"createdDate": "2021-10-27T00:16:00.341Z",
						"company": "61730080f94921c9089ea99f",
						"workflow": "6178704387c9e1c238ae4f58",
						"__v": 0,
						"problem": "You are talking to the Head of Talent Acquisition (TA) at a well-funded Series C startup in the e-commerce space. They have had great success raising funds, and now have aggressive targets to grow the team and expand the business. Over the last 12 months, the team hired 28 people. Their target for the next 12 months is 80. ::The Head of TA is concerned that without significantly revamping the way they approach recruiting, they will not come anywhere close to meeting the hiring targets, which would greatly hinder the business’ ability to grow and scale.::She is seeking your help to ramp up their ability to hire without affecting hiring quality, while keeping the size of the HR team small. In order to meet these targets, she has received a much more generous budget than before to spend on recruiting.",
						"productId": "ChatId4",
						"title": "HR Evaluative"
					}]}
					components={{ NoRowsOverlay }}
					getRowClassName={() =>
						`rowOnHover`
					}
					columns={columns}
					checkboxSelection={userRole === 'Admin'}
					componentsProps={{
						toolbar: {
							value: searchText,
							onChange: (event) => requestSearch(event.target.value),
							clearSearch: () => requestSearch(''),
						},
					}}
					onSelectionModelChange={(newSelectionModel) => {
						setSelectionModel(newSelectionModel);
					}}
					selectionModel={selectionModel}
					autoHeight
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[5, 10, 15]}
					className="dataGridScroll"
					loading={loading}
				/>
			</div>
			<Modal open={editModal} onClose={onCloseEditModal} center>
				<p className="modal-text">
					Edit candidate's name
				</p>
				{selectedRow.length == 1 ?
					(<>
						<div className="input-div-workflow">
							<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
							<input
								id="applicantsFlow-selector-4"
								type="text"
								ref={editNameRef}
								defaultValue={selectedRow[0].name}
								placeholder="Name"
								style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
								required
							/>
						</div>
					</>)
					: 'Loading'}
				<div className="button-div">
					<Button
						id="applicantsFlow-selector-5"
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							nameEdit(selectedRow[0])
						}}>
						<span style={{ fontSize: '16px' }}>Ok</span>
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseEditModal()
						}}>
						<span style={{ fontSize: '16px' }}>Cancel</span>
					</Button>
				</div>
			</Modal>
			<Modal open={showStatusModal} onClose={() => setShowStatusModal(false)} center>
				<div style={{ padding: '20px 40px' }}>
					<div style={{ fontSize: 22, fontWeight: '700', marginBottom: 20 }}>More details on status tags</div>
					<div><strong>Added:</strong> All candidates who have been added to the dashboard</div>
					<div style={{ marginTop: 10 }}><strong>Applied:</strong> All candidates who have applied on the job opening via HQ job opening link</div>
					<div style={{ marginTop: 10 }}><strong>Invited:</strong> All candidates who have been invited to attempt the HQ virtual interviewer</div>
					<div style={{ marginTop: 10 }}><strong>PS passed:</strong> All candidates who have qualified the pre-screening stage (meet the criteria defined in all pre-screening questions)</div>
					<div style={{ marginTop: 10 }}><strong>PS failed:</strong> Candidates who have been rejected from the pre-screening stage (do not qualify at least one of the pre-screening questions)</div>
					<div style={{ marginTop: 10 }}><strong>Opened (0% completed):</strong> Candidates who have just opened the link but have not started attempting the questions yet</div>
					<div style={{ marginTop: 10 }}><strong>x% completed:</strong> Candidates who are either attemting the assessment, or have prevoiously attempted but dropped midway (x = % of assessments that was attempted). Given these assessments were not submitted, they are not open for evaluation</div>
					<div style={{ marginTop: 10 }}><strong>Submitted:</strong> Candidates who have  successfully completed and submitted the assessment</div>
					<div style={{ marginTop: 10 }}><strong>Shortlisted:</strong> Candidates who have been shortlisted by the admin</div>
					<div style={{ marginTop: 10 }}><strong>Rejected:</strong> Candidates who have been rejected by the admin</div>
					<div style={{ marginTop: 10 }}><strong>Kept in View:</strong> Candidates who have been Kept in View by the admin. Decision of these candidates can be taken later in the ‘Reviewed’ tab’ tab</div>
				</div>
			</Modal >
			<Snackbar open={snackOpen} autoHideDuration={2500} onClose={handleSnackClose}>
				<Alert onClose={handleSnackClose} variant="filled" severity="error" sx={{ width: '100%' }}>
					{snackErr ? snackErr : ''}
				</Alert>
			</Snackbar>

			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose}>
				<Alert onClose={handleGreenSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					{successMsg ? successMsg : ''}
				</Alert>
			</Snackbar>
		</div >
	);
}

const mapStateToProps = createStructuredSelector({
	candidates: getCandidatesList(),
	rerender: getRerender(),
	userRole: getUserRole()
});

const mapDispatchToProps = (dispatch) => ({
	getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
	editCandidateName: (data) => dispatch(editCandidateNameRequest(data)),
	resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
	resetRerender: () => dispatch(resetRerenderRequest()),
	deleteCandidate: (data) => dispatch(deleteCandidateRequest(data))
});

Applicants.propTypes = {
	candidates: PropTypes.array,
	getCandidatesByType: PropTypes.func,
	rerender: PropTypes.number,
	editCandidateName: PropTypes.func,
	deleteCandidate: PropTypes.func,
	notification: PropTypes.object,
	resetRerender: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Applicants);