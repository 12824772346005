import React from 'react';

import loadingGif from '../../assets/loading.gif';

const Loader = () => {
	return (
		<div>
			<div align='center' style={{ margin: '1rem' }}>
				<img src={loadingGif} alt='' height='50px'></img>
			</div>
		</div>
	)
}

export default Loader;
