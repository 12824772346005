import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BookDemoModal from '../common/BookDemoModal';
import FeatureLockedModal from '../common/FeatureLockedModal';
import { checkCompanyFeatureAccess } from '../../utils/featureAccess';
import { getCompanyFeatures, getDashboardLogo, getSettings, getUserEmail, getUserName } from '../../selectors/app';

import DashboardActive from '../../assets/Icons/dashboard-active.png';
import FeatureLockedIcon from '../../assets/feature-locked.png';
import Logo from '../../assets/Logo.png';
import PreScreening from '../../assets/pre-screening.svg';
import PreScreeningActive from '../../assets/pre-screening-active.svg';
import TasksIcon from '../../assets/Icons/tasks.png';
import ContactsIcon from '../../assets/Icons/contacts.png';
import WidgetAlt from '../../assets/Icons/Widget_alt.png';
import {updateTrackerInfo as updateTrackerInfoRequest} from '../../actions/app';

import "./sidebar.css";

const Sidebar = (props) => {
	const { hidden = false, userName, userEmail, dashboardLogo, companyFeatures,updateTrackerInfo } = props;

	const location = useLocation();
	const history = useHistory();

	const [requestDemoModal, setRequestDemoModal] = useState(false);
	const [featureLockedModal, setFeatureLockedModal] = useState(false);

	/*useEffect (()=>{
		if(requestDemoModal){
			updateTrackerInfo({
						heading: "CLICK_BOOK_DEMO",
						rawRequest : JSON.stringify({}),
						detail : window.location.pathname
					})
		}
	},[requestDemoModal])*/

	const onOpenFeatureLocked = (info) => {
		updateTrackerInfo({
						heading: "CLICK_"+info.toUpperCase(),
						rawRequest : JSON.stringify({}),
						detail : info
					})
		setFeatureLockedModal(true);
	}

	const onClickPreScreening = () => {/*
		if (checkCompanyFeatureAccess(companyFeatures, 'preScreening') === 1) {
			setFeatureLockedModal(true);
		} else {
			history.push({
				pathname: '/pre-screening/dashboard',
				state: {
					sidebar: "preScreening"
				}
			})
		}*/
		onOpenFeatureLocked('prescreening')
	}

	const onClickAllApplicants = () => {
		onOpenFeatureLocked('all_applicants')
		/*if (checkCompanyFeatureAccess(companyFeatures, 'allApplicantsSidebar') === 1) {
			setFeatureLockedModal(true);
		} else {
			history.push({
				pathname: "/applicants",
				state: {
					workflowId: location.state.workflowId,
					workflowName: location.state.workflowName,
					sidebar: "applicants"
				}
			})
		}*/
	}

	const onClickReviewed = () => {
		onOpenFeatureLocked('reviewed')
		/*if (checkCompanyFeatureAccess(companyFeatures, 'reviewedSidebar') === 1) {
			setFeatureLockedModal(true);
		} else {
			history.push({
				pathname: "/finalized",
				state: {
					workflowId: location.state.workflowId,
					workflowName: location.state.workflowName,
					sidebar: "finalised"
				}
			})
		}*/
	}

	const onClickAnalytics = () => {
		onOpenFeatureLocked('analytics')
		/*if (checkCompanyFeatureAccess(companyFeatures, 'analyticsSidebar') === 1) {
			setFeatureLockedModal(true);
		} else {
			history.push({
				pathname: "/analytics",
				state: {
					workflowId: location.state.workflowId,
					workflowName: location.state.workflowName,
					sidebar: "analytics"
				}
			})
		}*/
	}

	return (
		<div style={{ width: "20vw", color: "black" }}>
			<div className="sidebar" style={{ display: 'flex', flexDirection: 'column' }}>
				<div className="sidebarWrapper" style={{ flex: 1 }}>
					<div className="logo-div">
						<img style={{ height: "auto", width: "11vw" }} src={dashboardLogo} alt="" />
					</div>
					<div className="user-div">
						<div className="user-details">
							<h4 style={{ padding: '0 8px', fontSize: '14px' }}>Welcome,<br /> {userName}</h4>
						</div>
					</div>
					<div className="sidebarMenu">
						<Link to={{ pathname: "/workflows", state: { sidebar: "workflows" } }} className="link">
							<Button><img src={DashboardActive} alt="" />&nbsp;Recruitment Drives</Button>
						</Link>
					</div>

					<div className={window.location.pathname === "/pre-screening/dashboard" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<div onClick={onClickPreScreening} className="link">
							<Button><img src={window.location.pathname === "/pre-screening/dashboard" ? PreScreeningActive : PreScreening} alt="" style={{ margin: "0 -2px -2px" }} />&nbsp;Pre-screening Stage</Button>
						</div>
						
								<div style={{ marginLeft: 15, cursor: 'pointer' }} onClick={()=>onOpenFeatureLocked('prescreening')}>
									<img style={{ width: 16 }} src={FeatureLockedIcon} alt='locked feature' />
								</div>
					</div>

					<div className={location.state.sidebar === "quickLook" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"} style={{ display: hidden ? "none" : "" }}>
						<Link to={{ pathname: "/workflows/" + location.state.workflowName, state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "quickLook" } }} className="link">
							<Button className='dashboard-fifth-step'><img src={TasksIcon} alt="" />&nbsp;Quick Overview</Button>
						</Link>
						<ul className="sidebarList">
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<div onClick={onClickAllApplicants} className="link" id="applicantsFlow-selector-1">
									<li className={location.state.sidebar === "applicants" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
										All Applicants
									</li>
								</div>
										<div style={{ marginLeft: 15, cursor: 'pointer' }} onClick={()=>onOpenFeatureLocked('all_applicants')}>
											<img style={{ width: 16 }} src={FeatureLockedIcon} alt='locked feature' />
										</div>
									
							</div>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<div onClick={onClickReviewed} className="link dashboard-sixth-step" id="reviewedFlow-selector-1">
									<li className={location.state.sidebar === "finalised" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
										Reviewed
									</li>
								</div>
								
										<div style={{ marginLeft: 15, cursor: 'pointer' }} onClick={()=>onOpenFeatureLocked('reviewed')}>
											<img style={{ width: 16 }} src={FeatureLockedIcon} alt='locked feature' />
										</div>
							</div>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<div onClick={onClickAnalytics} className="link dashboard-seventh-step" id="analytics-flow-selector-4">
									<li className={location.state.sidebar === "analytics" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
										Analytics
									</li>
								</div>
								
										<div style={{ marginLeft: 15, cursor: 'pointer' }} onClick={()=>onOpenFeatureLocked('analytics')}>
											<img style={{ width: 16 }} src={FeatureLockedIcon} alt='locked feature' />
										</div>
							</div>
							<Link to={{ pathname: "/settings", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "settings" } }} className="link dashboard-eighth-step" id="settingsFlow-selector-1">
								<li className={location.state.sidebar === "settings" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
									Settings & Drive Details
								</li>
							</Link>
						</ul>
					</div>
					<div className={window.location.pathname === "/new-applicants" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
						<Link className="link" to={{ pathname: "/new-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "invitations" } }} id="manageInvitation-selector-1">
							<Button className="dashboard-second-step"><img src={DashboardActive} alt="" />&nbsp;Manage Invitations</Button>
						</Link>
					</div>
					<div className={location.state.sidebar === "reviewApplicants" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
						<Link className="link" to={{ pathname: "/review-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "reviewApplicants" } }} id="reviewApplicantsFlow-selector-1">
							<Button className='dashboard-third-step'><img src={WidgetAlt} alt="" style={{ marginLeft: "-2px" }} />&nbsp;Review Applicants</Button>
						</Link>
					</div>
					<div className={window.location.pathname === "/team" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
						<Link to={{ pathname: "/team", state: { sidebar: "team" } }} style={{ textDecoration: "none" }}>
							<Button><img src={ContactsIcon} alt="" />&nbsp;Manage Team</Button>
						</Link>
					</div>
				</div>
				<div style={{ width: 150, margin: '10px auto', backgroundColor: '#479BD2', color: '#fff', borderRadius: 10, fontSize: 14, textAlign: 'center', padding: '7px 0', fontWeight: '700', cursor: 'pointer' }} onClick={() => setRequestDemoModal(true)}>Book Demo</div>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '15px 10px', borderTop: '1px solid #EBEFF2' }}>
					<div style={{ fontSize: 11, color: '#334D6E', fontWeight: '400', marginRight: 15 }}>All rights reserved ©</div>
					<div style={{ display: 'flex' }}>
						<img src={Logo} alt='' style={{ height: 14 }} />
					</div>
				</div>
			</div>
			<BookDemoModal
				requestDemoModal={requestDemoModal}
				setRequestDemoModal={setRequestDemoModal}
				userName={userName}
				userEmail={userEmail}
			/>
			<FeatureLockedModal
				modalOpen={featureLockedModal}
				onClose={() => setFeatureLockedModal(false)}
			/>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	userName: getUserName(),
	settings: getSettings(),
	dashboardLogo: getDashboardLogo(),
	userEmail: getUserEmail(),
	companyFeatures: getCompanyFeatures()
});

const mapDispatchToProps = (dispatch) => ({
	updateTrackerInfo: (data) => dispatch(updateTrackerInfoRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
