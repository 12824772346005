import { Button } from '@mui/material';
import React from 'react';
import { customAssessmentData } from '../newDriveData';

const AddCustomFields = (props) => {
    const { closeCustomAssessmentForm, driveRole, customFieldsData, setCustomFieldsData, setCustomAssessmentProblemStatement, customAssessmentProblemStatement } = props;

    const onChangeCustomField = (e, val) => {
        const newCustomFieldsData = { ...customFieldsData };

        if (e.target.value !== '') {
            newCustomFieldsData[val.label] = e.target.value;
        } else if (newCustomFieldsData[val.label]) {
            delete newCustomFieldsData[val.label];
        }

        setCustomFieldsData({ ...newCustomFieldsData });

        let customAssessmentProbStatement = customAssessmentData[driveRole].problemStatement;
        customAssessmentData[driveRole].customFields.forEach((cf) => {
            const regex = new RegExp(`{{${cf.key}}}`, "g");
            if (cf.label === val.label) {
                if (e.target.value !== '') {
                    customAssessmentProbStatement = customAssessmentProbStatement.replace(regex, `<b>${e.target.value}</b>`);
                } else {
                    customAssessmentProbStatement = customAssessmentProbStatement.replace(regex, `<b>[${cf.label}]</b>`);
                }
            } else if (customFieldsData[cf.label]) {
                customAssessmentProbStatement = customAssessmentProbStatement.replace(regex, `<b>${customFieldsData[cf.label]}</b>`);
            } else {
                customAssessmentProbStatement = customAssessmentProbStatement.replace(regex, `<b>[${cf.label}]</b>`);
            }
        });
        setCustomAssessmentProblemStatement(customAssessmentProbStatement);
    }

    return (
        <div className='create-custom-assessment'>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginBottom: 16 }}>
                <div className='back-btn' onClick={closeCustomAssessmentForm} style={{ marginTop: 2 }}>
                    <div></div>
                </div>
                <div style={{ borderBottom: '1px solid #c4c4c4', flex: 1, paddingBottom: 15 }}>
                    <div className="title" style={{ marginBottom: 0 }}>Create custom assessment</div>
                    <div style={{ fontSize: 14, color: '#B6B6B6' }}>Add custom fields to customize your assessment</div>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 4, marginRight: 25 }}>
                    <div style={{ fontWeight: 'bold' }}>Add custom fields :</div>
                    <div>
                        {
                            customAssessmentData[driveRole].customFields.map((cf) => (
                                <div key={cf.label}>
                                    <input type="text" value={customFieldsData[cf.label] ? customFieldsData[cf.label] : ''} placeholder={`[${cf.label}]`} onChange={(e) => onChangeCustomField(e, cf)} />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div style={{ flex: 6 }}>
                    <div style={{ fontWeight: 'bold' }}>Preview problem statement :</div>
                    <div style={{ padding: '10px 25px', borderRadius: 20, backgroundColor: '#f8f8f8', marginTop: 15, fontSize: 14, lineHeight: '1.7' }} dangerouslySetInnerHTML={{ __html: customAssessmentProblemStatement }} />
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button className='action' size="small" variant="contained" onClick={closeCustomAssessmentForm} style={{ padding: '10px 40px', borderRadius: 40 }}>Save</Button>
            </div>
        </div>
    )
}

export default AddCustomFields;
