import React, { useState } from "react";
import { Modal } from "react-responsive-modal";

import VerticalCarousel from "./chat_section/VerticalCarousel";

import img1 from "../../assets/exhibits/case2/1.jpg";
import img2 from "../../assets/exhibits/case2/2.jpg";
import user from '../../assets/proctoring-default-user.png';

import "../../styles/scrollbar.css";
import "../../styles/chatSidebar.scss";
import { useLocation } from "react-router-dom";

const Sidebar = (props) => {
	const { toSpeak, setToSpeak, exhibits, photoRef, videoRef, videoProctoring } = props;
	
	const location = useLocation();

	const [modal1, setModal1] = useState(false);

	const onOpenModal1 = () => setModal1(true);

	const onCloseModal1 = () => setModal1(false);

	return (
		<div id="chatSidebar">
			<div
				style={{
					height: "12vh",
					background: "#fff",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img alt="" src={ location.state.logo ? 'https://allhqclientpics.s3.ap-southeast-1.amazonaws.com/' + location.state.logo : 'https://allhqclientpics.s3.ap-southeast-1.amazonaws.com/website_videos/logo.png' } style={{ margin: "0", maxWidth: "12vw", maxHeight: "12vh" }} />
			</div>
			<div
				style={{
					height: "70vh",
					background: "#363740",
					color: "#fff",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<div
					className="bot-second-step"
					id="sample-tour-video"
					style={{
						padding: "5px",
						marginBottom: "10px"
					}}
				>
					<img src={user} alt="sample tour video" style={{ maxWidth: "100%" }} />
				</div>
				{
					videoProctoring ? (
						<div
							id="user-video-flex"
							style={{
								width: "13vw",
								height: "20vh",
								padding: "0 0 15px",
								margin: "1vh 0 0",
								display: "none",
								alignItems: "center",
								justifyContent: "center",
								borderBottom: "1px solid white"
							}}
						>
							<video
								ref={videoRef}
								style={{
									maxHeight: "20vh",
									maxWidth: "13vw",
									borderRadius: "25px",
									background: "#000"
								}}
							/>
						</div>
					) : ''
				}
				<canvas
					ref={photoRef}
					id="canvas"
					width="13vw"
					height="20vh"
					style={{ display: "none" }}
				></canvas>
				<div className="bot-third-step" id="sample-tour-exhibits" style={{ padding: "0 5px" }}>
					<img src={img1} alt="exhibit" style={{ maxWidth: '100%', margin: '5px 0' }} />
					<img src={img2} alt="exhibit" style={{ maxWidth: '100%', margin: '5px 0' }} />
				</div>
				{ exhibits.length > 0 ? <VerticalCarousel exhibits={exhibits.slice().reverse()} /> : "" }
				{/* {
					exhibits.length > 0 ? (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								overflowY: "auto",
								overflowX: "hidden",
								margin: "0 0 2vh",
								padding: "10px 0px 10px 0px",
								borderBottom: "1px solid #fff",
								width: "13vw",
								maxHeight: "30vh"
							}}
							className="scrollbar"
						>
							{exhibits.map((exhibit) => {
								if (exhibit !== "na")
									return (
										<Zoom>
											<img src={exhibit} alt="exhibit" style={{ maxWidth: '100%', margin: '10px 0' }} />
										</Zoom>
									);
								else return null;
							})}
						</div>
					) : ''
				} */}
			</div>
			<div
				style={{
					height: "18vh",
					background: "#525252",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					fontSize: "14px",
				}}
			>
				<div
					className="bot-sixth-step"
					style={{
						display: "flex",
						margin: "0 0 2vh",
						flexDirection: "row",
						alignItems: "center",
						color: "#fff",
						width: "7vw"
					}}
					onClick={() => {
						setToSpeak(!toSpeak);
					}}
				>
					<img
						style={{
							height: "4.5vh",
							maxWidth: "4.5vh",
							marginRight: "0.5vw"
						}}
						src={toSpeak ? "./sound.png" : "./sound-off.png"}
						alt=""
					/>
					<p style={{ marginRight: toSpeak ? "1vw" : "0" }}>{toSpeak ? " Mute" : " Unmute"}</p>
				</div>

				<div
					className="bot-seventh-step"
					onClick={onOpenModal1}
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						color: "#fff",
						cursor: "pointer",
						width: "7vw"
					}}
				>
					<img
						style={{
							height: "4.5vh",
							maxWidth: "4.5vh",
							marginRight: "0.5vw"
						}}
						src="./support.png"
						alt=""
					/>
					<span>Support</span>
				</div>
				<Modal open={modal1} onClose={onCloseModal1} center styles={{ modal: { borderRadius: "50px", padding: "40px" } }}>
					<div style={{ margin: "2vh 2vw" }}>
						Facing any difficulties? Email us at {" "}
						<a href="mailto::support@hirequotient.com">support@hirequotient.com</a>
					</div>
				</Modal>
			</div>
		</div>
	);
}

export default Sidebar;
