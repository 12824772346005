import React from 'react'
import Dashboard from '../components/dashboard/Dashboard'
import '../App.css'

const DashboardPage = () => {
  return (
    <div className='App'>
      <Dashboard />
    </div>
  )
}

export default DashboardPage
