import React, { useState } from 'react';
import Modal from 'react-responsive-modal';

import tourstartimg from '../../assets/tourstartimg.gif';
import OnboardingChecklistImg from '../../assets/onboarding-checklist.png';

const WalkthroughWelcome = (props) => {
    const { modalOpen, onClose, updateCurrentWalkthroughStep } = props;

    const [currentTab, setCurrentTab] = useState(0);

    const onClickSkip = () => {
        onClose();
        localStorage.setItem('walkthrough', false);
    }

    const onClickBegin = () => {
        onClose();
        updateCurrentWalkthroughStep(1);
        localStorage.setItem('walkthrough', false);
    }

    return (
        <Modal open={modalOpen} onClose={onClose} center>
            <div style={{ padding: 20, width: 750 }}>
                {
                    currentTab === 0 ? (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ color: '#686970' }}>
                                    <div style={{ fontSize: 28, fontWeight: '700' }}>Welcome to HireQuotient Free Trial!</div>
                                    <div style={{ fontSize: 18, fontWeight: '400', marginTop: 40, lineHeight: '1.7' }}>Here, we will help you to understand the product better. You have 14 days to explore HireQuotient to its fullest.</div>
                                    <div style={{ fontSize: 18, fontWeight: '400', marginTop: 40, lineHeight: '1.7' }}>For any queries, reach out to us at support@hirequotient.com.</div>
                                </div>
                                <div>
                                    <img style={{ width: 350 }} src={tourstartimg} alt='Tour welcome' />
                                </div>
                            </div>
                            <div style={{ borderRadius: 30, color: '#fff', fontSize: 20, fontWeight: '600', padding: '10px 50px', background: 'linear-gradient(99.98deg, #215D7F 18.73%, #047C93 81.12%)', cursor: 'pointer' }} onClick={() => setCurrentTab(1)}>Next</div>
                            <div style={{ textDecoration: 'underline', fontSize: 12, color: '#686970', lineHeight: '2', cursor: 'pointer' }} onClick={onClickSkip}>Skip</div>
                        </div>
                    ) : ''
                }
                {
                    currentTab === 1 ? (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ color: '#686970' }}>
                                    <div style={{ fontSize: 28, fontWeight: '700' }}>Here's your onboarding checklist:</div>
                                    <div style={{ display: 'flex', marginTop: 50 }}>
                                        <div style={{ marginRight: 20, display: 'flex' }}>
                                            <img style={{ width: 32, height: 32 }} src={OnboardingChecklistImg} alt='onboarding checklist' />
                                        </div>
                                        <div>Create your own custom assessment</div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 15 }}>
                                        <div style={{ marginRight: 20, display: 'flex' }}>
                                            <img style={{ width: 32, height: 32 }} src={OnboardingChecklistImg} alt='onboarding checklist' />
                                        </div>
                                        <div>Preview your assessment</div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 15 }}>
                                        <div style={{ marginRight: 20, display: 'flex' }}>
                                            <img style={{ width: 32, height: 32 }} src={OnboardingChecklistImg} alt='onboarding checklist' />
                                        </div>
                                        <div>View the performance report, transcript and more!</div>
                                    </div>
                                    <div style={{ marginTop: 40 }}>Click below to start your journey with HireQuotient!</div>
                                </div>
                                <div>
                                    <img style={{ width: 350 }} src={tourstartimg} alt='Tour welcome' />
                                </div>
                            </div>
                            <div style={{ borderRadius: 30, color: '#fff', fontSize: 20, fontWeight: '600', padding: '10px 50px', background: 'linear-gradient(99.98deg, #215D7F 18.73%, #047C93 81.12%)', cursor: 'pointer', marginTop: 20 }} onClick={onClickBegin}>Let's begin</div>
                            <div style={{ textDecoration: 'underline', fontSize: 12, color: '#686970', lineHeight: '2', cursor: 'pointer' }} onClick={onClickSkip}>Skip</div>
                        </div>
                    ) : ''
                }
            </div>
        </Modal>
    )
}

export default WalkthroughWelcome;