import React, { useMemo,useState,useCallback,useEffect, useRef } from 'react'
import { Editor } from "react-draft-wysiwyg";
import {convertToRaw,EditorState,ContentState} from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {useDropzone} from 'react-dropzone'
import { Button } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from 'react-responsive-modal';
import '../../styles/react-draft-wysiwyg.css';
import axios from '../../axios';
import AddIcon from '@mui/icons-material/Add';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export default function Upload(props) {
  // console.log(props);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const onOpenModal2 = () => setOpen(true);
  const onCloseModal2 = () => setOpen(false);
  let location = useLocation()
  let history = useHistory()
  const [img,setImg]=useState('')
  const [file,setFile]=useState('')//image
  const [subject,setsubject]=useState()//subject
  const [header,setheader]=useState()//header
  const [buttonText,setbuttonText]=useState()//buttonText
  const openToast = () => {
    setOpen(true);
  }
  let [editorState, setEditorState] = useState(EditorState.createEmpty())//htmlToDraft(html)
  const spanRef = useRef(null);

  useEffect(() => {
    if (spanRef.current) {
        spanRef.current.innerHTML = draftToHtml(convertToRaw(editorState.getCurrentContent())).replaceAll('<p></p>','<br/>').replaceAll('  ','&nbsp;&nbsp;')
    }
    // console.log(spanRef.current.innerHTML)
    
}, [editorState]);


  function toFormData(o) {
    // console.log(o)
  let x= Object.entries(o).reduce((d,e) => (d.append(...e),d), new FormData())
  // console.log(x)
}
/*`<div style="width:100%;background:#bbb">
<div style="width:500px;margin:auto;background:#fff;padding:20px;">
  <div style="width: 500px;text-align: center;">
    <img src="${file}" style="max-width: 80%;"/>
  </div>
<p style="text-align:center"><span style="font-size:24px"><br/><strong>${header}</strong></span></p>
<p><span style="font-size:16px">Hello,<br/><br/>${draftToHtml(convertToRaw(editorState.getCurrentContent()))}</span></p>
<br/><br/>
<p><span style="font-size:12px">If the button doesn't work, <a href="{{link}}" target="_blank">click here</a><br/><br/></span></p>
<div style="text-align: center;margin-top:15px;">
 <a href="{{link}}" target="_blank" style="padding:25px 75px;background:rgb(255, 160, 27);margin:auto;color:white;border:none">${buttonText}</a>
</div>
</div>
</div>`*/
  const updateEmail = () => {
    let data = {
      render:`<div style="width:100%;background:#bbb">
      <div style="width:500px;margin:auto;background:#fff;padding:20px;">
        <div style="width: 500px;text-align: center;">
          <img src="${file}" style="max-width: 80%;"/>
        </div>
      <p style="text-align:center"><span style="font-size:24px"><br/><strong>${header}</strong></span></p>
      <p><span style="font-size:16px">${draftToHtml(convertToRaw(editorState.getCurrentContent()))}</span></p>
      <br/><br/>
      <div style="text-align: center;margin-top:15px;;margin-bottom:15px;">
      <a href="{{link}}" target="_blank" style="padding:25px 75px;background:rgb(255, 160, 27);margin:auto;color:white;border:none">${buttonText}</a>
      </div>
      <p><span style="font-size:12px"><br/><br/>If the button doesn't work, use {{link}}</span></p>
      </div>
      </div>`,
    body:draftToHtml(convertToRaw(editorState.getCurrentContent())),
    image:file,
    subject:subject,
    header:header,
    button:buttonText};

  var formData = new FormData();
  formData.append('workflowId', location.state.workflowId);
  formData.append('key', props.type);
  formData.append('image', file);
  formData.append('body', data["body"]);
  formData.append('subject', subject);
  formData.append('header', header);
  formData.append('button', buttonText);
  formData.append('render', data["render"]);
    axios.post("/workflow/setemail", formData,{'Content-Type': 'multipart/form-data'})
      .then((res) => {
        // console.log(res)
        openToast()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  /*useEffect(()=>{
    setFile(new  Buffer(img).toString('Base64'))
  },[img])*/
  useEffect(()=>{
    // console.log(location.state)
    axios.post('/workflow/getemail',{workflowId:location.state.workflowId,key:props.type}).then((res)=>{
      // console.log(res.data)
      setImg(res.data.image)
      setFile(res.data.image)
      setsubject(res.data.subject)
      setheader(res.data.header)
      setbuttonText(res.data.button)
      const blocksFromHtml = htmlToDraft(res.data.body);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(contentState));
      //setbody(res.data.body)
    }).catch((err)=>console.log(err))
  },[])
function getBase64(file1) {
  // console.log(file1)
  setImg(file1)
   var reader = new FileReader();
   var reader2=new FileReader();
   reader.readAsArrayBuffer(file1);
   reader2.readAsDataURL(file1);
   reader.onload = function () {
    //  setImg(reader.result);
   };
   reader.onerror = function (error) {
     console.log('Error: ', error);
   };
   reader2.onload = function () {
     setFile(reader2.result);
   };
   reader2.onerror = function (error) {
     console.log('Error: ', error);
   };
}
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    const files = acceptedFiles.find(f => f)
    // console.log(files)
    setFile(files)
    getBase64(files)
  }, [])
  const {getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject} = useDropzone({accept: 'image/*',onDrop})

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);
  function changeSubject(e){
    setsubject(e.target.value)
  }
  function changeImg(data){
    setImg(data)
    /*let x = Buffer.from(data).toString('Base64')
    setFile(x)*/

  }
 function changeHeader(e){
    setheader(e.target.value)
  }
  function changeButtonText(e){
     setbuttonText(e.target.value)
   }


    return (
      
      <div>
        <Snackbar open={open2} autoHideDuration={3000} anchorOrigin={{vertical:"bottom", horizontal:"center"}} onClose={()=>setOpen(false)}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Email Template saved successfully
        </Alert>
        </Snackbar>
        <div style={{display:"flex"}}>
          <div style={{height: "18vh", width: "13vw", backgroundColor: "lightpink", margin: "8px", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", textAlign: "center"}}>
            <h1>{props.typeOfEmail}</h1>
            <Button variant='outline' onClick={onOpenModal}>Open</Button>
          </div>
          <Modal open={open} onClose={onCloseModal} classNames={{modal: 'customModal'}} center>
            <div style={{display: "flex"}}>
            <div style={{background:"#fff", padding:"20px", borderRadius: "25px 0px 0px 25px"}}>
              <div>{props.typeOfEmail}</div>
              <div style={{marginBottom: "10px"}}>
                {/* Change logo<br/> */}
                <div {...getRootProps(style)} style={baseStyle}>
                <img src={file} style={{maxWidth: "400px", maxHeight:"150px"}}/>
                  <input {...getInputProps()} />
                  {
                    isDragActive ?
                      <p>Drop the files here ...</p> :
                      <p>Change Logo <AddIcon style={{width: "18px", height: "18px", top: "4px", position: "relative"}}/></p>
                  }
                </div>
              </div>
            {/* <p>Change Subject</p> */}
            <input type="text" onChange={changeSubject} value={subject} placeholder="Subject" style={{width: "90%", marginLeft:"2%", padding:"8px", marginBottom: "20px", boxShadow: "0px 1px 1px #EDEFF1", border: "none"}} /><br/>
            <input type="text" onChange={changeHeader} value={header} placeholder="Message header"  style={{width: "90%", marginLeft:"2%", padding:"8px", marginBottom:"20px", boxShadow: "0px 1px 1px #EDEFF1", border: "none"}} />
            <input type="text" onChange={changeButtonText} value={buttonText} placeholder="Button Name"  style={{width: "90%", marginLeft:"2%", padding:"8px", marginBottom:"20px", boxShadow: "0px 1px 1px #EDEFF1", border: "none"}} />
            <div style={{ border: "1px solid #EDEFF1", padding: '2px', minHeight: '100px',width: '500px' }}>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbar={{
                options: ["inline","link"],
                inline: {
                  inDropdown: false,
                  options: ['bold', 'italic', 'underline'],
                }
              }}
              />
              </div>
              <div style={{display:"flex", justifyContent:"center"}}>
                <Button style={{color:"#fff", background:"orange", margin:"10px 20px", borderRadius:"20px"}} onClick={()=>{
                  updateEmail();
                  setOpen2();
                }}>Save</Button>
              </div>
        </div>
          <div style={{padding:"40px 0 20px 40px", backgroundColor: "#fff"}}>
            <h2 style={{marginBottom: "20px"}}>Email preview</h2>
            <div id='preview' style={{ display:"flex", flexDirection:"column", alignItems:"center", margin:"50px 0 0 40px", width:"500px", background:"#fff", padding:"20px"}}>
              <img src={file}  style={{maxWidth: "100%", maxHeight:"150px"}} />
              <h2>{header}</h2><br/>
              <span ref={spanRef} style={{overflow:"wrap"}} />
              {buttonText===""?"":<Button style={{background:"orange", color:"#fff"}}>{buttonText}</Button>}
            </div>
          </div>
            </div>    
          </Modal>
      </div>
      </div>
    );
}
//module.exports = Upload