
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-responsive-modal';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Validator from "validator";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@material-ui/core';
import { Select, Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/styles';
import { useHistory } from 'react-router-dom';

import AccountMenu from "../accountMenu/AccountMenu";
import axios from '../../axios';
import NotificationBell from "../notificationBell/notificationBell";
import { validateEmail, validateSpoofText } from '../../utils/validation/validateUtils';

import {
	updateTrackerInfo as updateTrackerInfoRequest
} from '../../actions/app';

import Edit from '../../assets/Icons/Edit.png';
import FeatureLockedIcon from '../../assets/feature-locked.png';
import FeatureLockedWhiteIcon from '../../assets/feature-locked-white.png';
import Trash from '../../assets/Icons/Trash.png';

import "./Team.css";
import ChangePassword from '../../components/common/ChangePassword';
import { getCompanyFeatures, getUserName, getUserRole } from '../../selectors/app';
import { redirectUnauthorisedUser } from '../../helper/helperFunction';
import { checkCompanyFeatureAccess } from '../../utils/featureAccess';
import FeatureLockedModal from '../../components/common/FeatureLockedModal';

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: 12,
		border: '1px solid #dadde9',
	},
}));

const Team = (props) => {
	const { notification, userRole, userName, companyFeatures, updateTrackerInfo } = props;

	const history = useHistory();

	const [open, setOpen] = useState(false);
	const [role2, setRole2] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [selectionModel, setSelectionModel] = useState([]);
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackErr, setSnackErr] = useState(null);
	const [selectedRow, setSelectedRow] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [successMsg, setSuccessMsg] = useState(null);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [change, setChange] = useState(0);
	const [changeName] = useState(false);
	const [rows, updateRows] = useState([]);
	const [changePwdOpen, setChangePwdOpen] = useState(false);
	const [featureLockedModal, setFeatureLockedModal] = useState(false);

	const editNameRef = useRef(null);

	const openToast = () => {
		setOpen(true);
	}

	const roles = [
		{ value: 'Admin', label: "Admin" },
		{ value: "Bias-free", label: "Bias-free" },
		{ value: "Member", label: "Member" },
	];

	const handleSnackClick = () => {
		setSnackOpen(true);
	};

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	useEffect(() => {
		if (selectionModel.length == 0) setSelectedRow([]);

		if (selectionModel.length == 1) setSelectedRow(rows.filter((row) => selectionModel[0] == row.id));
		else {
			let temp = [];

			for (let i = 0; i < selectionModel.length; i++) {
				temp.push(...rows.filter((row) => selectionModel[i] == row.id));
			}

			setSelectedRow(temp);
		}
	}, [selectionModel]);

	const onOpenEditModal = () => {
		if (selectionModel.length == 1) setEditModal(true);
		else {
			setSnackErr('Please select atleast one team member');
			handleSnackClick();
		}
	};

	const onCloseEditModal = () => setEditModal(false);

	const handleGreenSnackClick = () => {
		setGreenSnackOpen(true);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setGreenSnackOpen(false);
		setSuccessMsg(null);
	};

	const nameEdit = function (row) {
		if (!Validator.isEmpty(editNameRef.current.value) && validateSpoofText(editNameRef.current.value)) {
			axios.post(("/update-company-user/"), { ...row, name: editNameRef.current.value })
				.then(() => {
					let index = -1;
					rows.map((val, i) => {
						if (val.id == selectionModel[0]) index = i;
					})
					rows[index].name = editNameRef.current.value;
					selectedRow[0].name = editNameRef.current.value;
					editNameRef.current.value = '';

					onCloseEditModal();
					handleGreenSnackClick();
					setSuccessMsg('Updated Name Successfully');
				})
				.catch(err => {
					console.log(err);
					redirectUnauthorisedUser(err, history);
					handleSnackClick();
					setSnackErr('Could not update name at the moment');
				})
		} else {
			handleSnackClick();
			setSnackErr('Enter valid name');
		}
	}

	const roleEdit = function (row, e) {
		axios.post(("/update-company-user/"), { ...row, role: e.target.value })
			.then(() => {
				let index = -1;
				rows.map((val, i) => {
					if (val.id == row.id) index = i;
				})
				rows[index].role = e.target.value;

				onCloseEditModal();
				handleGreenSnackClick();
				setSuccessMsg('Updated Role Successfully');
			})
			.catch(err => {
				console.log(err);
				handleSnackClick();
				setSnackErr('Could not update role at the moment');
			})
	}

	const onOpenDeleteModal = () => {
		if (selectionModel.length >= 1) setDeleteModal(true);
		else {
			setSnackErr('Please select atleast one team member');
			handleSnackClick();
		}
	};

	const onCloseDeleteModal = () => {
		setDeleteModal(false);
	};

	useEffect(() => {
		axios.get("/company-user").then((res) => {
			filterResponse(res.data);
		}).catch((err) => {
			console.log(err)
			redirectUnauthorisedUser(err, history);
		})
	}, [change])

	const sendData = () => {
		const uri = "/company/member/";
		const emailValid = validateEmail(email);

		if (Validator.isEmpty(name) || !validateSpoofText(name)) {
			setSnackErr("Please Enter Valid Name");
			handleSnackClick();
		} else if (!emailValid.isValid) {
			setSnackErr("Invalid Email");
			handleSnackClick();
		} else if (Validator.isEmpty(role2)) {
			setSnackErr("Please Enter Role");
			handleSnackClick();
		} else if (!checkCompanyFeatureAccess(companyFeatures, 'memberCount', rows.length)) {
			setSnackErr("You cannot invite more than 3 members in free trial");
			handleSnackClick();
		} else {
			const getData = {
				memberName: name,
				memberEmail: email,
				memberRole: role2,
				companyName: 'Dummyy'
			};

			axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
				.then(() => {
					setChange(change + 1);
					setEmail('');
					setName('');
					setRole2('');
					setSuccessMsg("Added new member successfully")
					handleGreenSnackClick();
					updateTrackerInfo({
						heading: "ADD_USER",
						rawRequest : JSON.stringify(getData),
						detail : email
					})
				})
				.catch((err) => {
					console.log(err)
					redirectUnauthorisedUser(err, history);
					setSnackErr("Not able to add member at the moment");
					handleSnackClick();
				});
		}
	}

	const deleteData = (rowSelected) => {
		const IDs = [];
		rowSelected.map((val) => {
			IDs.push(val._id);
		})

		axios.post('/delete-company-user', { id: IDs })
			.then(res => {
				setSuccessMsg(`Deleted ${res.data.deletedCount} member successfully`);
				handleGreenSnackClick();
				setChange(change + 1);
				updateTrackerInfo({
						heading: "DELETE_USER",
						rawRequest : JSON.stringify({ id: IDs }),
						detail : IDs.join(',')
					})
			})
			.catch(err => {
				console.log(err);
				redirectUnauthorisedUser(err, history);
				setSnackErr('Could not delete member at the moment');
				handleSnackClick();
			});
	};

	const column = [
		{
			field: "id",
			headerName: "S. No",
			flex: 1,
			minWidth: 120,
			headerAlign: 'center',
			align: 'center',
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ marginRight: "5ch", width: "100%" }}>
						{params.row.id}
					</div>
				)
			}
		},
		{
			field: "name",
			headerName: "Name",
			flex: 2,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: "16px", fontWeight: "400" }}>
						{params.row.name}
					</div>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			flex: 2,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: "16px", fontWeight: "400" }}>
						{params.row.email}
					</div>
				);
			},
		},
		{
			field: "role",
			headerName: "Role",
			flex: 1,
			headerAlign: "center",
			align: 'center',
			renderCell: (params) => {
				console.log(userRole);
				return (
					<div>
						<select name="options" id="" style={{ padding: "5px 20px", "background": "#FFFFFF", "boxShadow": "1px 1px 1px rgba(0, 0, 0, 0.25)", "borderRadius": "17px", "fontFamily": "Mulish", "fontStyle": "normal", "fontSize": "15px", "lineHeight": "20px", "color": "#C6C6C6", outline: "none", border: "none" }} onChange={(e) => roleEdit(params.row, e)} disabled={userRole !== 'Admin'} value={params.row.role}>
							<>
								<option value="Admin" selected>Admin</option>
								<option value="Member">Member</option>
								{/* <option value="Bias-free">Bias-free</option> */}
							</>
						</select>
					</div>
				)
			}
		}
	]

	if (userRole === 'Admin') {
		column.push({
			field: "action",
			headerName: "Action",
			headerAlign: "center",
			align: 'center',
			flex: 2,
			renderCell: (params) => {
				return (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					{/* <Button
						className="reinvite-btn"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							marginRight: '10px',
							cursor: 'pointer',
							display: 'flex',
							alignItems: 'center'
						}}
						size="small"
						variant="contained"
						onClick={() => onOpenChangePwd(params.row)}
					>
						<span style={{ fontSize: '14px', color: '#fff' }}>Reset Password</span>
						{
							checkCompanyFeatureAccess(companyFeatures, 'resetPassword') === 1 ? (
								<div style={{ marginLeft: 15 }}>
									<img style={{ width: 16 }} src={FeatureLockedIcon} alt='locked feature' />
								</div>
							) : ''
						}
					</Button> */}
					<Button
						className="reinvite-btn"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							cursor: 'pointer'
						}}
						size="small"
						variant="contained"
						onClick={() => { setSelectedRow([params.row]); setDeleteModal(true); }}
					>
						<span style={{ fontSize: '14px', color: '#fff' }}>Remove</span>
					</Button>
				</div>)
			}
		})
	}

	const filterResponse = (filterData) => {
		let temp = [];

		for (let i = 0; i < filterData.length; i++) {
			const data = {
				id: i + 1,
				name: filterData[i].name,
				email: filterData[i].email,
				role: filterData[i].role,
				_id: filterData[i]._id
			}
			temp.push(data);
		}

		updateRows(temp);
	}

	const onCloseChangePwd = () => {
		setChangePwdOpen(false);
	}

	const onOpenChangePwd = (val) => {
		// if (checkCompanyFeatureAccess(companyFeatures, 'resetPassword') === 1) {
		// 	setFeatureLockedModal(true);
		// } else {
			setSelectedRow([val]);
			setChangePwdOpen(true);
		// }
	}

	return (
		<div className="productList" style={{ overflowY: 'auto' }}>
			<Snackbar open={open} autoHideDuration={3000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} onClose={() => setOpen(false)}>
				<Alert severity="info" sx={{ width: '100%' }}>
					You need to be an Admin to perform this Action
				</Alert>
			</Snackbar>
			<ChangePassword
				open={changePwdOpen}
				onClose={onCloseChangePwd}
				handleGreenSnackClick={handleGreenSnackClick}
				setSuccessMsg={setSuccessMsg}
			/>
			<div>
				<div className="inner-div" style={{ marginBottom: "10px" }}>
					<div className='top-icon-box'>
						{
							userRole === 'Admin' ? (
								<IconButton aria-label="edit" onClick={onOpenEditModal}>
									<img src={Edit} alt="" width={30} height={30} />
								</IconButton>
							) : ''
						}
						{
							userRole === 'Admin' ? (
								<IconButton aria-label="delete" onClick={onOpenDeleteModal}>
									<img src={Trash} alt="" width={30} height={30} />
								</IconButton>
							) : ''
						}
						<NotificationBell notification={notification} />
						<AccountMenu />
					</div>
				</div>
				<div style={{ padding: '20px 20px 0' }}>
					<div className="inner-div-details" style={{ marginBottom: '10px' }}>
						<h2>{
							changeName ?
								(
									<div className="workflow-name" style={{ fontSize: 18, fontWeight: "600" }}>{userRole}</div>
								) :
								(
									<div className="workflow-name" style={{ fontSize: 18, fontWeight: "600" }}>
										{userName}
									</div>
								)
						}</h2>
						<p>{userRole}</p>
					</div>
					<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
						<p className="modal-text">
							Do you want to remove this member ?
						</p>
						<div className="button-div">
							{selectedRow.length != 0 ?
								(<Button
									className="modal-button"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 12px',
										fontSize: '16px',
										margin: '0 10px'
									}}
									size="small"
									variant="contained"
									onClick={() => {
										onCloseDeleteModal()
										deleteData(selectedRow)
									}}>
									Yes
								</Button>) : 'Loading'}
							<Button
								className="cancel-modal-button"
								style={{
									background: '#479BD2',
									fontWeight: 'bold',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									borderRadius: '25px',
									padding: '5px 12px',
									fontSize: '16px',
									margin: '0 10px'
								}}
								size="small"
								variant="contained"
								onClick={() => {
									onCloseDeleteModal()
								}}>
								No
							</Button>
						</div>
					</Modal>
					<div style={{ height: 'max-content', padding: '10px', background: '#fff', borderRadius: '25px', overflow: 'hidden' }}>
						{
							userRole === "Admin" ?
								<DataGrid
									className="dataGridScroll"
									sx={{
										borderRadius: "25px"
									}}
									rows={rows}
									columns={column}
									onSelectionModelChange={(newSelectionModel) => {
										setSelectionModel(newSelectionModel);
									}}
									selectionModel={selectionModel}
									disableSelectionOnClick
									checkboxSelection
									pageSize={15}
									rowsPerPageOptions={[5, 10, 15]}
									autoHeight
									style={{ borderRadius: "25px" }}
								/>
								: <DataGrid
									className="dataGridScroll"
									sx={{
										borderRadius: "25px"
									}}
									rows={rows}
									columns={column}
									pageSize={5}
									rowsPerPageOptions={[5]}
									autoHeight
									style={{ borderRadius: "25px" }}
								/>
						}
					</div>
				</div>
			</div>
			{
				userRole === "Admin" ?
					<div style={{ marginTop: "20px", padding: '0 0 0 20px' }}>
						<div className="heading-team">
							<div className="workflow-name" style={{ fontWeight: "600", marginBottom: "10px", fontSize: "20px" }}>
								Invite Members
							</div>
						</div>
						<div style={{ "background": "#FFFFFF", "boxShadow": "4px 8px 18px rgba(0, 0, 0, 0.25)", "borderRadius": "35px", padding: "30px", marginBottom: "20px" }}>
							<div style={{ display: "flex" }}>
								<div style={{ width: "22vw", margin: '0 0 10px 10px', fontWeight: '600', fontFamily: "Poppins", color: "#334D6E" }}>
									Name
								</div>
								<div style={{ width: "22vw", margin: '0 0 10px 10px', fontWeight: '600', fontFamily: "Poppins", color: "#334D6E" }}>
									Email
								</div>
								<div style={{ width: "22vw", margin: '0 0 10px 10px', fontWeight: '600', fontFamily: "Poppins", color: "#334D6E" }}>
									Role
								</div>
							</div>
							<div style={{ display: "flex", height: "35px", paddingTop: "3px", fontSize: "16px" }}>
								<input style={{ "background": "#FFFFFF", "boxShadow": "1px 1px 4px rgba(0, 0, 0, 0.25)", "borderRadius": "17px", border: "none", width: "15vw", padding: "0px 2vw", textDecoration: "none" }} value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" />
								<input style={{ "background": "#FFFFFF", "boxShadow": "1px 1px 4px rgba(0, 0, 0, 0.25)", "borderRadius": "17px", border: "none", width: "15vw", padding: "0px 2vw", textDecoration: "none", marginLeft: "3vw" }} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email Address" />
								<div style={{ display: 'flex', marginLeft: "2vw", width: "20vw" }}>
									<div>
										<Select
											className='role-select'
											value={role2}
											onChange={(e) => {
												if (e.target.value === 'Bias-free') {
													if (checkCompanyFeatureAccess(companyFeatures, 'inviteBiasFree') === 1) {
														setFeatureLockedModal(true);
													} else {
														setRole2(e.target.value)
													}
												} else {
													setRole2(e.target.value)
												}
											}}
											style={{ "background": "#FFFFFF", "boxShadow": "1px 1px 4px rgba(0, 0, 0, 0.25)", "borderRadius": 17, marginLeft: "1vw", border: "none", width: "15vw", padding: "0px 25px", textDecoration: "none", height: 35 }}
										>
											{
												roles.map((role) => (
													<MenuItem value={role.value}>
														<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
															<div>{role.label}</div>
															{
																checkCompanyFeatureAccess(companyFeatures, 'inviteBiasFree') === 1 && role.value === 'Bias-free' ? (
																	<div style={{ marginLeft: 10 }} onClick={() => setFeatureLockedModal(true)}>
																		<img style={{ width: 16 }} src={FeatureLockedIcon} alt='locked feature' />
																	</div>
																) : ''
															}
														</div>
													</MenuItem>
												))
											}
										</Select>
									</div>
									{/* <div style={{ marginLeft: 15, display: 'flex', cursor: 'pointer' }}>
										<HtmlTooltip
											title={
												<React.Fragment>
													<div>Admin</div>
													<div>Member</div>
													<div>Bias-Free</div>
												</React.Fragment>
											}
										>
											<img src={InfoIcon} alt='' />
										</HtmlTooltip>
									</div> */}
								</div>
								<Button
									className="reinvite-btn"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 12px',
									}}
									size="small"
									variant="contained"
									onClick={() => { userRole === "Admin" ? sendData() : openToast() }}
								>
									<span style={{ fontSize: '16px', color: '#fff', padding: '0 10px' }}>Invite</span>
								</Button>
							</div>
						</div>
					</div> : <></>
			}
			<Modal open={editModal} onClose={onCloseEditModal} center>
				<p className="modal-text">
					Edit member's name
				</p>
				{selectedRow.length == 1 ?
					(<>
						<div className="input-div-workflow">
							<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
							<input
								type="text"
								ref={editNameRef}
								defaultValue={selectedRow[0].name}
								placeholder="Name"
								style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
								required
							/>
						</div>
					</>)
					: 'Loading'}
				<div className="button-div">
					<Button
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							nameEdit(selectedRow[0])
						}}>
						<span style={{ fontSize: '16px' }}>Ok</span>
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseEditModal()
						}}>
						<span style={{ fontSize: '16px' }}>Cancel</span>
					</Button>
				</div>
			</Modal>
			<FeatureLockedModal
				modalOpen={featureLockedModal}
				onClose={() => setFeatureLockedModal(false)}
			/>
			<Snackbar open={snackOpen} autoHideDuration={2500} onClose={handleSnackClose}>
				<Alert onClose={handleSnackClose} variant="filled" severity="error" sx={{ width: '100%' }}>
					{snackErr ? snackErr : ''}
				</Alert>
			</Snackbar>
			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose}>
				<Alert onClose={handleGreenSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					{successMsg ? successMsg : ''}
				</Alert>
			</Snackbar>
		</div>
	)
}

Team.propTypes = {
	notification: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
	userRole: getUserRole(),
	userName: getUserName(),
	companyFeatures: getCompanyFeatures()
});


const mapDispatchToProps = (dispatch) => ({
	updateTrackerInfo: (data) => dispatch(updateTrackerInfoRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
