export const redirectUnauthorisedUser = function (err, history) {
	if (err.response && err.response.status == "403") {
		history.push("/signin");
	}
};

export const companyCheck = (convo, setConvo2) => {
	let companyName = localStorage.getItem('company');
	// console.log(companyName)
	if (companyName == "62ac3bb53089fbfa5008efd6") {
		// console.log("BCGDV")
		setConvo2(convo.filter((val) => ["Project Manager 1 - TVTech (Prescreening)", "Project Manager 1 - TVTech", "Integration Director - Lion Corp"].includes(val.name)));
	} else if (companyName == "62b3e998e974806c773fefb1") {
		// console.log("DHL")
		setConvo2(convo.filter((val) => ["DHL - Field Sales Specialist", "DHL - PTP Coordinator", "DHL - Country Lead", "DHL - BPO Project Specialist", "DHL - Senior PTP Coordinator"].includes(val.name)))
	} else if (companyName == "62b5477c1af472aad92437eb") {
		// console.log("BCGDV")
		setConvo2(convo.filter((val) => ["DBS - Project Manager"].includes(val.name)))
	} else if (companyName == "62bc7dca1af472aad9245870") {
		// console.log("BCGDV")
		setConvo2(convo.filter((val) => ["Preliminary Screening - CapConnect", "Screening - CapConnect"].includes(val.name)))
	} else if (companyName == "62c3ce5a9ceb9dfcb5d92506") {
		setConvo2(convo.filter((val) => ["Software Engineer - Razer"].includes(val.name)));
	} else if (companyName == "62c50f30fe2bc62a7e91acc5") {
		setConvo2(convo.filter((val) => ["Partnership manager : TravelOtter"].includes(val.name)));
	} else if (companyName == "62c2b080138b1701fd343c8f") {
		setConvo2(convo.filter((val) => ["Partnership manager : TravelOtter", "Senior product developer : Relic", "Consulting: Telecom", "Igloo - Consulting", "Senior Product Evangelist : Relic", "Change Manager - Warehousing"].includes(val.name)))
	} else if (companyName == "62c55be8836e73f0f74fe513") {
		setConvo2(convo.filter((val) => ["BCG Rise - TFIP AI assessment"].includes(val.name)));
	} else {
		// console.log("Some other company")
		setConvo2(convo);
	}

};
