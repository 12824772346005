import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import axios from '../../../axios';

import styles from '../../../styles/MultiSelect.module.css';

const ChatInput4 = (props) => {
	const { messages, addToMessageBox, setNextQues, setProgress, addToExhibitObjs } = props;

	const location = useLocation()

	const [newMessage, setNewMessage] = useState(messages.messages[0].options[0]);
	const [rowH, setRowH] = useState(1);

	useEffect(() => {
		const rowlen = newMessage.split('\n').length;

		setRowH(rowlen);
	}, [newMessage]);

	const onSendMessage = (message) => {
		let convoMssg = {
			q: message.replaceAll('\n', '::'),
			isMyMessage: true
		};
		const url = 'userconvo';
		let getData = {
			user_id: location.state.code,
			comp: location.state.comp,
			message1: messages.messages[0].qid,
			message2: message,
			mode: 0
		};

		addToMessageBox([convoMssg]);
		setNewMessage("");
		axios
			.post(url, getData, { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				let parsedMssg = res.data.msg1[0].map((ele, index) => {
					return ({
						q: ele,
						ref: "na"
					});
				});
				parsedMssg[parsedMssg.length - 1]["qtype"] = res.data.msg1[1];
				parsedMssg[parsedMssg.length - 1]["options"] = res.data.msg1[2];
				parsedMssg[parsedMssg.length - 1]["qid"] = res.data.msg1[3];
				parsedMssg[parsedMssg.length - 1]["exhibit"] = res.data.msg1[4];
				parsedMssg[parsedMssg.length - 1]["progress"] = res.data.msg1[5];
				let parsedRespMssg = res.data.msg2.split(";;").map((ele) => {
					return ({
						q: ele,
						ref: "na"
					});
				});
				let finalQues = [...parsedRespMssg.reverse()];
				sendToChatBox(finalQues, finalQues.length - 1);
				setTimeout(() => { setNextQues(parsedMssg.reverse()) }, (finalQues.length) * 1500);
				setProgress(parseInt(res.data.msg1[5], 10));
				addToExhibitObjs(res.data.msg1);
			})
			.catch((err) => {
				console.log("this is err : ", err);
			});
	}

	const sendToChatBox = (msglist, x) => {
		addToMessageBox([msglist[x]]);

		if (x > 0)
			setTimeout(() => { sendToChatBox(msglist, x - 1) }, 1500);
	}


	const sendButtonClick = () => {
		let trimmedNewMessage = newMessage.trim();
		if (trimmedNewMessage.length >= 1) {
			onSendMessage(trimmedNewMessage);
		} else {
			return null;
		}
	};

	return (
		<div style={{ background: "#fff", borderRadius: "2vh", boxShadow: "0.1rem 0.5rem 1rem grey", width: "50vw", marginTop: "1vh" }}>
			<div style={{ width: "100%", padding: "10px", boxSizing: "border-box" }}>
				<textarea
					rows={rowH}
					style={{
						display: "block",
						background: "#FAFAFA",
						width: "95%",
						fontSize: "16px",
						margin: "auto",
						border: "1px solid rgba(0,0,0,0.3)",
						borderRadius: "7px",
						boxSizing: "border-box",
						padding: "8px 12px",
						boxShadow: "0 5px 10px rgb(0 0 0 / 10%)"
					}}
					value={newMessage}
					onChange={(e) => setNewMessage(e.target.value)}
				/>
			</div>
			<div id="chat-form" className="justify-content-end" style={{ height: "70px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
				<Button className={styles.SubmitButton} onClick={sendButtonClick}>
					<p style={{ fontSize: '16px', fontWeight: 'bold', textTransform: "none" }}>Confirm</p>
				</Button>
			</div>
		</div>
	)
}

export default ChatInput4;
