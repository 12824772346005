import React, { useEffect, useState } from 'react';

import '../../styles/multipleChoiceQuestion.scss';

const MultipleChoiceQuestion = (props) => {
	const { mcqData, submitAnswer } = props;

	const [optionData, setOptionData] = useState([]);

	useEffect(() => {
		const newOptionData = [];
		mcqData.options.forEach((option, i) => {
			const optionObj = {
				text: option.text,
				checked: false
			};

			newOptionData.push(optionObj);
		});

		setOptionData([ ...newOptionData ]);
	}, []);

	const handleOptionCheck = (e, i) => {
		const newOptionData = [...optionData];
		if(mcqData.questionType.value === 'singleAnswerMcq') {
			for(let k = 0; k < newOptionData.length; k++) {
				newOptionData[k].checked = false;
			}
		}
		newOptionData[i].checked = e.target.checked;
		setOptionData([...newOptionData]);
	}

	const confirmAnswer = () => {
		submitAnswer(optionData);
	}

	return (
		<div style={{ backgroundColor: '#F8F8F8', padding: '10px 30px', borderRadius: 25 }}>
			<div className="option-item-question">{mcqData.question}</div>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				{
					optionData.map((option, i) => (
						<div className="option-item-bot" key={i}>
							<label style={{ fontSize: 18 }}>{option.text}<input type="checkbox" checked={option.checked} onClick={(e) => handleOptionCheck(e, i)} /><span className="checkmark"></span></label>
						</div>
					))
				}
			</div>
			<div className="option-item-btnbox">
				<div style={{ backgroundColor: '#479BD2', padding: '8px 32px', color: '#fff', boxShadow: '0px 4px 10px 0px #109CF13D', fontSize: 14, fontWeight: '600', borderRadius: 15, cursor: 'pointer' }} onClick={confirmAnswer}>Confirm</div>
			</div>
		</div>
	)
}

export default MultipleChoiceQuestion;
