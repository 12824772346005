import React, { useState, useEffect, useRef } from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from "react-router-dom";
// import { TourProvider } from '@reactour/tour';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Analytics from "./pages/analytics/Analytics";
import Applicants from "./pages/applicants/Applicants";
import Bots from './pages/bots/Bots';
import ChatboxPage from "./pages/ChatboxPage";
import DriveDetails from './pages/DriveDetails';
import Company from "./pages/companies/Company";
import DashboardPage from "./pages/DashboardPage";
import DemoSignInPage from './pages/DemoSignInPage';
import EmailDashboard from "./pages/emailDashboard/EmailDashboard";
import Feedback from "./pages/feedback/Feedback";
import Finalized from "./pages/finalized/Finalized";
import InProgress from "./pages/InProgress/InProgress";
import Invalid from "./pages/invalid/Invalid";
import LocationCheck from "./LocationCheck";
import ManageBots from './containers/ManageBots';
import ManageFeatures from './containers/ManageFeatures';
import ManageNotifications from './containers/ManageNotifications';
import MyAccount from "./containers/MyAccount";
import NewApplicants from "./pages/newApplicants/NewApplicants";
import PreScreeningBot from "./containers/PreScreeningBot";
import PreScreeningDashboard from "./containers/PreScreeningDashboard";
import QuickMenu from "./pages/quickMenu/QuickMenu";
import ReviewApplicants from "./pages/reviewApplicants/ReviewApplicants";
import SaasPlanDetails from './containers/SaasPlanDetails';
import SaasPlans from "./containers/SaasPlans";
import Settings from "./pages/settings/Settings";
import Sidebar from "./components/sidebar/Sidebar";
import Sidebar1 from "./components/sidebar/Sidebar1";
import SidebarSuperAdmin from "./components/sidebar/SidebarSuperAdmin";
import SignIn from './containers/SignIn';
import SignUpPage from './pages/SignUpPage';
import Success from "./pages/success/Success";
import SuperUser from './pages/superUser/SuperUser';
import Team from "./pages/team/Team";
import TrialUsers from "./containers/TrialUsers";
import Upload from "./pages/emailEditor/Upload";
import Workflow from "./pages/workflows/Workflow";
import VideoSubmission from "./pages/videoSubmission/videoSubmission";
import { getSaasPlanDetails, getSuccessMsg, getUserPlan } from './selectors/app';
import { resetSuccessMsg as resetSuccessMsgRequest, fetchSaasPlanDetails as fetchSaasPlanDetailsRequest } from './actions/app';

//import { registerTutorials, startTutorial, paragraphs } from 'react-interactive-tutorials';

import "./App.css";
//import 'react-interactive-tutorials/dist/react-interactive-tutorials.css';

function Dashboard(props) {
	const { resetSuccessMsg, successMsg, userPlan, fetchSaasPlanDetails, saasPlanDetails } = props;

	const history = useHistory();
	const location = useLocation();

	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [shouldShowTour, setShouldShowTour] = useState(localStorage.getItem('userName') ? false : true);
	const [notification, setNotification] = useState({});
	const [listening, setListening] = useState(false);

	const refObj = useRef();

	useEffect(() => {
		if (!listening) {
			const events = new EventSource(`${process.env.REACT_APP_BASE_SERVER_URL_FULL}/events/${localStorage.getItem('email')}`);

			events.onmessage = (event) => {
				const parsedData = JSON.parse(event.data);
				console.log(parsedData);
				if (!Array.isArray(parsedData)) {
					setNotification(parsedData);
				}
			};

			setListening(true);
		}
	}, [listening]);

	useEffect(() => {
		if (userPlan.name && !saasPlanDetails.name) {
			const data = {
				planName: userPlan.name
			};

			fetchSaasPlanDetails(data);
		}
	}, [userPlan, saasPlanDetails]);

	const handleGreenSnackClick = () => {
		setGreenSnackOpen(true);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setGreenSnackOpen(false);
		resetSuccessMsg();
	};

	useEffect(() => {
		if (!successMsg) return;

		handleGreenSnackClick();
	}, [successMsg]);

	const styles = {
		popover: (base, state) => {
			return {
				...base,
				background: "linear-gradient(180deg, #215E80 0%, #077991 100%)",
				borderRadius: 40,
				paddingLeft: 35,
				paddingRight: 35,
				paddingTop: 20,
				paddingBottom: 20,
				maxWidth: 600,
				margin: 30,
				...doArrow(state.position, state.verticalAlign, state.horizontalAlign),
			}
		},
	}

	const opositeSide = {
		top: 'bottom',
		bottom: 'top',
		right: 'left',
		left: 'right',
	}

	function doArrow(position, verticalAlign, horizontalAlign) {
		if (!position || position === 'custom') {
			return {}
		}
		const side = 30
		const isVertical = position === 'top' || position === 'bottom'
		const width = isVertical ? side : side * 0.866
		const height = isVertical ? side * 0.866 : side

		const color = 'rgba(33, 95, 129, 1)'

		const spaceFromSide = 10

		const obj = isVertical ? {
			'borderLeft': `${width / 2}px solid transparent`, // CSS Triangle width
			'borderRight': `${width / 2}px solid transparent`, // CSS Triangle width
			[`border${position[0].toUpperCase()}${position.substring(1)}`]:
				`${height}px solid ${color}`, // CSS Triangle height
			[isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
				width + spaceFromSide, // space from side
			[opositeSide[position]]: -height,
		} : {
			'borderTop': `${height / 2}px solid transparent`, // CSS Triangle width
			'borderBottom': `${height / 2}px solid transparent`, // CSS Triangle width
			[`border${position[0].toUpperCase()}${position.substring(1)}`]:
				`${width}px solid ${color}`, // CSS Triangle height
			[isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
				height + spaceFromSide, // space from side
			[opositeSide[position]]: -width,
		};

		return {
			'&::after': {
				content: "''",
				width: 0,
				height: 0,
				position: 'absolute',
				...obj,
			},
		}
	}

	/*const YOUR_TUTORIALS = {
		'demo': {
			key: 'demo',
			title: 'Demo Tutorial',
			steps: [
				{
					key: 'anywhere',
					announce: paragraphs`
					  The tutorial will present itself as a series of modals with annotations and prompts on how
					  to progress. This, in fact, is an announcement-style tutorial step.
			
					  Feel free to hide / show this modal at any point to attempt to break the flow of the
					  tutorial. You should not be able to force the tutorial into any situtation it cannot
					  recover from.
					`,
					announceDismiss: "Okay, let's begin",
					activeWhen: [],
				},
				{
					key: 'beginning',
					highlight: '#testNewDriveFlow-selector-1',
					annotate: paragraphs`
					  Step one: choose your favourite colour.
			
					  Note that you can skip this step if your favourite colour was guessed correctly.
					`,
					annotateIn: 'body',
					annotateSkip: 'Skip',
					activeWhen: [
						{
							compare: 'checkpointComplete',
							checkpoint: 'demo_anywhere',
						},
					],
				},
				{
					key: 'complete',
					announce: paragraphs`
					  Now we're back at the first page, but we know the user has acknowledged the announcements
					  on the previous page.
					`,
					announceDismiss: 'Okay',
					activeWhen: [
						{
							compare: 'checkpointComplete',
							checkpoint: 'demo_beginning',
						},
					],
				},
			],
			complete: {
				on: 'checkpointReached',
				checkpoint: 'complete',
				title: 'Demo Tutorial Complete!',
				message: paragraphs`
				  And so concludes the demo tutorial.
		  
				  Take a look at the code for this tutorial below, and get started writing your own
				  tutorials!
				`,
			},
		}
	}

	registerTutorials(YOUR_TUTORIALS);*/

	return (
		<>
			{/* <TourProvider
				steps={[]}
				showNavigation={false}
				showCloseButton={false}
				showButtons={false}
				showNumber={false}
				disableInteraction={true}
				showBadge={false}
				onClickMask={() => { }}
				styles={styles}
				position="bottom"
				startAt={0}
				beforeClose={() => {
					if (location.pathname !== "workflows") history.push("/workflows");
				}}
			> */}
			<Switch>
				<Route exact path='/signup'>
					<SignUpPage />
				</Route>
				<Route exact path='/signin'>
					<SignIn />
				</Route>

				<Route exact path="/companies">
					<SidebarSuperAdmin hidden={true} workflowId="" />
					<Company />
				</Route>
				<Route exact path="/bots">
					<SidebarSuperAdmin hidden={true} workflowId="" />
					<Bots />
				</Route>
				<Route exact path="/user-plans">
					<SidebarSuperAdmin />
					<SaasPlans />
				</Route>
				<Route exact path="/user-plans/:userPlanId">
					<SidebarSuperAdmin />
					<SaasPlanDetails />
				</Route>
				<Route path="/manage-notifications">
					<SidebarSuperAdmin />
					<ManageNotifications />
				</Route>
				<Route exact path="/superUser">
					<SidebarSuperAdmin hidden={true} workflowId="" />
					<SuperUser />
				</Route>
				<Route exact path="/feedback">
					<SidebarSuperAdmin hidden={true} workflowId="" />
					<Feedback />
				</Route>
				<Route exact path="/workflows">
					<Sidebar1 hidden={true} refObj={refObj} />
					<Workflow shouldShowTour={shouldShowTour} setShouldShowTour={setShouldShowTour} notification={notification} refObj={refObj}/>
				</Route>
				<Route exact path="/quickMenu">
					<LocationCheck>
						<Sidebar />
						<QuickMenu notification={notification} />
					</LocationCheck>
				</Route>
				<Route exact path="/analytics">
					<LocationCheck>
						<Sidebar />
						<Analytics notification={notification} />
					</LocationCheck>
				</Route>
				<Route exact path="/workflows/:workflowId">
					<LocationCheck>
						<Sidebar />
						<QuickMenu notification={notification} />
					</LocationCheck>
				</Route>
				<Route path="/applicants">
					<LocationCheck>
						<Sidebar />
						<Applicants notification={notification} />
					</LocationCheck>
				</Route>
				<Route path="/new-applicants">
					<LocationCheck>
						<Sidebar />
						<NewApplicants notification={notification} />
					</LocationCheck>
				</Route>
				<Route path="/email-editor">
					<LocationCheck>
						<Sidebar />
						<Upload />
					</LocationCheck>
				</Route>
				<Route path="/in-progress">
					<LocationCheck>
						<Sidebar />
						<InProgress />
					</LocationCheck>
				</Route>
				<Route path="/review-applicants">
					<LocationCheck>
						<Sidebar />
						<ReviewApplicants notification={notification} />
					</LocationCheck>
				</Route>
				<Route path="/settings">
					<LocationCheck>
						<Sidebar />
						<Settings notification={notification} />
					</LocationCheck>
				</Route>
				<Route path="/drive-details">
					<LocationCheck>
						<Sidebar />
						<DriveDetails notification={notification} />
					</LocationCheck>
				</Route>
				<Route path="/finalized">
					<LocationCheck>
						<Sidebar />
						<Finalized notification={notification} />
					</LocationCheck>
				</Route>
				<Route path="/video-submission">
					<LocationCheck>
						<Sidebar />
						<VideoSubmission notification={notification} />
					</LocationCheck>
				</Route>
				<Route path="/email-dashboard">
					<LocationCheck>
						<Sidebar />
						<EmailDashboard />
					</LocationCheck>
				</Route>
				<Route path="/demorush/:workflowId">
					<DemoSignInPage />
				</Route>
				<Route path="/dashboard/:comp/:workflowId/:user_id">
					<DashboardPage />
				</Route>
				<Route path="/chatbox">
					<ChatboxPage />
				</Route>
				<Route path="/team">
					<LocationCheck>
						<Sidebar1 hidden={true} refObj={refObj} />
						<Team notification={notification} refObj={refObj} />
					</LocationCheck>
				</Route>

				<Route path="/my-account">
					<Sidebar1 hidden={true} />
					<MyAccount notification={notification} />
				</Route>

				<Redirect exact from='/' to='/signin' />
				<Route path="/invalid">
					<Invalid />
				</Route>
				<Route path="/success">
					<Success />
				</Route>
				<Route path="/pre-screening/dashboard">
					<Sidebar1 hidden={true} refObj={refObj} />
					<PreScreeningDashboard notification={notification} />
				</Route>
				<Route path="/pre-screening/:preScreeningBotId/:workflowId/:userId">
					<PreScreeningBot />
				</Route>
				<Route path="/jd-users">
					<TrialUsers />
				</Route>
				<Route path="/manage-bots">
					<SidebarSuperAdmin />
					<ManageBots />
				</Route>
				<Route path="/manage-features">
					<SidebarSuperAdmin />
					<ManageFeatures />
				</Route>
			</Switch>
			{/* </TourProvider> */}

			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose}>
				<Alert onClose={handleGreenSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					{successMsg}
				</Alert>
			</Snackbar>
		</>
	);
}

const mapStateToProps = createStructuredSelector({
	successMsg: getSuccessMsg(),
	userPlan: getUserPlan(),
	saasPlanDetails: getSaasPlanDetails()
});

const mapDispatchToProps = (dispatch) => ({
	resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
	fetchSaasPlanDetails: (data) => dispatch(fetchSaasPlanDetailsRequest(data)),
});

Dashboard.propTypes = {
	successMsg: PropTypes.string,
	resetSuccessMsg: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);


