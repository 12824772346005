import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchTrialUsersList as fetchTrialUsersListRequest } from '../actions/app';
import { getTrialsUsersList } from '../selectors/app';

import '../styles/trialUsers.scss';

const TrialUsers = (props) => {
	const { trialUsersList, fetchTrialUsersList } = props;

	useEffect(() => {
		fetchTrialUsersList();
	}, []);

	return (
		<div className='trial-users-list' style={{ padding: 50 }}>
			<div className='header' style={{ display: 'flex' }}>
				<div>S. No.</div>
				<div>Email</div>
				<div>Name</div>
				<div>Company</div>
				<div>Coupon Code</div>
				<div>Login Type</div>
				<div>Role</div>
				<div>Download Type</div>
				<div>Email Sent</div>
				<div>Created On</div>
			</div>
			<div>
				{
					trialUsersList.map((trialUsers, i) => (
						<div className='rows' style={{ display: 'flex' }}>
							<div>{i + 1}</div>
							<div>{trialUsers.email}</div>
							<div>{trialUsers.name}</div>
							<div>{trialUsers.company}</div>
							<div>{trialUsers.couponCode}</div>
							<div>{trialUsers.loginType}</div>
							<div>{trialUsers.role}</div>
							<div>{trialUsers.downloadedType}</div>
							<div>{trialUsers.emailSent}</div>
							<div>{moment(trialUsers.createdDate).format('L')}</div>
						</div>
					))
				}
			</div>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	trialUsersList: getTrialsUsersList()
});

const mapDispatchToProps = (dispatch) => ({
	fetchTrialUsersList: (data) => dispatch(fetchTrialUsersListRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrialUsers);
