import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';

import Message from '../components/preScreeningBot/Message';
import MyMessage from '../components/preScreeningBot/MyMessage';
import { fetchPreScreeningChat as fetchPreScreeningChatRequest } from '../actions/app';
import { getCurrentBotQuestion, getPreScreeningChatData } from '../selectors/app';

import Logo from '../assets/Logo.png';
import LogoWhite from '../assets/logo-white.png';
import PreScreeningSuccess from '../assets/pre-screening-success.png';

import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@mui/material/Drawer';

import '../styles/preScreeningBot.scss';

const PreScreeningBot = (props) => {
	const { fetchPreScreeningChat, preScreeningChatData, currentBotQuestion } = props;

	const { workflowId, preScreeningBotId, userId } = useParams();
	const [drawerOpen, setDrawerOpen] = useState(false);

	const [chatData, setChatData] = useState([]);
	const [chatDetails, setChatDetails] = useState({});
	const [chatEndModal, setChatEndModal] = useState(false);
	const [totalQuestions, setTotalQuestions] = useState(0);
	const [chatEnded, setChatEnded] = useState(false);

	useEffect(() => {
		const chatReqData = {
			userId,
			preScreeningBotId,
			workflowId,
			qId: 0
		};

		fetchPreScreeningChat(chatReqData);
	}, []);

	useEffect(() => {
		if (preScreeningChatData.chatData) {
			setChatData([...preScreeningChatData.chatData]);
			setChatDetails({ ...preScreeningChatData.details });
			setTotalQuestions(preScreeningChatData.totalQuestions);
			if(!preScreeningChatData.chatData[preScreeningChatData.chatData.length - 1].questionData) {
				setChatEndModal(true);
			}
		}
	}, [preScreeningChatData]);

	const submitAnswer = (val) => {
		const chatReqData = {
			userId,
			preScreeningBotId,
			workflowId,
			qId: currentBotQuestion + 1,
			answer: val,
			totalQuestions
		};

		const newChatData = [...chatData];
		newChatData[newChatData.length - 1] = {
			content: chatData[newChatData.length - 1].questionData.question,
			isMyMessage: false
		};

		val.forEach((option) => {
			const newChatObj = {
				content: option.text,
				isMyMessage: true
			};

			if (option.checked) {
				newChatData.push(newChatObj);
			}
		});

		setChatData([...newChatData]);

		fetchPreScreeningChat(chatReqData);
	}

	return (
		<div className="ps-bot">
			<Drawer
				anchor={"left"}
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
				classes={{
					root: "ps-bot-drawer",
					paper: "ps-bot-drawer-paper"
				}}
			>
				<div className="ps-bot-drawer-head">
					<IconButton onClick={() => setDrawerOpen(false)}>
						<MenuIcon style={{fontSize: "30px"}} />
					</IconButton>
				</div>

				<div className="ps-bot-drawer-body">
					<div className="ps-bot-username">
						{Object.keys(preScreeningChatData).length != 0 ? preScreeningChatData.details.name : ""}
					</div>

					<div className="ps-bot-questionlist">
						{Object.keys(preScreeningChatData).length && Array(preScreeningChatData.totalQuestions).fill().map((val,index) => (
							<div className={index <= currentBotQuestion ? "visited" : "not-visited"}>
								Question {index+1}
							</div>
						))}
					</div>

					<div className="ps-bot-usermail">
						{Object.keys(preScreeningChatData).length != 0 ? preScreeningChatData.details.email : ""}
					</div>
				</div>
			</Drawer>
			<div className="ps-bot-header">
				<div className="ps-bot-header-icon">
					<IconButton onClick={() => setDrawerOpen(true)}>
						<MenuIcon style={{fontSize: "30px"}} />
					</IconButton>
				</div>
				<div className="ps-bot-header-logo">
					<img src={Logo} alt='Logo' style={{ height: 32 }} />
				</div>
			</div>
			<div className="ps-bot-left">
				<div className="ps-bot-details">
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ flex: 1, fontWeight: '600', fontSize: 20 }}>Name</div>
						<div style={{ flex: 3, fontSize: 18 }}>{chatDetails.name}</div>
					</div>
					<div style={{ display: 'flex', marginTop: 25, alignItems: 'center' }}>
						<div style={{ flex: 1, fontWeight: '600', fontSize: 20 }}>Email ID</div>
						<div style={{ flex: 3, fontSize: 18 }}>{chatDetails.email}</div>
					</div>
					<div style={{ marginTop: 30, borderTop: '1px solid #c4c4c4', paddingTop: 20 }}>
						<div style={{ fontWeight: '600', fontSize: 20 }}>About the company</div>
						<div style={{ fontSize: 18, marginTop: 20 }}>{chatDetails.aboutCompany}</div>
					</div>
				</div>
				<div className="ps-bot-nav">
					<div style={{ fontWeight: '600', fontSize: 20 }}>Questions</div>
					<div style={{ marginTop: 15, display: 'flex', flexWrap: 'wrap' }}>
						{Array.from(Array(totalQuestions)).map((item, index) =>
							<div className={`question-sequence-item ${currentBotQuestion > index ? 'done' : ''}`}>{index + 1}</div>
						)}
					</div>
				</div>
			</div>
			{
				chatEnded ? (
					<div style={{ borderRadius: 32, flex: 6, padding: '15px 30px 35px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
						<div>
							<img src={PreScreeningSuccess} alt='' style={{ height: '50vh' }} />
						</div>
						<div style={{ color: '#fff', borderTop: '1px solid #fff', borderBottom: '1px solid #fff', padding: '25px' }}>We've received your answers. You will hear back from the team shortly.</div>
						<div>
							<img src={LogoWhite} alt='' />
						</div>
					</div>
				) : (
					<div className="ps-bot-right">
						<div className="ps-bot-right-header">
							<div style={{ textAlign: 'center', paddingBottom: 10 }}>
								<img src={Logo} alt='Logo' style={{ height: 32 }} />
							</div>
						</div>
						<div style={{ flex: 1, overflowY: 'auto' }}>
							{
								chatData.map((cd) => (
									cd.isMyMessage ? (
										<MyMessage messageData={cd} candidateName={chatDetails.name} />
									) : (
										<Message messageData={cd} submitAnswer={submitAnswer} />
									)
								))
							}
						</div>
						{
							chatEndModal ? (
								<div style={{ position: 'absolute', background: 'rgba(85, 85, 85, 0.65)', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, bottom: 0, right: 0, left: 0, zIndex: "555" }}>
									<div style={{ maxWidth: '380px', borderRadius: 25, background: '#fff', boxShadow: '0px 4px 4px 0px #00000040', padding: 30, textAlign: 'center', boxSizing: "border-box", margin: "0 15px" }}>
										<div>Thanks for completing the pre-screening questions. Submit your responses here.</div>
										<div style={{ marginTop: 15, background: '#8CD55F', boxShadow: '0px 4px 4px 0px #00000040', padding: '6px 20px', borderRadius: 4, color: '#fff', display: 'inline-block', cursor: 'pointer' }} onClick={() => setChatEnded(true)}>Submit</div>
									</div>
								</div>
							) : ''
						}
					</div>
				)
			}
		</div >
	)
};

const mapStateToProps = createStructuredSelector({
	preScreeningChatData: getPreScreeningChatData(),
	currentBotQuestion: getCurrentBotQuestion()
});

const mapDispatchToProps = (dispatch) => ({
	fetchPreScreeningChat: (data) => dispatch(fetchPreScreeningChatRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreScreeningBot);
