export const workFunctionData = {
	"Product Manager": {
		"Formulate and implement product development strategy and plans": [
			'Create feature descriptions for the organization’s products',
			'Develop product portfolio roadmap, pricing, and launch strategies, and financial projections',
			'Anticipate internal and/or external business challenges and/or regulatory issues',
			'Present product portfolio performance to senior stakeholders'
		],
		"Drive product development": [
			'Oversee development for a suite of products',
			'Evaluate products to identify gaps, issues with product interface, performance, and product functionalities based on market feedback',
			'Integrate feedback and requests in the ideation and development of products',
			'Validate detailed specifications and development costing against market potential and future revenue',
			'Prioritises the development of product features against strategic goals and initiatives',
			'Define the requirements for each feature and desired user experience',
			'Determine the timeline for development, implementation and release process for the product',
		],
		"Develop and grow business": [
			'Develop marketing tactics and pricing strategies',
			'Drive market research studies to explore new technology',
			'Drive sales volume from specific target markets in collaboration with sales and marketing team',
			'Oversee the development of business proposals for new opportunities',
			'Identify potential partner relationships for the product',
		],
		"Manage people and organisation": [
			'Manage the budget expenditure and allocation across teams and projects',
			'Monitor and track the achievement of the team’s achievements and key performance indicators',
			'Propose new operational plans, including targeted budgets, work allocations and staff forecasts',
			'Acquire, allocate and optimise the use of and allocation of resources',
			'Develop learning roadmaps to support the professional development of the team',
			'Manage the performance and development process, including providing coaching and development opportunities to maximise the potential of each individual',
		],
	},
	"Project Manager": {
		"Develop project plans": [
			'Conduct cost-benefit analysis and develop project plan',
			'Review project plans to determine time frame, funding limitations, procedures for accomplishing projects',
			'Estimate budgetary needs based on the project scope and anticipate future budget challenges',
			'Assess consolidated project plans for dependencies, gaps and continued business value',
		],
		"Oversee program implementation": [
			'Plan and manage project compliance requirements and adherence to governance structures',
			'Evaluate and address external business environment changes for impact on project scope',
			'Plan and manage project closure and/or transitions',
			'Conduct project reviews to recommend changes to project schedules, cost or resource requirements',
			'Document and track project scope, changes, issues and risks that affect implementation',
			'Facilitate the daily stand-up to achieve team consensus',
			'Eliminate conflicts and assist in developing solutions to manage roadblocks',
			'Refine and manage the product backlog',
		],
		"Manage people and organisation": [
			'Manage project budget, work allocations, manpower and resourcing needs for the team',
			'Develop team members through ongoing coaching, mentoring and career discussions',
			'Drive performance management practices within the team in accordance with organisational policies and procedures',
			'Develop initiatives to support the continuing competence and professional development of the team',
			'Facilitate discussions, problem solving and conflict resolution',
		],
	},
	"Customer Success": {
		"Implement customer success strategy": [
			"Design initiatives and programs to drive client satisfaction, retention and lifetime value",
			"Develop service level agreements with various functions to facilitate implementation, feedback and collaboration",
			"Analyse operating landscape, technology developments, and client feedback to derive insights",
			"Determine key performance indicators and goals to measure progress and achievement of client success"
		],
		"Onboard new customers": [
			"Manage the client onboarding process and provide recommendations to improve the process",
			"Determine objectives and success measures of the onboarding process with the client",
			"Design and manage delivery of client onboarding programmes",
			"Engage clients throughout the onboarding process to identify and address concerns, provide support,obtain feedback and understand client needs",
			"Evaluate success of the client onboarding process and celebrate wins"
		],
		"Optimise derivable value of products and services for customers": [
			"Conduct reviews on usage of and satisfaction with products and services to determine opportunities for optimising value for the client",
			"Formulate solutions to address challenges, under-utilisation, and improve utilisation of solutions to deliver greater value to clients",
			"Create client success case studies and educational resources for internal teams and clients",
			"Conduct sharing sessions with clients on industry best practices",
			"Analyse client data to improve client experience, engagement and satisfaction with the organisation’s products and services",
			"Engage clients to understand their business challenges and variables that may impact future growth and performance",
			"Direct technical issues of products and services to relevant technical teams for resolution"
		],
		"Increase customer lifetime value": [
			"Identify opportunities for upselling and cross-selling of products and services based on analysis of the client’s business strategy, needs and maturity of technology",
			"Provide inputs to conceptualise new products and services and increase the value of existing products and services",
			"Provide inputs to the sales team on securing renewal of contracts and additions to existing contracts",
			"Manage the renewal sales cycle and pipeline"
		]
	},
	"AE": {
		"Identify new business opportunities": [
			"Identify prospective clients and channel partners through online research.",
			"Conduct client and channel partner research to uncover insights on potential business needs.",
			"Conduct market research and profiling, competitive landscape analysis and client profiling.",
			"Assist with identifying new business opportunities with existing clients based on research."
		],
		"Convert sales opportunities to clients": [
			"Provide administrative support to sales and business development teams.",
			"Maintain client/prospect database and documentation",
			"Assist with the preparation of client outreach and presentation materials",
			"Perform follow-up action to close sales."
		],
		"Implement business development and sales strategies": [
			"Analyse business development and sales approaches and strategies to determine their best use within the market",
			"Use sales tools for accurate forecasting of current and future business"
		]
	},
	"SDR": {
		"Identify new business opportunities": [
			"Identify prospective clients and channel partners through online research.",
			"Conduct client and channel partner research to uncover insights on potential business needs.",
			"Conduct market research and profiling, competitive landscape analysis and client profiling.",
			"Assist with identifying new business opportunities with existing clients based on research."
		],
		"Convert sales opportunities to clients": [
			"Provide administrative support to sales and business development teams.",
			"Maintain client/prospect database and documentation",
			"Assist with the preparation of client outreach and presentation materials",
			"Perform follow-up action to close sales."
		],
		"Manage integrated marketing communications (MOC) programme": [
			"Participate in the conceptualisation and design development of marketing campaigns for the business.",
			"Identify target client profile segments, market segments and potential marketing mix.",
			"Consolidate performance data of campaigns.",
			"Monitor implementation of campaigns against planned timeline."
		]
	},
	"ME": {
		"Formulate data-driven market and client insights": [
			'Conduct market research on trends, competitor’s product positioning, placement and pricing strategies',
			"Analyse data obtained on clients' buying behaviours, motivation, attitudes, preferences and needs to derive client insights",
			'Analyse data from digital marketing dashboards',
			"Propose new marketing ideas and approaches based on client insights derived from market analysis"
		],
		"Manage integrated marketing communications (MOC) programme": [
			"Participate in the conceptualisation and design development of MOC campaigns for the business and/or specific lines of product and service",
			"Identify target client profile segments, market segments and potential marketing mix for MOC campaign",
			"Identify possible traditional and digital media channels and platforms for MOC campaign",
			"Coordinate with design teams to develop MOC campaign concept design elements and materials for traditional and digital media channels",
			"Consolidate performance data of MOC campaigns",
			'Update marketing and communications content on various media and platforms'
		]
	},
	"PTP Coordinator": {
		"Perform financial accounting and corporate reporting activities": [
			"Perform month-end closing activities",
			"Maintain accurate financial records and statements",
			"Record and process all business transactions in the accounting system using double-entry accounting",
			"Record transactions and events relating to inventory, accruals, prepayments, capital structure and finance costs",
			"Calculate customer and/or supplier account balances and reconcile with totals",
			"Compile the relevant business documentation used in banking processes",
			"Process payroll transactions into the organization’s accounting system"
		],
		"Process business transactions and reports using information technology tools": [
			"Enter accurate data into the accounting system",
			"Use relevant Infocomm technology systems and tools effectively for data analysis",
			"Retrieve system reports from the accounting system for management’s use",
			"Assign account codes to transactions in the accounting system",
			"Assist to prepare consolidated financial statements, business activity reports and forecasts for management and external stakeholders"
		],
	}
	//   "Associate business analyst": {
	//     "Identify business needs, systems and requirements": [
	//       'Assist in identifying business needs and system requirements',
	//       'Assist in the in-depth analysis of the business requirements and systems specifications',
	//       'Conduct feasibility studies on proposed solutions',
	//       'Provide analysis to support the development of business cases',
	//       'Support the preparation of proposals for modified or replacement systems',
	//       'Conduct analysis on the risk and benefits for the proposed solutions',
	//    ],
	//    "Analyse systems and propose solutions": [
	//       'Analyse systems and processes to identify enhancement opportunities to resolve system gaps',
	//       'Evaluate the ability of an existing system to support proposed changes and identify systems deficiencies and performance gaps',
	//       'Ensure proposed solutions and/or enhancements are aligned with user needs and requirements',
	//       'Identify and conduct feasibility analysis of proposed solutions and/or enhancements to systems',
	//       'Prepare proposals for proposed solutions and/or enhancements to systems',
	//    ],
	//    "Develop technical specifications": [
	//       'Assist with translating business requirements into functional specifications',
	//       'Document specifications and interfaces between legacy and new systems, and systems enhancements and detailed specifications',
	//       'Act as the liaison between users and technical staff throughout the solution implementation cycle',
	//    ],
	//    "Manage the implementation of new solutions and/or enhancements": [
	//       'Develop test plans and test cases',
	//       'Support users on change control and systems updates and escalate issues to relevant team members for resolution',
	//       'Document post-test evidence of expected results or defects',
	//       'Coordinate training for new users',
	//       'Prepare progress reports and training documents',
	//       'Develop technical documentation of the design documents, coding documents and user manuals',
	//       'Coordinate User Acceptance Testing (UAT) and integration testing in accordance with the implementation plan',
	//       'Ensure adherence to project plan to ensure deliverables are completed on time and in accordance with user and system requirements',
	//    ],
	//   },
	//   "Business Analyst": {
	//     "Identify business needs, systems and requirement": [
	//       'Analyse business technology requirements and specifications against value and risk of potential solutions',
	//       'Translate business needs and requirements into potential Artificial Intelligence (AI) and/or analytics problems',
	//       'Scope Proof-of-Concepts for AI and analytics related use cases and projects',
	//       'Identify suitable technological solutions for the business',
	//       'Balance requests and competing priorities from key stakeholders to maximise the value delivered to the organisation',
	//       'Conduct cost-benefit and risk assessment analyses for proposed solutions to determine suitability of solutions',
	//       'Present business cases defining potential benefits and solutions to increase efficiencies of business processes and associated risks',
	//    ],
	//    "Analyse systems and propose solutions": [
	//       'Examine interactions between systems elements, performance and issues',
	//       'Recommend proposed solutions and/or enhancements to improve and optimise processes, workflows, performance and systems',
	//       'Identify opportunities where AI and analytics can address business and user needs and create value',
	//       'Design the solution blueprints for the specific areas of expertise with the consideration of implications for integration across the entire solution',
	//       'Oversee the evaluation of proposed solutions and/or enhancements to ensure its feasibility, viability and efficiency',
	//       'Evaluate the feasibility, viability and implications of proposed solutions and/or enhancements to systems on the current and future business environment',
	//       'Oversee the development of different components within the proposed solutions and/or enhancements',
	//       'Analyse inter-dependencies and inter-linkages of systems and processes across the organisation',
	//    ],
	//    "Develop technical specifications": [
	//       'Translate business requirements and user needs into functional and technical specifications',
	//       'Develop a roadmap to translate existing system specifications into future-state systems requirements',
	//       'Function as the liaison between users and technical team throughout the implementation cycle',
	//       'Ensure that business requirements are incorporated into the solution design',
	//       'Manage risks associated with new solutions and/or proposed enhancements',
	//       'Guide the design and development teams towards smooth solutions integration',
	//    ],
	//    "Manage the implementation of new solutions and/or enhancements": [
	//       'Apply multi-disciplinary technical expertise to support senior management in complex projects',
	//       'Devise procedures to solve complex operational issues',
	//       'Oversee the translation of requirements documentation to systems requirement specifications',
	//       'Manage the conduct of change management programmes and initiatives to drive the adoption of new and/or enhanced technologies including AI related solutions',
	//       'Act as the main point of contact for escalated issues',
	//       'Review technical documentation of the design documents, coding documents and user manuals',
	//       'Oversee the conduct of User Acceptance Testing (UAT) and integration testing',
	//       'Develop dashboards and provide regular status reports to project managers',
	//       'Review work at critical milestones with team leader or sponsor to maintain their commitment and support',
	//    ],
	//   }
}

export const customAssessmentData = {
	'Project Manager': {
		'problemStatement': 'Our customer is {{clientName}}, a leading player in the {{clientIndustry}} industry. They are looking to implement a customer relationship management (CRM) system to better identify leads, qualify and convert them into opportunities, and track these accounts throughout the customer lifecycle. This project has been codenamed {{projectName}}. While they believe that they have found the best CRM solution, they want to make sure to build a hypothesis and test the product in several markets and with several customers before fully adopting. They want the system fully implemented within a year and are unwilling to increase the budget for {{projectName}} during this year. {{clientPm}} will be your main counterpart on the client team.',
		'customFields': [
			{ label: 'Client Name', key: 'clientName' },
			{ label: 'Client Industry', key: 'clientIndustry' },
			{ label: 'Project Name', key: 'projectName' },
			{ label: 'Client PM', key: 'clientPm' }
		]
	},
	'Product Manager': {
		'problemStatement': '{{productName}} is the flagship product for your company, {{companyName}}. {{productName}} is a market leader in the {{product}} space. The product leadership team agreed to sponsor building a new module for {{productName}}, which has been identified as a major revenue booster. You are the PM in charge of this new module, which has been codenamed {{projectName}}.  A {{newFeature}} feature is currently being executed. This new feature has high visibility among the leadership team but has tight timelines and budgets.',
		'customFields': [
			{ label: 'Product Name', key: 'productName' },
			{ label: 'Company Name', key: 'companyName' },
			{ label: 'Product', key: 'product' },
			{ label: 'Project Name', key: 'projectName' },
			{ label: 'New Feature', key: 'newFeature' }
		]
	}
};

export const industryData = [
	{ label: 'Banks', value: 'BNK'},
	{ label: 'Insurance', value: 'INS'},
	{ label: 'Airlines', value: 'ARL'},
	{ label: 'Hotels', value: 'HTL'},
	{ label: 'SaaS', value: 'SAS'},
	{ label: 'Retail', value: 'RTL'},
	{ label: 'Fiancial Services', key: 'FNS'},
	{ label: 'Technology', value: 'TEC'},
	{ label: 'Manufacturing', value: 'MSG'},
	{ label: 'Construction', value: 'CNS'},
	{ label: 'Healthcare', value: 'HLC'},
	{ label: 'Engineering', value: 'ENG'},
	{ label: 'Marketing and sales', value: 'MAS'},
	{ label: 'Real estate', value: 'RES'}
];

export const salesStagesData = [
	{ value: 'TAR', label: 'Targeting and segmentation' },
	{ value: 'PRO', label: 'Prospecting' },
	{ value: 'MAR', label: 'Marketing outreach' },
	{ value: 'CMP', label: 'Campaign management' },
	{ value: 'LGN', label: 'Lead generation/nurturing' },
	{ value: 'PIT', label: 'Pitching' }
];
