import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Button from '@mui/material/Button';
import { io } from "socket.io-client";
import { Modal } from 'react-responsive-modal';
import { useHistory, useLocation } from 'react-router-dom';
import { useTour } from '@reactour/tour';

import axios from '../../axios';
import ChatBody from './chat_section/ChatBody';
import ProgressBar from "./Progress";
import Sidebar from './Sidebar';
import useSpeechSynthesis from './useSpeechSynthesis';

import completedTourImg from '../../assets/Completed-pana.gif';
import Telecommuting from '../../assets/Telecommuting.gif';

import '../../styles/modal.css';
import "../../styles/chatboxpage.css";

const connectionString = process.env.REACT_APP_BASE_URL;

const Chatbox = forwardRef((props, ref) => {
	const { removeSampleVideoExhibits } = props;

	const { setIsOpen } = useTour();
	const location = useLocation();
	const history = useHistory();

	const [toSpeak, setToSpeak] = useState(false);
	const [modal3, setModal3] = useState(false);
	const [modal2, setModal2] = useState(false);
	const [caseState, setCaseState] = useState(false);
	const [nextQues, setNextQues] = useState([]);
	const [startT, setStartT] = useState(location.state.resume ? (new Date().getTime() - new Date(location.state.startT).getTime()) / 1000 : 0);
	const [remainingTime, setRemainingTime] = useState(location.state.hasOwnProperty('remainingTime') ? location.state.remainingTime: 0 );
	const [seeQues, setSeeQues] = useState(true);
	const [progress, setProgress] = useState(0);
	const [exhibits, setExhibits] = useState([]);
	const [exhibitObjs, setExhibitObjs] = useState({});
	const [startTime, setStartTime] = useState(new Date());
	const [stopChat, setStopChat] = useState(false)
	const [speakqueue,setSpeakQueue] = useState([])
	const [videoOn, setVideoOn] = useState(false);
	const [tourFinishModal, setTourFinishModal] = useState(false);
	const [speakIndex,setSpeakIndex] = useState(0)
	const videoRef = useRef(null);
	const photoRef = useRef(null);

	let totalChatTime = location.state.assessmentTime ? location.state.assessmentTime : 30;
	if(location.state.id && location.state.id.videoQs) {
		totalChatTime += (location.state.id.videoQsArr.length * 3);
	}

	let quesStatement = {
		q: location.state.prob,
		ref: "na",
	};
	let tnc = {
		q: `I will be assessing your application for this role. This assessment will take about ${totalChatTime} minutes in total to complete.::::Should you encounter any technical difficulties, please close this tab and visit the link that was shared with you - you will not lose your place in the test. Should you continue to experience difficulties, please email support@hirequotient.com`,
		ref: "na",
		qtype: "tac"
	};
	let timeoutResumeInfinity = null;
	const pitch = 1;
	const rate = 0.95;
	let setIntervalId;
	let socket;

	if (location.state.id == "Test") {
		tnc['q'] = "Welcome to the video structuring practice. This exercise consists of 3 individual cases and will take about 30 minutes in total to complete.::::For each case question, you will be given the introduction to the case. You should then spend 2-3 minutes planning out your approach. When ready, start the video recorder, and then spend another 2-3 minutes explaining  your proposed approach to your interviewer. You have the option to re-record your answer one additional time, if needed.::::If you are satisfied with your answer, simply move on to the next question."
	}

	const [messageBox, setMessageBox] = useState({ new: { messages: [tnc] }, old: { messages: [] } });

	let newMessageBox = [...messageBox.new.messages, ...messageBox.old.messages];

	const onOpenModal3 = () => { setModal3(true) };

	const onCloseModal3 = () => setModal3(false);
	
	const onOpenModal2 = () => { setModal2(true) };

	const onCloseModal2 = () => setModal2(false);

	const addToMessageBox = (mssg) => {
		if (mssg[0].qid && mssg[0].qid === "-1") {
			setCaseState(false)
		}

		setMessageBox({ new: { messages: mssg }, old: { messages: newMessageBox } });

		let tempExhibits = [];

		mssg.forEach(element => {
			if (!element.q.Workbook && element?.q.includes('data:image/')) {
				tempExhibits.push(element.q)
			}
		});

		if (tempExhibits.length > 0) {
			const newExhibits = exhibits.concat(tempExhibits);

			setExhibits([...newExhibits]);
		}

		newMessageBox = [...mssg, ...newMessageBox];

		if (!mssg[0].isMyMessage) {
			addToSpeakQueue(mssg);
			//speaktext();
		} else {
			console.log("emptying the queue")
			cancel();
			//setSpeakIndex(speakqueue.length)
		}
	}

	const addToExhibitObjs = (msgs) => {
		let k = 0;
		const newExhibitObjs = { ...exhibitObjs };
		const exhibitNames = msgs[4].split(';;');

		msgs[0].forEach((item) => {
			if (item.Workbook) {
				console.log('Excel')
			} else {
				if (item.includes('--ref')) {
					k++;
				} else if (item.includes('data:image/')) {
					newExhibitObjs[exhibitNames[k]] = item;
				}
			}
		});

		setExhibitObjs(newExhibitObjs);
	}

	const addToExhibitObjsOnResume = (msgs,newmsgs) => {
		let k = 0;
		const newExhibitObjs = { ...exhibitObjs };

		msgs.forEach((message) => {
			message.ques.forEach((item) => {
				if (item.Workbook) {
					console.log('Excel')
				} else {
					if (item.includes('--ref')) {
						k++;
					} else if (item.includes('data:image/')) {
						newExhibitObjs[message.exhibit[k]] = item;
					}
				}
			});
		});

		const exhibitNames = newmsgs[4].split(';;');

		newmsgs[0].forEach((item) => {
			if (item.Workbook) {
				console.log('Excel')
			} else {
				if (item.includes('--ref')) {
					k++;
				} else if (item.includes('data:image/')) {
					newExhibitObjs[exhibitNames[k]] = item;
				}
			}
		});

		setExhibitObjs(newExhibitObjs);
	}

	useEffect(() => {
		if (!toSpeak) {
			/*setSpeakQueue([]);
			setSpeakIndex(0)*/

			cancel();
			//clearTimeout(timeoutResumeInfinity);
		}
	}, [toSpeak]);

	const addToSpeakQueue = (mssg) => {
		let tmpp = []
		for (let i = mssg.length - 1; i >= 0; i--) {
			if(mssg[i].q.Workbook || mssg[i].q.includes('data:image/'))
				continue
			
			let totalstring = mssg[i].q.replaceAll(':',' ').replaceAll(';',' ').replaceAll('*','').split('. ');
			console.log(totalstring)
			tmpp.push(...totalstring);
	
		}
		setSpeakIndex(speakqueue.length)
		setSpeakQueue([...speakqueue,...tmpp])
	}
	
	const onEnd = () => {
		console.log("reached end")
		//clearTimeout(timeoutResumeInfinity);
		console.log(speakqueue)
		if (toSpeak && speakIndex<speakqueue.length-1)
			setSpeakIndex(speakIndex+1);
	}

	const { speak, cancel, speaking, supported, voices, resume } = useSpeechSynthesis({ onEnd });

	/*const resumeInfinity = () => {
		resume();
		timeoutResumeInfinity = setTimeout(resumeInfinity, 1000);
	}*/

	useEffect(()=>{
		console.log(speakIndex,speakqueue.length)
		if(toSpeak && speakIndex<speakqueue.length){
			console.log(speakqueue)
			let text = speakqueue[speakIndex]
			speak({ text, pitch, rate });
		}		
	},[speakIndex,speakqueue])

	/*const speaktext = () => {
		if(speakIndex>=speakqueue.length-1)
			return
		setSpeakIndex(speakIndex+1)
		//resumeInfinity();

	}
*/
	const addFirstMessage = () => {
		cancel();
		
		const getData = {
			user_id: location.state.code,
			comp: location.state.comp,
			mode: -1,
			message1: { ...location.state.id, wf: location.state.wf },
			message2: "z",
		};

		axios.post('/userconvo', JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				let parsedMssg = res.data.message[0].map((ele, index) => {
					return ({
						q: ele,
						ref: "na"
					})
				});
				parsedMssg[parsedMssg.length - 1]["qtype"] = res.data.message[1];
				parsedMssg[parsedMssg.length - 1]["options"] = res.data.message[2];
				parsedMssg[parsedMssg.length - 1]["qid"] = res.data.message[3];
				parsedMssg[parsedMssg.length - 1]["exhibit"] = res.data.message[4];
				parsedMssg[parsedMssg.length - 1]["progress"] = res.data.message[5];

				addToMessageBox(parsedMssg.reverse());
				setCaseState(true);
				setStartTime(new Date());
				setProgress(parseInt(res.data.message[5], 10));
				addToExhibitObjs(res.data.message);
			})
			.catch((err) => {
				console.log(err)
			});
	}

	const getVideo = () => {
		if(location.state.videoProctoring) {
			navigator.mediaDevices
			.getUserMedia({ video: { width: 640 } })
			.then(stream => {
				let video = videoRef.current;
				video.srcObject = stream;
				try {
					video.play();

					socket = io(connectionString, { path: "/socket", query: "email=" + location.state.code });
					socket.on('connect', function () {
						console.log('Websocket Connected with App');
						setVideoOn(true)
					});
					socket.on('disconnect', function (evt) { console.log('errr', evt); });
					console.log(socket)
					paintToCanvas();
				}
				catch (err) {
					console.log(err)
				}
			})
			.catch(err => {
				console.error("error:", err);
				setVideoOn(false)
			});
		}
	};

	const paintToCanvas = () => {
		let video = videoRef.current;
		let photo = photoRef.current;
		let ctx = photo.getContext("2d");
		const width = 640;
		const height = 480;
		photo.width = width;
		photo.height = height;

		setIntervalId = setInterval(() => {
			if (video.srcObject && setVideoOn){
				ctx.drawImage(video, 0, 0, width, height);
				socket.emit('image', photo.toDataURL());
			}
			else {
				socket.emit('end','send end')
				console.log('send end')
				clearInterval(setIntervalId);
				setTimeout(function(){socket.disconnect()},1000)
			}
		}, 1000);
	};

	const stop = () => {
		setVideoOn(false);

		if (videoRef.current) {
			let video = videoRef.current;

			if (video.srcObject) {
				const stream = video.srcObject;
				const tracks = stream.getTracks();

				for (let i = 0; i < tracks.length; i++) {
					let track = tracks[i];
					track.stop();
				}

				
				video.srcObject = null;
			}
		}
	}
	useEffect(() =>{
		if(stopChat)
			stop()
	})
	useEffect(() => {
		if (location.state.resume) {
			onOpenModal2();
			setMessageBox({ new: { messages: [quesStatement] }, old: { messages: [] } });

			const getData = {
				user_id: location.state.code,
				comp: location.state.comp,
				mode: 2,
				message1: '',
				message2: "z",
			};

			removeSampleVideoExhibits();

			axios.post('/userconvo', JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
				.then((res) => {
					if (res.data.msg2.length > 0) {
						let parsedMssg = res.data.msg2.map((objects) => {
							let obj = objects.ques;
							let mmssgg = obj.map((ele) => {
								if (ele != "")
									return ({
										q: ele,
										ref: "na",
									})
							})
							obj = objects.ans.split('::').filter(word => word.length > 0);
							let mmssgg1 = obj.map((ele) => {
								if (ele != "")
									return ({
										q: ele,
										ref: 'na',
										isMyMessage: true
									})
							})
							obj = objects.resp.split(';;').filter(word => word.length > 0);
							let mmssgg2 = obj.map((ele) => {
								if (ele != "")
									return ({
										q: ele,
										ref: 'na'
									})
							});
							
							return ([...mmssgg, ...mmssgg1, ...mmssgg2])
						});
						let realMssg = [];
						for (let i = 0; i < parsedMssg.length; i++) {
							for (let j = 0; j < parsedMssg[i].length; j++)
								realMssg.push(parsedMssg[i][j]);
						}

						addToMessageBox(realMssg.reverse());
					}

					let parsedMssg1 = res.data.msg1[0].map((ele, index) => {
						return ({
							q: ele,
							ref: "na"
						})
					});
					parsedMssg1[parsedMssg1.length - 1]["qtype"] = res.data.msg1[1];
					parsedMssg1[parsedMssg1.length - 1]["options"] = res.data.msg1[2];
					parsedMssg1[parsedMssg1.length - 1]["qid"] = res.data.msg1[3];
					parsedMssg1[parsedMssg1.length - 1]["exhibit"] = res.data.msg1[4];
					parsedMssg1[parsedMssg1.length - 1]["progress"] = res.data.msg1[5];

					addToMessageBox(parsedMssg1.reverse());
					setCaseState(true);
					setProgress(parseInt(res.data.msg1[5], 10));
					addToExhibitObjsOnResume(res.data.msg2,res.data.msg1);
				})
		} else {
			onOpenModal3();
			setMessageBox({ new: { messages: [tnc] }, old: { messages: [] } });
			getVideo();
		}
	}, []);
	
	useEffect(() => {
		window.onbeforeunload=function(){
			return "Did you save your stuff?";
		};
	}, []);

	const openTourFinishModal = () => { setTourFinishModal(true) };

	const closeTourFinishModal = () => setTourFinishModal(false);

	useImperativeHandle(ref, () => ({
		openTourFinishModal: openTourFinishModal,
		getVideo: getVideo
	}))

	return (
		<div id="background" style={{ display: "flex", background: "#E5E5E5" }}>
			<Sidebar toSpeak={toSpeak} setToSpeak={setToSpeak} exhibits={exhibits} photoRef={photoRef} videoRef={videoRef} videoOn={videoOn} videoProctoring={location.state.videoProctoring} />
			<div style={{ flex: "80vw" }}>
				<ProgressBar messages={messageBox.old} messages1={messageBox.new} startT={startT} addToMessageBox={addToMessageBox} progress={progress} socket={socket} timerState={caseState} setCaseState={setCaseState} setStopChat={setStopChat} startTime={startTime} totalChatTime={totalChatTime} remainingTime={remainingTime} />
				<ChatBody
					messages={messageBox}
					exhibits={exhibits}
					addToMessageBox={addToMessageBox}
					nextQues={nextQues}
					setNextQues={setNextQues}
					seeQues={seeQues}
					setSeeQues={setSeeQues}
					setCaseState={setCaseState}
					setProgress={setProgress}
					setStopChat={setStopChat}
					addFirstMessage={addFirstMessage}
					setMessageBox={setMessageBox}
					exhibitObjs={exhibitObjs}
					addToExhibitObjs={addToExhibitObjs}
					startTime={startTime}
					startT={startT}
					totalChatTime={totalChatTime}
				/>
				<Modal open={modal3} onClose={onOpenModal3} closeIcon={() => { }} center styles={{ modal: { borderRadius: "0", padding: "40px", maxWidth: "500px" } }}>
					<div style={{ textAlign: "center" }}>
						<div style={{ margin: '0 0 20px 0' }}>
							<img
								src={Telecommuting}
								alt="user-logo"
								className='chatbox-user-guide-img'
							/>
						</div>
						<div style={{ color: '#3c3c3c' }}>
							<h1 style={{ color: "inherit" }}>Want to view our quick user guide?</h1>
							<h4 style={{ color: "inherit", margin: "10px 0 30px 0", fontWeight: "500" }}>Familiarise yourself with the interface of the virtual interviewer. Don't worry, this <b>won't include</b> the time for the assessment!</h4>
						</div>
						<div style={{ margin: "10px 0" }}>
							<Button
								size="small"
								variant="contained"
								className="cancel-modal-button"
								style={{
									backgroundImage: 'linear-gradient(to left, #23597C, #008095)',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									borderRadius: '25px',
									padding: '5px 30px',
								}}
								onClick={() => {
									setIsOpen(true)
									onCloseModal3()
								}}
							>
								<span style={{ fontSize: '18px', fontWeight: 'bold', textTransform: 'none' }}>Take tour</span>
							</Button>
						</div>
						<div>
							<div
								className="cancel-modal-button"
								style={{
									background: 'transparent',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
								}}
								onClick={() => {
									removeSampleVideoExhibits();
									onCloseModal3()
									getVideo()
								}}
							>
								<span style={{ color: '#3c3c3c', fontSize: '16px', textDecoration: 'underline' }}>No thanks, I'll take it from here</span>
							</div>
						</div>
					</div>
				</Modal>
				<Modal open={tourFinishModal} onClose={closeTourFinishModal} closeIcon={() => { }} center styles={{ modal: { borderRadius: "0", padding: "40px", maxWidth: "500px" } }}>
					<div style={{ textAlign: "center" }}>
						<div style={{ margin: '0 0 20px 0' }}>
							<img
								style={{
									width: '300px'
								}}
								src={completedTourImg}
								alt="user-logo"
							/>
						</div>
						<div style={{ color: '#3c3c3c' }}>
							<h1 style={{ color: "inherit" }}>You are all set</h1>
							<h4 style={{ color: "inherit", margin: "10px 0 30px 0", fontWeight: "400" }}>Press start to begin your assessment</h4>
						</div>
						<div style={{ margin: "10px 0" }}>
							<Button
								size="small"
								variant="contained"
								className="cancel-modal-button"
								style={{
									backgroundImage: 'linear-gradient(to left, #23597C, #008095)',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									borderRadius: '25px',
									padding: '5px 30px',
								}}
								onClick={() => {
									closeTourFinishModal();
									getVideo();
								}}
							>
								<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Start</span>
							</Button>
						</div>
					</div>
				</Modal>
				<Modal open={modal2} onClose={onOpenModal2} closeIcon={() => { }} center styles={{ modal: { borderRadius: "50px", padding: "40px" } }}>
					<p className="modal-text">
						Shall we resume?
					</p>
					<div className="button-div">
						<Button
							size="small"
							variant="contained"
							className="modal-button"
							onClick={() => {
								onCloseModal2()
								getVideo()
							}}
							style={{
								background: '#479BD2',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								marginLeft: "10px"
							}}
						>
							<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>Ok</span>
						</Button>
						<Button
							size="small"
							variant="contained"
							className="cancel-modal-button"
							onClick={() => {
								onCloseModal2()
								stop()
								history.push('/')
							}}
							style={{
								background: '#479BD2',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								textTransform: 'none'
							}}
						>
							<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>Cancel</span>
						</Button>
					</div>
				</Modal>
			</div>
		</div>
	)
});

export default Chatbox;
