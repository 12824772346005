import React from "react";
import createSagaMiddleware from 'redux-saga';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';

import rootReducer from './reducers/app';
import appSaga from './sagas/app';
import Dashboard from './Dashboard';

import "./App.css";
import './styles/Common.scss';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(appSaga);

function App() {
	return (
		<Provider store={store} >
			<div className="container">
				<Router>
					<Dashboard />
				</Router>
			</div>
		</Provider>
	);
}

export default App;

