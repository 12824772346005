import React, { useEffect, useState } from 'react';

import '../../styles/walkthroughOverlay.scss';

const WalkthroughOverlay = (props) => {
    const { onClickSkip, onClickExit, onClickDone, showWalkthroughNextBtn, currentWalkthroughStep } = props;

    const [stepMessage, setStepMessage] = useState('');
    const [stepMessageTop, setStepMessageTop] = useState(0);
    const [stepMessageRight, setStepMessageRight] = useState(0);
    const [leftArrow, setLeftArrow] = useState(false);
    const [topArrow, setTopArrow] = useState(false);
    const [bottomArrow, setBottomArrow] = useState(false);

    useEffect(() => {
        switch (currentWalkthroughStep) {
            case 0: setStepMessage('');
                break;
            case 1: setStepMessage('Start by creating your first recruitment drive for<br />your new hiring campaign by clicking here.');
                setTopArrow(true);
                setStepMessageTop(175);
                setStepMessageRight(20);
                break;
            case 3: setStepMessage("<strong>Add a name and brief description for the drive.</strong><br />For example:<br /><em>Name: Associate Hiring'22</em><br /><em>Description: Hiring for Boston region at</em><br /><em>associate level, to be interviewed by Partners</em>");
                setTopArrow(false);
                setLeftArrow(true);
                setStepMessageTop(180);
                setStepMessageRight(0);
                break;
            case 4: setStepMessage('Select the role for which you want to<br />create the assessment.');
                setTopArrow(false);
                setLeftArrow(true);
                setStepMessageTop(340);
                setStepMessageRight(0);
                break;
            case 5: setStepMessage('Here you can lay out the expected<br />profile of the ideal candidate, by<br />selecting the relevant work functions<br />and key tasks.');
                setTopArrow(false);
                setLeftArrow(true);
                setStepMessageTop(400);
                setStepMessageRight(0);
                break;
            case 6: setStepMessage('Add your own video questions<br/>at the end of the assessment. You<br/>can add upto 2 video questions, each</br>of 1 minute.');
                setTopArrow(false);
                setLeftArrow(true);
                setStepMessageTop(450);
                setStepMessageRight(20);
                break;
            case 7: setStepMessage('You can choose to add <br/>uniqueness to each assessment<br/> in a drive, so that no two candidates</br>get the same assessment yet there is<br/>apple to apple comparison');
                setTopArrow(false);
                setLeftArrow(true);
                setStepMessageTop(450);
                setStepMessageRight(20);
                break;
            case 8: setStepMessage('<div style="text-align: center"><strong>Done !</strong><br />Click here to save your customisations</div>');
                setTopArrow(false);
                setLeftArrow(false);
                setBottomArrow(true);
                setStepMessageTop(500);
                setStepMessageRight((window.innerWidth / 2) - 150);
                break;
            case 9: setStepMessage('You can view the assessment from here');
                setTopArrow(true);
                setLeftArrow(false);
                setBottomArrow(false);
                setStepMessageTop(175);
                setStepMessageRight(250);
                break;
            default: setStepMessage('');
        }
        if (currentWalkthroughStep === 5) {
            if(document.getElementById('specifyKeyTask')){
                document.getElementById('specifyKeyTask').style.transform = 'scale(1.05)';
                setTimeout(function () {
				    document.getElementById('specifyKeyTask').style.transform = 'scale(1)';
			     }, 500);
            } else if (document.getElementById('specifyFunctionalSkill')){
                setStepMessage('Here you can lay out<br/> the expected profile of the ideal<br/> candidate, by selecting the relevant<br/> functional and core skills.');
                document.getElementById('specifyFunctionalSkill').style.transform = 'scale(1.05)';
                setTimeout(function () {
                    document.getElementById('specifyFunctionalSkill').style.transform = 'scale(1)';
                 }, 500);
                setStepMessage('Here you can lay out<br/> the expected profile of the ideal<br/> candidate, by selecting the relevant functional <br/>and core skills.');
                
            }
        }
        if (currentWalkthroughStep === 6) {
            document.getElementById('walkthroughStep6').scrollIntoView();
        }
        if (currentWalkthroughStep === 7) {
            document.getElementById('walkthroughStep7').scrollIntoView();
        }
    }, [currentWalkthroughStep]);

    return (
        <div className='walkthrough-overlay'>
            {
                stepMessage !== '' ? (
                    <div className='step-box' style={{ top: stepMessageTop, right: stepMessageRight }}>
                        <div dangerouslySetInnerHTML={{ __html: stepMessage }} />
                        {
                            leftArrow ? (
                                <div className='left-arrow'></div>
                            ) : ''
                        }
                        {
                            topArrow ? (
                                <div className='top-arrow'></div>
                            ) : ''
                        }
                        {
                            bottomArrow ? (
                                <div className='bottom-arrow'></div>
                            ) : ''
                        }
                    </div>
                ) : ''
            }
            <div style={{ display: 'flex', padding: 50, justifyContent: 'space-between', width: '100%' }}>
                <div>
                    <div style={{ padding: '8px 32px', fontWeight: '600', background: '#479BD2', color: '#fff', borderRadius: 40, cursor: 'pointer' }} onClick={onClickExit}>Exit Walkthrough</div>
                </div>
                {/* <div style={{ textAlign: 'center' }}>
                    <div  style={{ padding: '8px 32px', fontWeight: '600', background: '#479BD2', color: '#fff', borderRadius: 40, cursor: 'pointer' }} onClick={onClickSkip}>Skip</div>
                    <div style={{ marginTop: 10, color: '#fff', fontWeight: '600' }}>(Go to next step)</div>
                </div> */}
                {
                    showWalkthroughNextBtn && currentWalkthroughStep < 8 ? (
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ padding: '8px 32px', fontWeight: '600', background: '#479BD2', color: '#fff', borderRadius: 40, cursor: 'pointer' }} onClick={onClickDone}>Next</div>
                            {/* <div style={{ marginTop: 10, color: '#fff', fontWeight: '600' }}>(Go to next step)</div> */}
                        </div>
                    ) : ''
                }
            </div>
        </div>
    )
}

export default WalkthroughOverlay;
