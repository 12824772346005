import React from 'react'
//<img src="./bluebg.jpg" style={{minHeight:"100vh", minWidth:"100vw", position:"fixed", zIndex:"-1", backgroundSize: "contain", backgroundPosition:"center"}} />
      
function Invalid() {
  return (
    <div>
      <div style={{ height:"100vh", width:"100vw", color:"#fff", display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center", fontSize:"4rem", backgroundColor:"#498792"}}>
        Link you used is either Invalid or Expired.
        <p style={{fontSize:'2rem'}}><br/>You should contact your interviewers or mail to us at <a href="mailto::support@hirequotient.com">support@hirequotient.com</a></p>
      </div>
      <img src="./hq-logo.png" style={{height:"10vh",top:"5px",left:"20px",position:"fixed"}}/>
    </div>
  )
}

export default Invalid
