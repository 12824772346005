import React from 'react';
import { Container, Link } from '@material-ui/core';

import { NavAppBar, NavToolBar } from './MaterialComponents'

import styles from '../styles/Navbar2.module.css'

const Navbar2 = () => {
	return (
		<NavAppBar style={{ backgroundColor: '#fff', boxShadow: 'none', width: "100vw", backgroundImage: "linear-gradient(to right, #23597C, #008095)" }}>
			<Container maxWidth='lg'>
				<NavToolBar style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Link
						href='/'
						style={{
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							color: '#fff',
							textDecoration: 'none',
						}}
					>
						<span>
							<img style={{ height: "auto", width: "250px", padding: "1vh" }} src="/hq-logo.png" alt="logo" />
						</span>
					</Link>
					<div
						className={styles.navLink}
						style={{
							display: 'flex',
							height: '100%',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
					</div>
				</NavToolBar>
			</Container>
		</NavAppBar>
	)
}

export default Navbar2;
