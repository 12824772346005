import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import Select from '../common/Select';
import { Button, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';

const AddPreScreeningModal = (props) => {
	const { addBotModal, onCloseAddBotModal, addPreScreeningBot, setSnackSuccessMsg, handleSuccessSnackClick, setSnackErrMsg, handleErrSnackClick } = props;

	const history = useHistory();

	const [addBotName, setAddBotName] = useState('');
	const [addAboutBot, setAddAboutBot] = useState('');
	const [addBotQuestionData, setAddBotQuestionData] = useState([]);

	const onChangeQuestionText = (e, index) => {
		const newAddBotQuestionData = [...addBotQuestionData];
		newAddBotQuestionData[index].question = e.target.value;
		setAddBotQuestionData([...newAddBotQuestionData]);
	}

	const onChangeQuestionType = (val, index) => {
		const newAddBotQuestionData = [...addBotQuestionData];
		newAddBotQuestionData[index].questionType = val;
		setAddBotQuestionData([...newAddBotQuestionData]);
	}

	const handleOptionCheck = (e, j, i) => {
		const newAddBotQuestionData = [...addBotQuestionData];
		newAddBotQuestionData[i].options[j].correct = e.target.checked;
		setAddBotQuestionData([...newAddBotQuestionData]);
	}

	const handleOptionKeyDown = (e, index) => {
		if (e.key === 'Enter') {
			const newAddBotQuestionData = [...addBotQuestionData];
			const newOptionObj = {
				text: e.target.value,
				correct: false
			}
			newAddBotQuestionData[index].options.push(newOptionObj);
			setAddBotQuestionData([...newAddBotQuestionData]);
			e.target.value = '';
		}
	}

	const handleOptionKeyDownAdded = (e, index, j) => {
		if (e.key === 'Enter') {
			const newAddBotQuestionData = [...addBotQuestionData];
			newAddBotQuestionData[index].options[j].text = e.target.value;
			setAddBotQuestionData([...newAddBotQuestionData]);
		}
	}

	const onChangeOption = (e, index, j) => {
		const newAddBotQuestionData = [...addBotQuestionData];
		newAddBotQuestionData[index].options[j].text = e.target.value;
		setAddBotQuestionData([...newAddBotQuestionData]);
	}

	const addNewQuestion = () => {
		const addBotQuestion = {
			question: '',
			questionType: '',
			options: []
		};
		const newAddBotQuestionData = [...addBotQuestionData];
		newAddBotQuestionData.push(addBotQuestion);
		setAddBotQuestionData([...newAddBotQuestionData]);
	}

	const onCreatePreScreeningBot = () => {
		if (addBotName === '') {
			setSnackErrMsg('Enter valid Name');
			handleErrSnackClick();
		} else if(checkQuestionsEmpty()) {
			setSnackErrMsg('Enter valid questions');
			handleErrSnackClick();
		} else {
			const botData = {
				name: addBotName,
				about: addAboutBot,
				questionData: addBotQuestionData
			};

			addPreScreeningBot({ botData, history });
			onCloseAddBotModal();
			setSnackSuccessMsg('Pre-Screening Bot Added Successfully')
			handleSuccessSnackClick();
		}
	}

	const checkQuestionsEmpty = () => {
		let emptyQuestionField = false;
		if(addBotQuestionData.length > 1) {
			addBotQuestionData.forEach((abqd, i) => {
				if(i < addBotQuestionData.length - 1) {
					if(abqd.questionType === '' || abqd.question === '' || abqd.options.length === 0) {
						emptyQuestionField = true;
					}
					abqd.options.forEach((option) => {
						if(option.text === '') {
							emptyQuestionField = true;
						}
					});	
				}
			});
			console.log(emptyQuestionField);
			if(!emptyQuestionField) {
				const lastQ = addBotQuestionData[addBotQuestionData.length - 1];
				let lastQEmpty = true;
				if(lastQ.questionType !== '' && lastQ.question !== '' && lastQ.options.length > 0) {
					lastQ.options.forEach((option) => {
						if(option.text !== '') {
							lastQEmpty = false;
						}
					});
				} else {
					lastQEmpty = false;
				}
				if(!lastQEmpty) {
					if(lastQ.questionType === '' || lastQ.question === '' || lastQ.options.length === 0) {
						emptyQuestionField = true;
					}
					lastQ.options.forEach((option) => {
						if(option.text === '') {
							emptyQuestionField = true;
						}
					});
				}
			}
		} else {
			addBotQuestionData.forEach((abqd) => {
				if(abqd.questionType === '' || abqd.question === '' || abqd.options.length === 0) {
					emptyQuestionField = true;
				}
				abqd.options.forEach((option) => {
					if(option.text === '') {
						emptyQuestionField = true;
					}
				});
			});
		}
		console.log(emptyQuestionField);
		return emptyQuestionField;
	}

	useEffect(() => {
		if (addBotModal) {
			const addBotFirstQuestion = {
				question: '',
				questionType: '',
				options: []
			};
			const newAddBotQuestionData = [];
			newAddBotQuestionData.push(addBotFirstQuestion);
			setAddBotQuestionData([...newAddBotQuestionData]);
			setAddBotName('');
			setAddAboutBot('');
		}
	}, [addBotModal]);

	return (
		<Modal open={addBotModal} onClose={onCloseAddBotModal} center closeOnOverlayClick={false}>
			<div style={{ padding: '20px 40px' }}>
				<div style={{ display: 'flex', marginRight: 100 }}>
					<div style={{ marginRight: 100 }}>
						<div style={{ fontSize: 22, fontWeight: '600', whiteSpace: 'nowrap' }}>Add Questions</div>
						<div style={{ marginTop: 15 }}>
							<input style={{ color: '#8B8B8B', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #8b8b8b' }} type="text" placeholder='PS Name' value={addBotName} onChange={(e) => setAddBotName(e.target.value)} />
						</div>
					</div>
					<div>
						<div style={{ fontSize: 14, fontWeight: '600', color: '#595959' }}>About us:</div>
						<div style={{ marginTop: 15 }}>
							<Tooltip title={addAboutBot}>
								<input style={{ color: '#8B8B8B', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #8b8b8b', width: 400 }} type="text" placeholder='Add description here' value={addAboutBot} onChange={(e) => setAddAboutBot(e.target.value)} />
							</Tooltip>
						</div>
					</div>
				</div>
				<div style={{ borderRadius: 20, boxShadow: '0px 4px 5px 2px #B7B7B761', padding: '15px 30px', marginTop: 30, marginBottom: 35 }}>
					<div style={{ fontSize: 15, color: '#878787', fontWeight: '700' }}>List of questions</div>
					{
						addBotQuestionData.map((abqd, i) => (
							<div style={{ display: 'flex', marginTop: 15 }} key={i}>
								<div style={{ fontSize: 16, fontWeight: '700', color: '#878787', marginRight: 15 }}>Q. {i + 1}</div>
								<div style={{ flex: 1, padding: '15px 30px', borderRadius: 25, boxShadow: '0px 2px 3px 1px #B0B0B061' }}>
									<div style={{ display: 'flex' }}>
										<div style={{ flex: 1, marginRight: 25 }}>
											<input style={{ lineHeight: '21px', width: '100%', color: '#858585', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #c4c4c4' }} type='text' placeholder='Type new question ...' value={abqd.question} onChange={(e) => onChangeQuestionText(e, i)} />
										</div>
										<div>
											<Select
												selectedValue={abqd.questionType.value ? abqd.questionType.label : 'Select question type'}
												onChangeSelect={(val) => onChangeQuestionType(val, i)}
												dropdownData={[
													{ label: 'Single Answer MCQ', value: 'singleAnswerMcq' },
													{ label: 'Multi Answer MCQ', value: 'mutiAnswerMcq' }
												]}
												style={{
													root: {
														position: 'relative',
														zIndex: (addBotQuestionData.length - i)
													}
												}}
											/>
										</div>
									</div>
									<div className='options'>
										{
											abqd.options.map((option, j) => (
												<div style={{ display: 'flex', marginTop: 10, alignItems: 'center', justifyContent: 'space-between' }}>
													<div style={{ display: 'flex', marginRight: 50, alignItems: 'center' }}>
														<div style={{ width: 16, height: 16, border: '2px solid #b4b4b4', borderRadius: '50%', marginRight: 10 }}></div>
														{/* <div style={{ fontSize: 13, color: '#6b6b6b' }}>{option.text}</div> */}
														<input style={{ width: 200, color: '#8B8B8B', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #8b8b8b' }} type='text' placeholder='Add Option' value={option.text} onKeyDown={(e) => handleOptionKeyDownAdded(e, i, j)} onChange={(e) => onChangeOption(e, i, j)} />
													</div>
													<div className="option-item" key={j}>
														<label>Correct<input type="checkbox" name={option.text} checked={option.correct} onClick={(e) => handleOptionCheck(e, j, i)} /><span className="checkmark"></span></label>
													</div>
												</div>
											))
										}
									</div>
									<div style={{ marginTop: 25 }}>
										<input style={{ width: 200, color: '#8B8B8B', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #8b8b8b' }} type='text' placeholder='Add Option' onKeyDown={(e) => handleOptionKeyDown(e, i)} />
									</div>
								</div>
								{
									i === addBotQuestionData.length - 1 ? (
										<div className='add-question-btn' onClick={addNewQuestion}></div>
									) : ''
								}
							</div>
						))
					}
				</div>
				<div className="button-div">
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={onCreatePreScreeningBot}>
						<span style={{ fontSize: '16px' }}>Create</span>
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default AddPreScreeningModal;
