import React from 'react';
import PropTypes from 'prop-types';

const Progress = ({ percentage }) => {
  const Parentdiv = {
    height: "2vh",
    width: '300px',
    backgroundColor: 'whitesmoke',
    borderRadius: 40,
    dispaly: "flex",
    margin: "auto",
  }
  
  const Childdiv = {
    height: '100%',
    width: `${percentage}%`,
    backgroundColor: "#3AB9D6",
   borderRadius:40,
    textAlign: 'right'
  }
  
  const progresstext = {
    display: "flex",
    flexDirection: "column-reverse",
    padding: "0 10px",
    color: 'black',
    fontSize: "1.5vh",
    fontWeight: "bold"
  }

  return (
    <div style={Parentdiv}>
    <div style={Childdiv}>
        <span style={progresstext}>{`${percentage}%`}</span>
    </div>
</div>
  );
};

Progress.propTypes = {
  percentage: PropTypes.number.isRequired
};

export default Progress;
