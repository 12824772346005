import React from 'react';

import '../../styles/billingAndPurchases.scss';

const BillingAndPurchases = (props) => {
	const { setSuccessAlertMsg, onSuccessAlertOpen } = props;

	return (
		<div className='billing-and-purchases'>
			<div className='purchase-history'>
				<div>Purchase history</div>
				<div>
					<div>
						<div></div>
						<div></div>
					</div>
					<div>
						<div></div>
						<div></div>
					</div>
					<div>
						<div></div>
						<div></div>
					</div>
					<div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
			<div className='credit-meter'>
				<div>Credit meter</div>
				<div>
					<div>
						<div></div>
						<div></div>
					</div>
					<div>
						<div>
							<div></div>
							<div></div>
						</div>
						<div>
							<div></div>
							<div></div>
						</div>
					</div>
					<div>
						<div>Upgrade now</div>
					</div>
				</div>
			</div>
			<div className='cancel-subscription'>
				<div>
					<div>Cancel subscription</div>
					<div>Need any support? Reach out to us at recruiter-support@hirequotient.com </div>
				</div>
				<div>Cancel subscription</div>
			</div>
		</div>
	)
}

export default BillingAndPurchases;
