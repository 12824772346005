import { Button } from '@material-ui/core'
import React from 'react'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import axios from '../../axios';
import { useLocation,useHistory } from 'react-router-dom';

function EmailDashboard(emailDetails) {
  let history = useHistory()
  let location = useLocation()
  // console.log(location.state)
  const openEmailEditor = (emailType) => {
    if(emailType === "Invited")
      history.push({
        pathname: "/email-editor", 
        state: {
          key:'invited',  workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "emailEditor"
        }
      })
    else if(emailType === "Completed")
    history.push({
      pathname: "/email-editor", 
      state: {
        key:'completed',  workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "emailEditor"
      }
    })
  }

  return (
    <div style={{ flex: "4", padding:"5vh 4vw 2vh 8vw", background: "#eee", height:"100%", minHeight:"93vh"}}>
      <div style={{fontSize: "3rem", fontWeight:"600"}}>
            {location.state.workflowName}
          </div>
      <div style={{display:"flex", justifyContent:"space-between"}}>
        <div style={{fontSize: "2rem", fontWeight: "500", marginBottom:"1vh"}}>
          Email Dashboard
        </div>
        {/* <div>
          <Button style={{color:"blue", fontWeight:"600"}}>Edit parameters</Button>
          <Button style={{color:"#fff", background:"orange", textDecorationStyle:"none", padding:"10px 20px", borderRadius:"20px", fontWeight:"600"}}>Test All Edited Mails</Button>
        </div> */}
      </div>
      <div style={{height:"2px", background:"#ccc", width:"75vw"}}></div>
      <div>
        <div style={{fontSize: "1.5rem", fontWeight: "500", margin:"1vh", color:"#555"}}>
          Before Application
        </div>
        <div style={{background:"#fff", width:"290px", borderRadius: "10px"}}>
          <div style={{fontSize: "1.2rem", fontWeight: "500", margin:"1vh", padding:"1vh 0 0", color:"#333"}}>
            Candidate Invitation
          </div>
          <div style={{fontWeight: "500", margin:"3vh 1vh 0.25vh"}}>
            <p style={{color:"#888", fontSize: "0.9rem"}}>Last tested <span style={{color:"#333", fontSize: "1.05rem", fontWeight:"600"}}> - 1 week ago</span> </p>
          </div>
          <div style={{fontWeight: "500", margin:"0.25vh 1vh 1vh"}}>
            <p style={{color:"#888", fontSize: "0.9rem"}}>Last edited <span style={{color:"#333", fontSize: "1.05rem", fontWeight: "600"}}> - 1 week ago</span> </p>
          </div>
          <div style={{height:"1px", background:"#ddd", width:"100%", marginBottom: "1vh"}}></div>
          <div style={{display:"flex", flexDirection:"row-reverse", justifyContent:"center", paddingBottom:"1vh"}}>
            <Button style={{background:"orange", color:"#fff"}} onClick={() => {openEmailEditor("Invited")}}> <EditOutlinedIcon/> Edit</Button>
          </div>
        </div>
      </div>
      <div>
        <div style={{fontSize: "1.5rem", fontWeight: "500", margin:"3vh 1vh 1vh", color:"#555"}}>
          After Application
        </div>
        <div style={{background:"#fff", width:"290px", borderRadius: "10px"}}>
          <div style={{fontSize: "1.2rem", fontWeight: "500", margin:"1vh", padding:"1vh 0 0", color:"#333"}}>
            Candidate Conclusion
          </div>
          <div style={{fontWeight: "500", margin:"3vh 1vh 0.25vh"}}>
            <p style={{color:"#888", fontSize: "0.9rem"}}>Last tested <span style={{color:"#333", fontSize: "1.05rem", fontWeight:"600"}}> - 1 week ago</span> </p>
          </div>
          <div style={{fontWeight: "500", margin:"0.25vh 1vh 1vh"}}>
            <p style={{color:"#888", fontSize: "0.9rem"}}>Last edited <span style={{color:"#333", fontSize: "1.05rem", fontWeight: "600"}}> - 1 week ago</span> </p>
          </div>
          <div style={{height:"1px", background:"#ddd", width:"100%", marginBottom: "1vh"}}></div>
          <div style={{display:"flex", flexDirection:"row-reverse", justifyContent:"center", paddingBottom:"1vh"}}>
            <Button style={{background:"orange", color:"#fff"}}  onClick={() => {openEmailEditor("Completed")}}> <EditOutlinedIcon/> Edit</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailDashboard
