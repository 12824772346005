import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Select from '../common/Select';

const EditPreScreeningModal = (props) => {
	const { editBotModal, onCloseEditBotModal, editPreScreeningBot, setSnackSuccessMsg, handleSuccessSnackClick, selectedRow, setSnackErrMsg, handleErrSnackClick } = props;

	const history = useHistory();

	const [editBotName, setEditBotName] = useState('');
	const [editAboutBot, setEditAboutBot] = useState('');
	const [editBotQuestionData, setEditBotQuestionData] = useState([]);

	const onChangeQuestionText = (e, index) => {
		const newEditBotQuestionData = [...editBotQuestionData];
		newEditBotQuestionData[index].question = e.target.value;
		setEditBotQuestionData([...newEditBotQuestionData]);
	}

	const onChangeQuestionType = (val, index) => {
		const newEditBotQuestionData = [...editBotQuestionData];
		newEditBotQuestionData[index].questionType = val;
		setEditBotQuestionData([...newEditBotQuestionData]);
	}

	const handleOptionCheck = (e, j, i) => {
		const newEditBotQuestionData = [...editBotQuestionData];
		newEditBotQuestionData[i].options[j].correct = e.target.checked;
		setEditBotQuestionData([...newEditBotQuestionData]);
	}

	const handleOptionKeyDown = (e, index) => {
		if (e.key === 'Enter') {
			const newEditBotQuestionData = [...editBotQuestionData];
			const newOptionObj = {
				text: e.target.value,
				correct: false
			}
			newEditBotQuestionData[index].options.push(newOptionObj);
			setEditBotQuestionData([...newEditBotQuestionData]);
			e.target.value = '';
		}
	}

	const handleOptionKeyDownAdded = (e, index, j) => {
		if (e.key === 'Enter') {
			const newAddBotQuestionData = [...editBotQuestionData];
			newAddBotQuestionData[index].options[j].text = e.target.value;
			setEditBotQuestionData([...newAddBotQuestionData]);
		}
	}

	const onChangeOption = (e, index, j) => {
		const newAddBotQuestionData = [...editBotQuestionData];
		newAddBotQuestionData[index].options[j].text = e.target.value;
		setEditBotQuestionData([...newAddBotQuestionData]);
	}

	const editNewQuestion = () => {
		const editBotQuestion = {
			question: '',
			questionType: '',
			options: []
		};
		const newEditBotQuestionData = [...editBotQuestionData];
		newEditBotQuestionData.push(editBotQuestion);
		setEditBotQuestionData([...newEditBotQuestionData]);
	}

	const onEditPreScreeningBot = () => {
		if (editBotName === '') {
			setSnackErrMsg('Enter valid Name');
			handleErrSnackClick();
		} else {
			const botData = {
				botId: selectedRow._id,
				name: editBotName,
				about: editAboutBot,
				questionData: [...editBotQuestionData]
			};
	
			editPreScreeningBot({ botData, history });
			onCloseEditBotModal();
			setSnackSuccessMsg('Pre-Screening Bot Added Successfully')
			handleSuccessSnackClick();
		}
	}

	useEffect(() => {
		if (editBotModal) {
			setEditBotName(selectedRow.name);
			setEditAboutBot(selectedRow.about);
			setEditBotQuestionData([...selectedRow.questionData]);
		}
	}, [editBotModal, selectedRow]);

	return (
		<Modal open={editBotModal} onClose={onCloseEditBotModal} center closeOnOverlayClick={false}>
			<div style={{ padding: '20px 40px' }}>
				<div style={{ display: 'flex', marginRight: 100 }}>
					<div style={{ marginRight: 100 }}>
						<div style={{ fontSize: 22, fontWeight: '600', whiteSpace: 'nowrap' }}>Edit Questions</div>
						<div style={{ marginTop: 15 }}>
							<input style={{ color: '#8B8B8B', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #8b8b8b' }} type="text" placeholder='PS Name' value={editBotName} onChange={(e) => setEditBotName(e.target.value)} />
						</div>
					</div>
					<div>
						<div style={{ fontSize: 14, fontWeight: '600', color: '#595959' }}>About us:</div>
						<div style={{ marginTop: 15 }}>
							<input style={{ color: '#8B8B8B', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #8b8b8b', width: 400 }} type="text" placeholder='Add description here' value={editAboutBot} onChange={(e) => setEditAboutBot(e.target.value)} />
						</div>
					</div>
				</div>
				<div style={{ borderRadius: 20, boxShadow: '0px 4px 5px 2px #B7B7B761', padding: '15px 30px', marginTop: 30, marginBottom: 35 }}>
					<div style={{ fontSize: 15, color: '#878787', fontWeight: '700' }}>List of questions</div>
					{
						editBotQuestionData.map((abqd, i) => (
							<div style={{ display: 'flex', marginTop: 15 }} key={i}>
								<div style={{ fontSize: 16, fontWeight: '700', color: '#878787', marginRight: 15 }}>Q. {i + 1}</div>
								<div style={{ flex: 1, padding: '15px 30px', borderRadius: 25, boxShadow: '0px 2px 3px 1px #B0B0B061' }}>
									<div style={{ display: 'flex' }}>
										<div style={{ flex: 1, marginRight: 25 }}>
											<input style={{ lineHeight: '21px', width: '100%', color: '#858585', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #c4c4c4' }} type='text' placeholder='Type new question ...' value={abqd.question} onChange={(e) => onChangeQuestionText(e, i)} />
										</div>
										<div>
											<Select
												selectedValue={abqd.questionType.value ? abqd.questionType.label : 'Select question type'}
												onChangeSelect={(val) => onChangeQuestionType(val, i)}
												dropdownData={[
													{ label: 'Single Answer MCQ', value: 'singleAnswerMcq' },
													{ label: 'Multi Answer MCQ', value: 'mutiAnswerMcq' }
												]}
												style={{
													root: {
														position: 'relative',
														zIndex: (editBotQuestionData.length - i)
													}
												}}
											/>
										</div>
									</div>
									<div className='options'>
										{
											abqd.options.map((option, j) => (
												<div style={{ display: 'flex', marginTop: 10, alignItems: 'center', justifyContent: 'space-between' }}>
													<div style={{ display: 'flex', marginRight: 50, alignItems: 'center' }}>
														<div style={{ width: 16, height: 16, border: '2px solid #b4b4b4', borderRadius: '50%', marginRight: 10 }}></div>
														{/* <div style={{ fontSize: 13, color: '#6b6b6b' }}>{option.text}</div> */}
														<input style={{ width: 200, color: '#8B8B8B', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #8b8b8b' }} type='text' placeholder='Add Option' value={option.text} onKeyDown={(e) => handleOptionKeyDownAdded(e, i, j)} onChange={(e) => onChangeOption(e, i, j)} />
													</div>
													<div className="option-item" key={j}>
														<label>Correct<input type="checkbox" name={option.text} checked={option.correct} onClick={(e) => handleOptionCheck(e, j, i)} /><span className="checkmark"></span></label>
													</div>
												</div>
											))
										}
									</div>
									<div style={{ marginTop: 25 }}>
										<input style={{ width: 200, color: '#8B8B8B', outline: 'none', border: 'none', padding: '5px 0', borderBottom: '1px solid #8b8b8b' }} type='text' placeholder='Add Option' onKeyDown={(e) => handleOptionKeyDown(e, i)} />
									</div>
								</div>
								{
									i === editBotQuestionData.length - 1 ? (
										<div className='add-question-btn' onClick={editNewQuestion}></div>
									) : ''
								}
							</div>
						))
					}
				</div>
				<div className="button-div">
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={onEditPreScreeningBot}>
						<span style={{ fontSize: '16px' }}>Save</span>
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default EditPreScreeningModal;
