import React from "react";
import styled from "styled-components";

export const Grid = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;
  list-style: none;
  margin: 1vw auto;
`;

const GridItemWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 40vw;
  justify-content: start;
  align-items: center;
  
  box-sizing: border-box;

  :before {
    content: "";
    display: table;
    padding-top: 10vh;
  }
`;

export const GridItem = ({ forwardedRef, ...props }) => (
  <GridItemWrapper ref={forwardedRef} {...props} />
);
