import React, { useState } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Icon } from '@mui/material';

import axios from '../../../axios';
import RangeSlider from '../../../components/common/RangeSlider';
import SwitchBtn from '../../../components/common/SwitchBtn';
import CustomSelect from '../../common/Select';
import { industryData, salesStagesData } from '../newDriveData';
import { getUserPlan, getSaasPlanDetails, getCompanyFeatures } from '../../../selectors/app';
import { validateCreateNewDrive } from '../../../utils/validations';
import {
    updateTrackerInfo as updateTrackerInfoRequest
} from '../../../actions/app'
import Edit from '../../../assets/Icons/Edit.png';
import InfoIcon from '../../../assets/info-roles.png';
import { checkCompanyFeatureAccess, checkUserPlanAccess } from '../../../utils/featureAccess';
import { useHistory } from 'react-router-dom';

const AddCustomAssessment = (props) => {
    const { goBack, openInterviewGuide, openVideoQuestionForm, updateTrackerInfo, addVideoCount, setAddVideoCount, videoQuestions, setShowCustomAssessmentEditBtn, customFieldsData, openCustomAssessmentForm, companyName, change, setChange, onClose, setSnackErr, handleSnackClick, driveRole, setDriveRole, userPlan, saasPlanDetails, setDefaultSettings, setWorkfunctionSelected, workfunctionOption, workfunctionSelected, jobRole, setJobRole, keyTask, finalVal, setKeyTask, setFinalVal, functionalSkill, setFunctionalSkill, showKeyTask, setShowKeyTask, salesStages, setSalesStages, coreSkill, setCoreSkill, name, desc, setName, setDesc, industry, setIndustry, workflowFixed, setWorkflowFixed, showCustomAssessmentEditBtn, companyFeatures, currentWalkthroughStep, setShowWalkthroughNextBtn, setNextWalkthroughStep, updateCurrentWalkthroughStep } = props;

    const history = useHistory();

    const onChangeName = (e) => {
        if (e.target.value.length > 3) {
            setNextWalkthroughStep(4);
            setShowWalkthroughNextBtn(true);
        }
        setName(e.target.value);
    }

    const onChangeDesc = (e) => {
        setDesc(e.target.value);
    }

    const onSubmitWorkflow = () => {
        updateCurrentWalkthroughStep(9);
        const errorResult = validateCreateNewDrive(name, desc);
        let weightageSum = 0;

        if (errorResult.isValid) {
            if (driveRole === 'Associate consultant' || driveRole === 'Consultant') {
                coreSkill.forEach((cs) => {
                    weightageSum += parseInt(cs.weightage, 10);
                });

                if (weightageSum === 100) {
                    sendWorkflow();
                } else {
                    setSnackErr('Sum of weightage is not equal to 100');
                    handleSnackClick();
                }
            } else {
                if (driveRole === '') {
                    setSnackErr('Enter valid role');
                    handleSnackClick();
                } else {
                    sendWorkflow();
                }
            }
        } else {
            Object.keys(errorResult.errors).forEach((item) => {
                setSnackErr(errorResult.errors[item]);
            });
            handleSnackClick();
        }
    }

    const sendWorkflow = () => {
        const uri = "/company/workflow/";
        const videoQsArr = [];
        videoQuestions.forEach((vq) => {
            if (vq.question) {
                videoQsArr.push(vq);
            }
        });

        if (videoQsArr.length === videoQuestions.length) {
            const getData = {
                workflowName: name,
                workflowDescription: desc,
                productId: {
                    role: driveRole,
                    workfunction: driveRole != "Associate consultant" && driveRole != "Consultant" ? workfunctionSelected : {},
                    functionalSkill: driveRole == "Associate consultant" || driveRole == "Consultant" ? functionalSkill : {},
                    coreSkill: driveRole == "Associate consultant" || driveRole == "Consultant" ? coreSkill : {},
                    keyTask: finalVal,
                    isMegacase: true,
                    isCustom: false,
                    videoQs: videoQsArr.length > 0 ? true : false,
                    workflowFixed: !workflowFixed,
                    freeTrial: true
                },
                companyName: companyName,
            };

            if (!checkCompanyFeatureAccess(companyFeatures, 'driveVariable')) {
                getData.productId.workflowFixed = false;
            }

            if (videoQsArr.length > 0) {
                getData.productId.videoQsArr = videoQsArr;
            }

            if (jobRole) {
                getData.productId.jobTitle = jobRole;
                getData.productId.stages = salesStages;
            }

            if (Object.keys(customFieldsData).length > 0) {
                getData.productId.customFields = customFieldsData;
            }

            if (driveRole === 'Project Manager') {
                getData.productId.industry = industry;
            }
            updateTrackerInfo({
                heading: "SUBMIT_DRIVE",
                rawRequest: JSON.stringify(getData),
                detail: currentWalkthroughStep > 0 ? "Walkthrough" : "Normal"
            })
            axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
                .then((res) => {
                    setChange(change + 1);
                    if (currentWalkthroughStep > 0) {
                        history.push({
                            pathname: "/workflows/" + res.data.name,
                            state: {
                                workflowId: res.data._id,
                                workflowName: res.data.name,
                                sidebar: "quickLook"
                            }
                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setSnackErr('Error in adding drive');
                    handleSnackClick();
                });

            onClose();
        } else {
            setSnackErr('Please add the video questions');
            handleSnackClick();
        }
    }

    const onChangeWorkflowFixed = (val) => {
        setWorkflowFixed(val);
    }

    const addKeyTask = function (role, workFunction, keyTask) {
        if (!(finalVal[role][workFunction].find((val) => val == keyTask))) {
            setFinalVal(
                {
                    [role]: {
                        ...finalVal[role],
                        [workFunction]: [...finalVal[role][workFunction], keyTask]
                    }
                }
            )
        }
    }

    const removeKeyTask = (role, workFunction, keyTask) => {
        const index = finalVal[role][workFunction].indexOf(keyTask);
        if (index != -1) {
            let arr = [...finalVal[role][workFunction]];
            arr.splice(index, 1);
            setFinalVal(
                {
                    [role]: {
                        ...finalVal[role],
                        [workFunction]: arr
                    }
                }
            )
        }
    }

    const onChangeWeightage = (e, val) => {
        const newCoreSkill = [...coreSkill];
        let index = 0;
        newCoreSkill.forEach((ncs, i) => {
            if (ncs.value === val.value) {
                index = i;
            }
        });
        newCoreSkill[index].weightage = parseInt(e.target.value, 10);
        setCoreSkill([...newCoreSkill]);
    }

    const removeCoreSkill = (val) => {
        const newCoreSkill = [...coreSkill];
        let index = 0;
        newCoreSkill.forEach((ncs, i) => {
            if (ncs.value === val.value) {
                index = i;
            }
        });

        newCoreSkill.splice(index, 1);
        setCoreSkill([...newCoreSkill]);
    }

    const onClickDefaultSettings = () => {
        setDefaultSettings();
        setKeyTask({});
        setFinalVal({})
        setFunctionalSkill([]);
        setCoreSkill([]);
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
                <div className='back-btn' onClick={goBack}>
                    <div></div>
                </div>
                <div className='title'>Create new drive</div>
            </div>
            <div style={{ zIndex: currentWalkthroughStep === 3 ? 1000 : 1, position: 'relative' }}>
                <input type="text" onChange={onChangeName} value={name} placeholder='Name of drive' />
            </div>
            <div style={{ zIndex: currentWalkthroughStep === 3 ? 1000 : 1, position: 'relative' }}>
                <input type="text" onChange={onChangeDesc} value={desc} placeholder='Add description' />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, justifyContent: 'space-between', zIndex: currentWalkthroughStep === 4 ? 1000 : 10, position: 'relative', background: '#fff', padding: '5px 0' }}>
                <div style={{ width: 210 }}>Select Role</div>
                <CustomSelect
                    selectedValue={driveRole ? driveRole : 'Select Role'}
                    onChangeSelect={(val) => {
                        setDriveRole(val.value);
                        setFinalVal({ [val.value]: {} })
                        setKeyTask({});
                        setWorkfunctionSelected([]);
                        setFunctionalSkill([]);
                        setJobRole('');
                        setShowKeyTask(false);
                        setCoreSkill([]);
                        updateTrackerInfo({
                            heading: "SELECT_ROLE",
                            rawRequest: JSON.stringify({ 'role': val.value }),
                            detail: currentWalkthroughStep > 0 ? "Walkthrough" : "Normal"
                        })
                    }}
                    dropdownData={[
                        // { label: 'Product Manager', value: 'Product Manager' },
                        { label: 'Project Manager', value: 'Project Manager' },
                        { label: 'Sales and Marketing', value: 'Sales and Marketing' },
                        // { label: 'Customer Success', value: 'Customer Success' },
                        // { label: 'Associate consultant', value: 'Associate consultant' },
                        { label: 'Consultant', value: 'Consultant' }
                    ]}
                    style={{
                        root: {
                            borderRadius: 30,
                            border: '1px solid #c4c4c4',
                            position: 'relative',
                            zIndex: 10,
                            width: 300,
                            flex: 10,
                            margin: '0 25px'
                        },
                        wrapper: {
                            borderBottom: 'none',
                            padding: '0 15px',
                            textAlign: 'left'
                        },
                        dropdown: {
                            zIndex: 1
                        }
                    }}
                />
                {/* {
                    driveRole ? (
                        <div style={{ marginRight: 25, cursor: 'pointer' }} onClick={openInterviewGuide}>
                            <img src={InfoIcon} alt='' style={{ width: 32 }} />
                        </div>
                    ) : ''
                } */}
                {
                    driveRole !== 'Sales and Marketing' ? (
                        <Button
                            style={{
                                backgroundColor: '#479BD2',
                                borderRadius: 16,
                                padding: '8px 20px',
                                color: '#fff',
                                cursor: 'pointer',
                                width: 175,
                                textAlign: 'center',
                                boxSizing: 'border-box',
                                fontFamily: 'inherit',
                                fontSize: 16,
                                whiteSpace: 'nowrap',
                                lineHeight: 1.2
                            }}
                            onClick={onClickDefaultSettings}
                            size="small"
                            variant="contained"
                        >Default Settings</Button>
                    ) : ''
                }
            </div>
            {
                driveRole === 'Sales and Marketing' ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, justifyContent: 'space-between', zIndex: currentWalkthroughStep === 4 ? 1000 : 9, position: 'relative', background: '#fff', padding: '5px 0' }}>
                        <div style={{ width: 210 }}>Job Role</div>
                        <CustomSelect
                            selectedValue={jobRole ? jobRole : 'Select Job Role'}
                            onChangeSelect={(val) => {
                                setJobRole(val.value);
                                setShowKeyTask(false);
                            }}
                            dropdownData={[
                                { label: 'SDR', value: 'SDR' },
                                { label: 'Account Executive', value: 'AE' },
                                { label: 'Marketing Manager', value: 'ME' },
                                { label: 'Custom', value: 'Custom' }
                            ]}
                            style={{
                                root: {
                                    borderRadius: 30,
                                    border: '1px solid #c4c4c4',
                                    position: 'relative',
                                    zIndex: 9,
                                    width: 300,
                                    flex: 3,
                                    margin: '0 25px'
                                },
                                wrapper: {
                                    borderBottom: 'none',
                                    padding: '0 15px',
                                    textAlign: 'left'
                                },
                                dropdown: {
                                    zIndex: 1
                                }
                            }}
                        />
                        {
                            jobRole !== 'Custom' ? (
                                <Button
                                    style={{
                                        backgroundColor: '#479BD2',
                                        borderRadius: 16,
                                        padding: '8px 20px',
                                        color: '#fff',
                                        cursor: 'pointer',
                                        width: 175,
                                        textAlign: 'center',
                                        boxSizing: 'border-box',
                                        fontSize: 16,
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'inherit',
                                        lineHeight: 1.2
                                    }}
                                    onClick={() => {
                                        setJobRole('');
                                        setKeyTask({});
                                        setFinalVal({})
                                        setWorkfunctionSelected([]);
                                        setFunctionalSkill([]);
                                        setCoreSkill([]);
                                    }}
                                    size="small"
                                    variant="contained"
                                >Default Settings</Button>
                            ) : ''
                        }
                    </div>
                ) : ''
            }
            <hr style={{ borderTop: '1px solid #c4c4c4', marginTop: 20 }} />
            {
                driveRole === 'Project Manager' ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, }}>
                        <div style={{ width: 210 }}>Select Industry</div>
                        <CustomSelect
                            selectedValue={industry ? industry : 'Select Industry'}
                            onChangeSelect={(val) => {
                                setIndustry(val.value);
                            }}
                            dropdownData={industryData}
                            style={{
                                root: {
                                    borderRadius: 30,
                                    border: '1px solid #c4c4c4',
                                    position: 'relative',
                                    zIndex: 8,
                                    width: 300,
                                    margin: '0 25px'
                                },
                                wrapper: {
                                    borderBottom: 'none',
                                    padding: '0 15px',
                                    textAlign: 'left'
                                },
                                dropdown: {
                                    zIndex: 1
                                }
                            }}
                        />
                    </div>
                ) : ''
            }
            {
                (driveRole && driveRole != "Associate consultant" && driveRole != "Consultant" && driveRole != "Sales and Marketing") || (jobRole && jobRole !== 'Custom') ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, position: 'relative', zIndex: currentWalkthroughStep === 5 ? 1000 : 7, background: '#fff', padding: '5px 0' }}>
                        <div style={{ width: 210 }}>Critical work functions</div>
                        <Select
                            className="add-wf-select remove-bg"
                            isMulti
                            options={workfunctionOption.length == 0 ? [] : workfunctionOption}
                            value={workfunctionSelected}
                            onChange={setWorkfunctionSelected}
                            style={{
                                control: () => ({ width: 300 })
                            }}
                        />
                        <Button
                            style={{
                                backgroundColor: '#479BD2',
                                borderRadius: 16,
                                padding: '8px 20px',
                                color: '#fff',
                                cursor: 'pointer',
                                fontFamily: 'inherit',
                                fontSize: 16,
                                whiteSpace: 'nowrap',
                                lineHeight: 1.2,
                                flexShrink: 0,
                                width: 175,
                                transition: 'all 0.3 ease-in-out',
                                transform: 'scale(1)'
                            }}
                            size="small"
                            variant="contained"
                            onClick={() => setShowKeyTask(!showKeyTask)}
                            id="specifyKeyTask"
                        >Specify Key Task</Button>
                    </div>
                ) : ''
            }
            {
                driveRole == "Associate consultant" || driveRole == "Consultant" ? (
                    <div style={{ zIndex: currentWalkthroughStep === 5 ? 1000 : 1, position: 'relative', background: '#fff', padding: '5px 0' }}>
                        <div className="workflow__newmodal-main workflow__newmodal-main-alignstart" style={{ marginTop: 25 }}>
                            <label className="workflow__newmodal-label">Functional skills</label>
                            <div className="workflow__newmodal-select workflow__newmodal-select-dropdown" style={{ position: 'relative', zIndex: 6 }}>
                                <Select
                                    isMulti
                                    id="specifyFunctionalSkill"
                                    options={[
                                        { value: 'Management consultant/generalist', label: 'Management consultant/generalist' },
                                        { value: 'Strategy', label: 'Strategy' },
                                        { value: 'Marketing /Sales /Pricing', label: 'Marketing /Sales /Pricing' },
                                        { value: 'Operations', label: 'Operations' },
                                        { value: 'Technology & Digital', label: 'Technology & Digital' },
                                        { value: 'Corporate Finance & Accounting', label: 'Corporate Finance & Accounting' },
                                        { value: 'People & Organization', label: 'People & Organization' },
                                    ]}
                                    value={functionalSkill}
                                    onChange={setFunctionalSkill}
                                    style={{
                                        control: styles => ({ ...styles, width: '380px' })
                                    }}
                                    maxMenuHeight={150}
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: 50 }}>
                            <label className="workflow__newmodal-label">Select core skills</label>
                            <div className="workflow__newmodal-select workflow__newmodal-select-dropdown" style={{ position: 'relative', zIndex: 5 }}>
                                <Select
                                    isMulti
                                    options={[
                                        { value: 'Structuring', label: 'Structuring' },
                                        { value: 'Insights', label: 'Insights' },
                                        { value: 'Synthesis', label: 'Synthesis' },
                                        { value: 'Quantitative', label: 'Quantitative' },
                                        { value: 'Framing', label: 'Framing' },
                                    ]}
                                    value={coreSkill}
                                    onChange={setCoreSkill}
                                    style={{
                                        control: styles => ({ ...styles, width: '380px' })
                                    }}
                                    maxMenuHeight={150}
                                />
                            </div>
                        </div>
                    </div>
                ) : ""
            }
            {
                jobRole === 'Custom' ? (
                    <div className="workflow__newmodal-main workflow__newmodal-main-alignstart" style={{ marginTop: 20 }}>
                        <label className="workflow__newmodal-label">Pick relevant stages of sales cycle</label>
                        <div className="workflow__newmodal-select workflow__newmodal-select-dropdown" style={{ position: 'relative', zIndex: 4 }}>
                            <Select
                                isMulti
                                options={salesStagesData}
                                value={salesStages}
                                onChange={setSalesStages}
                                style={{
                                    control: styles => ({ ...styles, width: '380px' })
                                }}
                                maxMenuHeight={150}
                            />
                        </div>
                    </div>
                ) : ''
            }
            <div style={{ position: 'relative', zIndex: currentWalkthroughStep === 5 ? 1000 : 1, background: '#fff' }}>
                {
                    showKeyTask && Object.keys(keyTask).length > 0 ?
                        Object.keys(keyTask).map((val, index) => (
                            <div key={index} style={{ marginTop: 20 }}>
                                <div style={{ display: 'flex', paddingLeft: 16, justifyContent: 'space-between', marginBottom: 20 }}>
                                    <p style={{ fontSize: 16, color: '#010101' }}>{val}</p>
                                </div>
                                <div style={{ backgroundColor: '#f8f8f8', padding: 16, borderRadius: 40, marginTop: 10 }}>
                                    <div style={{ fontSize: 14, color: '#595959' }}>De-select the ones that are not relevant to the role</div>
                                    {
                                        keyTask[val].map((value, i) =>
                                        (
                                            <div
                                                className={`key-task ${finalVal[driveRole][val].indexOf(value) > -1 ? 'key-task-activekey' : ''}`}
                                                key={i}
                                            >
                                                <div
                                                    onClick={(e) => {
                                                        addKeyTask(driveRole, val, value);
                                                    }}
                                                    style={{ cursor: finalVal[driveRole][val].indexOf(value) > -1 ? 'default' : 'pointer', display: 'flex', flex: 1, fontSize: 14 }}
                                                >
                                                    {value}
                                                </div>
                                                {
                                                    finalVal[driveRole][val].indexOf(value) > -1 ? (
                                                        <div
                                                            style={{ marginLeft: 10, fontSize: 14, cursor: 'pointer' }}
                                                            onClick={(e) => {
                                                                removeKeyTask(driveRole, val, value);
                                                            }}
                                                        >
                                                            x
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                        ) : ''
                }
            </div>
            {
                coreSkill.length > 0 ? (
                    <div style={{ background: '#f8f8f8', padding: '40px 50px', marginTop: 30, position: 'relative', zIndex: currentWalkthroughStep === 5 ? 1000 : 1 }}>
                        <div>
                            {
                                coreSkill.map((cs) => (
                                    <div style={{ display: 'flex', padding: '25px 0', alignItems: 'center' }} key={cs.label}>
                                        <div style={{ flex: 1 }}>{cs.label}</div>
                                        {
                                            checkUserPlanAccess(userPlan.name, saasPlanDetails, 'addWeightage') ? (
                                                <div style={{ flex: 2, justifyContent: 'center', display: 'flex' }}>
                                                    <RangeSlider
                                                        onChangeSlider={(e) => onChangeWeightage(e, cs)}
                                                        helperText="Add Weightage"
                                                        minValue={10}
                                                        maxValue={50}
                                                        rangeValue={cs.weightage}
                                                    />
                                                </div>
                                            ) : ''
                                        }
                                        <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex', cursor: 'pointer', color: '#858585' }} onClick={() => removeCoreSkill(cs)}>Remove</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ) : ''
            }
            <div id="walkthroughStep6" style={{ background: '#fff', 'position': 'relative', zIndex: currentWalkthroughStep === 6 ? 1000 : 2, padding: '5px 0' }}>

                <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
                    <label className="workflow__newmodal-label" style={{ width: 210 }}>Add custom video questions</label>
                    <CustomSelect
                        selectedValue={addVideoCount}
                        onChangeSelect={(val) => {
                            setAddVideoCount(val.value);
                        }}
                        dropdownData={[
                            { label: '0', value: 0 },
                            { label: '1', value: 1 },
                            { label: '2', value: 2 }
                        ]}
                        style={{
                            root: {
                                borderRadius: 30,
                                border: '1px solid #c4c4c4',
                                position: 'relative',
                                zIndex: 3,
                                width: 300,
                                flex: 1,
                                margin: '0 25px'
                            },
                            wrapper: {
                                borderBottom: 'none',
                                padding: '0 15px',
                                textAlign: 'left'
                            },
                            dropdown: {
                                zIndex: 1
                            }
                        }}
                    />
                    <Button
                        style={{
                            backgroundColor: '#479BD2',
                            borderRadius: 16,
                            padding: '8px 20px',
                            color: '#fff',
                            cursor: 'pointer',
                            width: 175,
                            textAlign: 'center',
                            boxSizing: 'border-box',
                            fontFamily: 'inherit',
                            fontSize: 16,
                            lineHeight: 1.2
                        }}
                        onClick={openVideoQuestionForm}
                        size="small"
                        variant="contained"
                    >
                        {videoQuestions.length > 0 && videoQuestions.length === addVideoCount ? 'Edit' : 'Add'}
                    </Button>
                </div>
            </div>
            <hr style={{ borderTop: '1px solid #c4c4c4', marginTop: 20 }} />
            <div id="walkthroughStep7" style={{ background: '#fff', padding: '5px 0', position: 'relative', zIndex: currentWalkthroughStep === 7 ? 1000 : 1 }}>
                <div style={{ display: 'flex', marginTop: 20, alignItems: 'center' }}>
                    <div style={{ width: 210, flexShrink: 0 }}>Variability</div>
                    <div style={{ margin: '0 25px', color: '#B6B6B6', fontSize: 14 }}>Make unique assessments for each candidate to avoid cheating</div>
                    <div style={{ width: 164, flexShrink: 0 }}>
                        <SwitchBtn onChangeSwitch={onChangeWorkflowFixed} activeBtn={workflowFixed} />
                    </div>
                </div>
            </div>

            {
                (driveRole === 'Project Manager' || driveRole === 'Product Manager') && checkUserPlanAccess(userPlan.name, saasPlanDetails, 'customContent') ? (
                    <div style={{ display: 'flex', marginTop: 20, alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ width: 210, flexShrink: 0 }}>Customise problem statement</div>
                        <div style={{ margin: '0 25px', color: '#B6B6B6', fontSize: 14 }}>Add custom fields to customize your assessment</div>
                        {Object.keys(customFieldsData).length > 0}
                        <SwitchBtn onChangeSwitch={(val) => setShowCustomAssessmentEditBtn(val)} activeBtn={showCustomAssessmentEditBtn} />
                        {
                            showCustomAssessmentEditBtn ? (
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25, cursor: 'pointer', width: 65, flexShrink: 0 }} onClick={openCustomAssessmentForm}>
                                    <Icon aria-label="edit"><img src={Edit} alt="" width={30} height={30} /></Icon>
                                    <div style={{ marginLeft: 10, color: '#B6B6B6' }}>Edit</div>
                                </div>
                            ) : <div style={{ marginLeft: 25, width: 65, flexShrink: 0 }}></div>
                        }
                    </div>
                ) : ''
            }
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50, position: 'relative', zIndex: currentWalkthroughStep === 8 ? 1000 : 1 }}>
                <Button className='action' onClick={onSubmitWorkflow} size="small" variant="contained" id="walkthroughStep7">Submit</Button>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    userPlan: getUserPlan(),
    saasPlanDetails: getSaasPlanDetails(),
    companyFeatures: getCompanyFeatures()
});

const mapDispatchToProps = (dispatch) => ({
    updateTrackerInfo: (data) => dispatch(updateTrackerInfoRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomAssessment);
