import React from 'react';

import FeatureLockedWhiteIcon from '../../assets/feature-locked-white.png';

import '../../styles/featureLockedWrapper.scss';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    updateTrackerInfo as updateTrackerInfoRequest
} from '../../actions/app'

const FeatureLockedWrapper = (props) => {
    const { updateTrackerInfo} = props;
    const goToSaas=(e)=>{
        console.log('clicked upgrade')
        updateTrackerInfo({
                        heading: "CLICK_UPGRADE_PAYMENT_WRAP",
                        rawRequest : JSON.stringify({}),
                        detail : ""
                    })
        const x = window.open('https://hirequotient.com/pricing/saas','_blank')

    }
    return (
        <div className='feature-locked-wrapper'>
            <div className='feature-locked-box'>
                <div className='feature-locked-img'>
                    <img src={FeatureLockedWhiteIcon} alt='feature locked white' />
                </div>
                <div className='feature-locked-text'>This feature is not available in the free trial. Upgrade your plan to enable it.</div>
                <div className="myLock" href='https://hirequotient.com/Skill-test-Saas-talent' onClick = {(e)=>goToSaas(e)}>Upgrade</div>
            </div>
        </div>
    );
}
const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
    updateTrackerInfo: (data) => dispatch(updateTrackerInfoRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureLockedWrapper);
