import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Alert, Button, IconButton, Snackbar } from '@mui/material';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataGrid } from '@mui/x-data-grid';
import { useHistory, useLocation } from 'react-router-dom';

import AccountMenu from '../pages/accountMenu/AccountMenu';
import AddPreScreeningModal from '../components/preScreeningBot/AddPreScreeningModal';
import DeletePreScreeningModal from '../components/preScreeningBot/DeletePreScreeningModal';
import EditPreScreeningBotNameModal from '../components/preScreeningBot/EditPreScreeningBotNameModal';
import NotificationBell from '../pages/notificationBell/notificationBell';
import { addPreScreeningBot as addPreScreeningBotRequest, editPreScreeningBot as editPreScreeningBotRequest, deletePreScreeningBot as deletePreScreeningBotRequest, fetchPreScreeningBots as fetchPreScreeningBotsRequest, sendPreScreeningTest as sendPreScreeningTestRequest } from '../actions/app';
import { getPreScreeningBots } from '../selectors/app';

import Edit from '../assets/Icons/Edit.png';
import emptyRowImg from '../assets/emptyrow2.png';
import Trash from '../assets/Icons/Trash.png';

import '../styles/preScreeningDashboard.scss';
import EditPreScreeningModal from '../components/preScreeningBot/EditPreScreeningModal';

const escapeRegExp = (value) => {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const PreScreeningDashboard = (props) => {
	const { notification, preScreeningBots, deletePreScreeningBot, addPreScreeningBot, fetchPreScreeningBots, editPreScreeningBot, sendPreScreeningTest } = props;

	const history = useHistory();
	const location = useLocation();

	const [searchText, setSearchText] = useState('');
	const [tableRows, setTableRows] = useState([]);
	const [selectionModel, setSelectionModel] = useState([]);
	const [errSnackOpen, setErrSnackOpen] = useState(false);
	const [snackErrMsg, setSnackErrMsg] = useState(null);
	const [successSnackOpen, setSuccessSnackOpen] = useState(false);
	const [snackSuccessMsg, setSnackSuccessMsg] = useState(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [editNameModal, setEditNameModal] = useState(false);
	const [pageSize, setPageSize] = useState(15);
	const [selectedRow, setSelectedRow] = useState([]);
	const [editName, setEditName] = useState('');
	const [addBotModal, setAddBotModal] = useState(false);
	const [editBotModal, setEditBotModal] = useState(false);

	useEffect(() => {
		fetchPreScreeningBots({ history });
	}, []);

	useEffect(() => {
		const newTableRows = [];
		preScreeningBots.forEach((psb, i) => {
			const preScreeningBotObj = { ...psb };
			preScreeningBotObj['id'] = i + 1;
			newTableRows.push(preScreeningBotObj);
		});
		
		setTableRows([...newTableRows]);
	}, [preScreeningBots]);

	useEffect(() => {
		console.log(selectionModel);
		if (selectionModel.length === 0) setSelectedRow([]);
		if (selectionModel.length === 1) {
			const editableRow = tableRows.filter((row) => selectionModel[0] === row.id);
			setSelectedRow([ ...editableRow ])
			setEditName(editableRow[0].name);
		} else {
			let temp = [];

			for (let i = 0; i < selectionModel.length; i++) {
				temp.push(...tableRows.filter((row) => selectionModel[i] === row.id))
			}

			setSelectedRow(temp);
		}
	}, [selectionModel]);

	const nameEdit = (row) => {
		const editNameData = {
			botId: row._id,
			name: editName,
			about: row.about,
			questionData: [ ...row.questionData ]
		};

		editPreScreeningBot({ botData: editNameData, history });
		setSnackSuccessMsg('Pre-Screening Bot Name edited Successfully')
		handleSuccessSnackClick();
	};

	const onOpenDeleteModal = () => {
		if (selectionModel.length === 0) {
			setSnackErrMsg('Please select atleast one candidate');
			handleErrSnackClick();
		} else setDeleteModal(true);
	};

	const onCloseDeleteModal = () => setDeleteModal(false);

	const onOpenEditNameModal = () => {
		if (selectionModel.length === 1) setEditNameModal(true);
		else {
			setSnackErrMsg('Please select atleast one candidate')
			handleErrSnackClick();
		}
	};

	const onCloseEditNameModal = () => setEditNameModal(false);

	const onOpenAddBotModal = () => {
		setAddBotModal(true);
	}

	const onCloseAddBotModal = () => {
		setAddBotModal(false);
	}

	const onOpenEditBotModal = (e, val) => {
		e.stopPropagation();
		setSelectedRow(val);
		setEditBotModal(true);
	}

	const onCloseEditBotModal = () => {
		setEditBotModal(false);
	}

	const handleDelete = (rowSelected) => {
		const IDs = [];
		rowSelected.forEach((val) => {
			IDs.push(val._id);
		})
		deletePreScreeningBot({ id: IDs, history});
		setSnackSuccessMsg('Pre-Screening Bot deleted Successfully')
		handleSuccessSnackClick();
	};

	const handleErrSnackClick = () => {
		setErrSnackOpen(true);
	};

	const handleErrSnackClose = () => {
		setErrSnackOpen(false);
	};

	const handleSuccessSnackClick = () => {
		setSuccessSnackOpen(true);
	};

	const handleSuccessSnackClose = () => {
		setSuccessSnackOpen(false);
	};

	const NoRowsOverlay = () => {
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					textAlign: "center"
				}}
			>
				<img
					src={emptyRowImg}
					alt="no rows"
					style={{
						height: "350px",
						width: "auto"
					}}
				/>
				<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
			</div>
		)
	}

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);

		if (searchValue === '') {
			setTableRows(preScreeningBots);
		} else {
			const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
			const filteredRows = preScreeningBots.filter((row) => {
				return Object.keys(row).some((field) => {
					return searchRegex.test(row[field].toString());
				});
			});

			setTableRows(filteredRows);
		}
	};

	const onClickTest = (e, val) => {
		e.stopPropagation();
		const botData = {
			botId: val._id
		};

		sendPreScreeningTest({ botData, history });
		setSnackSuccessMsg('Mail with test link sent successfully');
		handleSuccessSnackClick();
	}

	const columns = [
		// {
		// 	field: "id",
		// 	headerName: "Sr. No",
		// 	flex: 1,
		// 	renderCell: (params) => {
		// 		return (
		// 			<div style={{ marginRight: 25 }}>
		// 				{params.row.id}
		// 			</div>
		// 		)
		// 	}
		// },
		{
			field: "name",
			headerName: "Name of Pre-screening",
			flex: 1.5,
			renderCell: (params) => {
				return (
					<div style={{ fontSize: 16, fontWeight: "400" }}>
						{params.row.name}
					</div>
				);
			},
		},
		{
			field: "createdBy",
			headerName: "Created by",
			flex: 0.7,
			renderCell: (params) => {
				return (
					<div style={{ fontSize: 16, fontWeight: "400" }}>
						{params.row.createdBy ? params.row.createdBy.name : ''}
					</div>
				);
			},
		},
		{
			field: "createdDate",
			headerName: "Date created",
			flex: 0.9,
			renderCell: (params) => {
				return (
					<div style={{ fontSize: 16, fontWeight: "400" }}>
						{moment(params.row.createdDate).format('L')}
					</div>
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			flex: 1,
			renderCell: (params) => {
				return (
					<div style={{ justifyContent: 'center' }}>
						<Button size="small" variant="contained" style={{ backgroundColor: '#479BD2', padding: '4px 25px', color: '#fff', boxShadow: '0px 4px 10px 0px #109CF13D', fontSize: 14, fontWeight: '600', borderRadius: 15, cursor: 'pointer', lineHeight: '20px', fontFamily: 'inherit' }} onClick={(e) => onOpenEditBotModal(e, params.row)}>Edit</Button>
						<Button size="small" variant="contained" style={{ backgroundColor: '#479BD2', padding: '4px 25px', color: '#fff', boxShadow: '0px 4px 10px 0px #109CF13D', fontSize: 14, fontWeight: '600', borderRadius: 15, cursor: 'pointer', lineHeight: '20px', fontFamily: 'inherit', marginLeft: 15 }} onClick={(e) => onClickTest(e, params.row)}>Test</Button>
					</div>
				);
			},
		}
	]

	return (
		<div className='pre-screening-dashboard'>
			<div className="inner-div" style={{ marginBottom: 15 }}>
				<div className='top-icon-box'>
					<IconButton aria-label="edit" onClick={onOpenEditNameModal} id="reviewApplicantsFlow-selector-3">
						<img src={Edit} alt="" width={30} height={30} />
					</IconButton>
					<IconButton onClick={onOpenDeleteModal} aria-label="delete">
						<img src={Trash} alt="" width={30} height={30} />
					</IconButton>
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div>
			</div>
			<div className='wrapper'>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<div>
						<div style={{ color: '#334d6e', fontWeight: '700', fontSize: 18 }}>Pre-screening Stage</div>
						<div style={{ color: '#90A0B7', fontSize: 15, fontWeight: '600' }}>Make your own pre-screening tests</div>
					</div>
					<div style={{ display: 'flex' }}>
						{/* <div style={{ marginRight: 20, backgroundColor: '#479BD2', padding: '8px 32px', color: '#fff', boxShadow: '0px 4px 10px 0px #109CF13D', fontSize: 14, fontWeight: '600', borderRadius: 15 }}>Chatbot Bot</div> */}
						<Button size="small" variant="contained" style={{ backgroundColor: '#479BD2', padding: '8px 32px', color: '#fff', boxShadow: '0px 4px 10px 0px #109CF13D', fontSize: 14, fontWeight: '600', borderRadius: 15, cursor: 'pointer', fontFamily: 'inherit' }} onClick={onOpenAddBotModal}>Add New</Button>
					</div>
				</div>
				<div style={{ height: '100%', padding: 10, background: '#fff', borderRadius: 25, overflow: 'hidden', flex: 1, marginTop: 10 }}>
					<DataGrid
						rows={tableRows}
						components={{ NoRowsOverlay }}
						columns={columns}
						checkboxSelection={true}
						componentsProps={{
							toolbar: {
								value: searchText,
								onChange: (event) => requestSearch(event.target.value),
								clearSearch: () => requestSearch(''),
							},
						}}
						onSelectionModelChange={(newSelectionModel) => {
							setSelectionModel(newSelectionModel);
						}}
						selectionModel={selectionModel}
						autoHeight
						pageSize={pageSize}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						rowsPerPageOptions={[5, 10, 15]}
						style={{ marginBottom: "2vh", border: "none" }}
						// loading={loading}
						className="dataGridScroll"
					/>
				</div>
			</div>
			<DeletePreScreeningModal
				deleteModal={deleteModal}
				onCloseDeleteModal={onCloseDeleteModal}
				handleDelete={handleDelete}
				selectedRow={selectedRow}
			/>
			<EditPreScreeningBotNameModal
				editNameModal={editNameModal}
				onCloseEditNameModal={onCloseEditNameModal}
				selectedRow={selectedRow}
				setEditName={setEditName}
				editName={editName}
				nameEdit={nameEdit}
			/>
			<AddPreScreeningModal
				addBotModal={addBotModal}
				onCloseAddBotModal={onCloseAddBotModal}
				addPreScreeningBot={addPreScreeningBot}
				setSnackSuccessMsg={setSnackSuccessMsg}
				handleSuccessSnackClick={handleSuccessSnackClick}
				setSnackErrMsg={setSnackErrMsg}
				handleErrSnackClick={handleErrSnackClick}
			/>
			<EditPreScreeningModal
				editBotModal={editBotModal}
				onCloseEditBotModal={onCloseEditBotModal}
				editPreScreeningBot={editPreScreeningBot}
				setSnackSuccessMsg={setSnackSuccessMsg}
				handleSuccessSnackClick={handleSuccessSnackClick}
				selectedRow={selectedRow}
				setSnackErrMsg={setSnackErrMsg}
				handleErrSnackClick={handleErrSnackClick}
			/>
			<Snackbar open={errSnackOpen} autoHideDuration={2500} onClose={handleErrSnackClose}>
				<Alert onClose={handleErrSnackClose} variant="filled" severity="error" sx={{ width: '100%' }}>
					{snackErrMsg ? snackErrMsg : ''}
				</Alert>
			</Snackbar>
			<Snackbar open={successSnackOpen} autoHideDuration={2500} onClose={handleSuccessSnackClose}>
				<Alert onClose={handleSuccessSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					{snackSuccessMsg ? snackSuccessMsg : ''}
				</Alert>
			</Snackbar>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	preScreeningBots: getPreScreeningBots()
});

const mapDispatchToProps = (dispatch) => ({
	deletePreScreeningBot: (data) => dispatch(deletePreScreeningBotRequest(data)),
	addPreScreeningBot: (data) => dispatch(addPreScreeningBotRequest(data)),
	editPreScreeningBot: (data) => dispatch(editPreScreeningBotRequest(data)),
	fetchPreScreeningBots: (data) => dispatch(fetchPreScreeningBotsRequest(data)),
	sendPreScreeningTest: (data) => dispatch(sendPreScreeningTestRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PreScreeningDashboard);
