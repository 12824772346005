import { Button } from '@mui/material';
import React from 'react';
import Modal from 'react-responsive-modal';

const DeletePreScreeningModal = (props) => {
	const { deleteModal, onCloseDeleteModal, handleDelete, selectedRow } = props;

	return (
		<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
			<p className="modal-text">
				Do you want to delete this bot?
			</p>
			<div className="button-div">
				<Button
					className="modal-button"
					style={{
						background: '#479BD2',
						fontWeight: 'bold',
						fontFamily: "Poppins",
						letterSpacing: "0.01em",
						borderRadius: '25px',
						padding: "6px 20px",
						fontSize: '16px'
					}}
					size="small"
					variant="contained"
					onClick={() => {
						onCloseDeleteModal()
						handleDelete(selectedRow)
					}}>
					Yes
				</Button>
				<Button
					className="cancel-modal-button"
					style={{
						background: '#479BD2',
						fontWeight: 'bold',
						fontFamily: "Poppins",
						letterSpacing: "0.01em",
						borderRadius: '25px',
						padding: "6px 20px",
						fontSize: '16px'
					}}
					size="small"
					variant="contained"
					onClick={() => {
						onCloseDeleteModal()
					}}>
					No
				</Button>
			</div>
		</Modal>
	)
}

export default DeletePreScreeningModal;
