import React, { useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AccountMenu from '../pages/accountMenu/AccountMenu';
import BillingAndPurchases from '../components/myAccount/BillingAndPurchases';
import ChangePassword from '../components/myAccount/ChangePassword';
import MyDetails from '../components/myAccount/MyDetails';
import NotificationBell from '../pages/notificationBell/notificationBell';
import { getCompanyFeatures, getUserPlan } from '../selectors/app';

import FeatureLockedIcon from '../assets/feature-locked.png';

import '../styles/myAccount.scss';
import { checkCompanyFeatureAccess } from '../utils/featureAccess';
import FeatureLockedModal from '../components/common/FeatureLockedModal';

const MyAccount = (props) => {
	const { notification, userPlan, companyFeatures } = props;

	const [currentTab, setCurrentTab] = useState(0);
	const [errorAlert, setErrorAlert] = useState(false);
	const [errorAlertMsg, setErrorAlertMsg] = useState('');
	const [successAlert, setSuccessAlert] = useState(false);
	const [successAlertMsg, setSuccessAlertMsg] = useState('');
	const [featureLockedModal, setFeatureLockedModal] = useState(false);

	const onErrorAlertOpen = () => {
		setErrorAlert(true);
	}

	const onErrorAlertClose = () => {
		setErrorAlertMsg('');
		setErrorAlert(false);
	}

	const onSuccessAlertOpen = () => {
		setSuccessAlert(true);
	}

	const onSuccessAlertClose = () => {
		setSuccessAlertMsg('');
		setSuccessAlert(false);
	}

	const onClickChangePwdTab = () => {
		if(checkCompanyFeatureAccess(companyFeatures, 'changePassword') === 1) {
			setFeatureLockedModal(true);
		} else {
			setCurrentTab(1);
		}
	}

	return (
		<div id="myAccount">
			<div className="inner-div" style={{ marginBottom: 15 }}>
				<div className='top-icon-box'>
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div>
			</div>
			<div style={{ marginTop: 25, color: '#334d6e', fontWeight: '700', fontSize: 18 }}>My Account</div>
			<div className='wrapper'>
				<div className='myaccount-tabs'>
					<div style={{ paddingLeft: 20 }} className={`${currentTab === 0 ? 'active' : ''}`} onClick={() => setCurrentTab(0)}>My details</div>
					<div style={{ padding: '0 60px', display: 'flex', alignItems: 'center' }} className={`${currentTab === 1 ? 'active' : ''}`} onClick={onClickChangePwdTab}>
						<div>Change password</div>
						{
							checkCompanyFeatureAccess(companyFeatures, 'ccList') === 1 ? (
								<div style={{ marginLeft: 15 }}>
									<img style={{ width: 16 }} src={FeatureLockedIcon} alt='locked feature' />
								</div>
							) : ''
						}
					</div>
					{
						userPlan.name ? (
							<div className={`${currentTab === 2 ? 'active' : ''}`} onClick={() => setCurrentTab(2)}>Billing &amp; purchase</div>
						) : ''
					}
				</div>
				{
					currentTab === 0 ? (
						<MyDetails
							onErrorAlertOpen={onErrorAlertOpen}
							setErrorAlertMsg={setErrorAlertMsg}
							onSuccessAlertOpen={onSuccessAlertOpen}
							setSuccessAlertMsg={setSuccessAlertMsg}
						/>
					) : ''
				}
				{
					currentTab === 1 ? (
						<ChangePassword
							onErrorAlertOpen={onErrorAlertOpen}
							setErrorAlertMsg={setErrorAlertMsg}
							onSuccessAlertOpen={onSuccessAlertOpen}
							setSuccessAlertMsg={setSuccessAlertMsg}
						/>
					) : ''
				}
				{
					currentTab === 2 ? (
						<BillingAndPurchases
							onSuccessAlertOpen={onSuccessAlertOpen}
							setSuccessAlertMsg={setSuccessAlertMsg}
						/>
					) : ''
				}
			</div>
			<FeatureLockedModal
				modalOpen={featureLockedModal}
				onClose={() => setFeatureLockedModal(false)}
			/>
			<Snackbar open={errorAlert} autoHideDuration={2500} onClose={onErrorAlertClose}>
				<Alert onClose={onErrorAlertClose} variant="filled" severity="error" sx={{ width: '100%' }}>
					{errorAlertMsg}
				</Alert>
			</Snackbar>
			<Snackbar open={successAlert} autoHideDuration={2500} onClose={onSuccessAlertClose}>
				<Alert onClose={onSuccessAlertClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					{successAlertMsg}
				</Alert>
			</Snackbar>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	userPlan: getUserPlan(),
	companyFeatures: getCompanyFeatures()
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
