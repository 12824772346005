import React from 'react';

const MyMessage = (props) => {
	const { messageData, candidateName } = props;

	return (
		<div style={{ display: 'flex', marginTop: 20, width: '70%', justifyContent: 'flex-end', marginLeft: 'auto' }}>
			<div>
				<div className="ps-mymsg">{messageData.content}</div>
			</div>
			<div style={{ marginLeft: 15 }}>
				<div style={{ fontSize: 20, fontWeight: '700', height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 40, color: '#fff', background: '#8CD55F' }}>{candidateName.substring(0, 1).toUpperCase()}</div>
			</div>
		</div>
	)
}

export default MyMessage;
