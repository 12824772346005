import React, { useEffect, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import emptyRowImg from '../../assets/emptyrow1.png';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getRerender, getSuperUserList } from '../../selectors/app';
import {
	resetRerender as resetRerenderRequest,
	getSuperUser as getSuperUserRequest,
	addSuperUser as addSuperUserRequest,
	deleteSuperUser as deleteSuperUserRequest,
} from '../../actions/app';

function SuperUser(props) {
	const {
		rerender,
		resetRerender,
		superUserList,
		getSuperUser,
		addSuperUser,
		deleteSuperUser
	} = props;

	const history = useHistory();
	const location = {
		state: {
			role: "super-admin"
		}
	}
	const [newModal, setNewModal] = useState(false)
	const onOpenNewModal = () => setNewModal(true);
	const onCloseNewModal = () => setNewModal(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const onOpenDeleteModal = () => setDeleteModal(true);
	const onCloseDeleteModal = () => {
		setUserToBeDeleted([]);
		setDeleteModal(false);
	}

	const [username, setUsername] = useState("");
	const [userRole, setUserRole] = useState("");
	const [pageSize, setPageSize] = useState(15);
	const [change, setChange] = useState(0);
	const [userToBeDeleted, setUserToBeDeleted] = useState([]);

	useEffect(() => {
		if (location.state.role != "super-admin") {
			history.push("/invalid");
		}
	}, [change]);

	useEffect(() => {
		if (!rerender) return;

		setChange(change + 1);
		resetRerender();

		if (newModal) onCloseNewModal();
		if (deleteModal) onCloseDeleteModal();

	}, [rerender]);

	useEffect(() => {
		getSuperUser();
	}, [change])

	useEffect(() => {
		if (userToBeDeleted.length == 0) return;

		onOpenDeleteModal();
	}, [userToBeDeleted]);


	const columns = [
		{
			field: "firstName",
			headerName: "First Name",
			headerAlign: "left",
			flex: 1
		},
		{
			field: "lastName",
			headerName: "Last Name",
			headerAlign: "left",
			flex: 1
		},
		{
			field: "email",
			headerName: "Email",
			headerAlign: "left",
			minWidth: 200
		},
		{
			field: "role",
			headerName: "Role",
			headerAlign: "left",
			width: 120,
		},
		{
			field: "action",
			headerName: "Actions",
			minWidth: 140,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<div className="datagrid__row datagrid__row-center">
						<Button
							className="btn-datagrid btn-danger"
							size="small"
							variant="contained"
							onClick={() => setUserToBeDeleted([params.row])}
						>
							Delete
						</Button>
					</div>
				)
			}
		},
	]

	function sendBots() {
		addSuperUser({ username: username });
	}

	function handleDelete(id) {
		deleteSuperUser({ _id: id });
	}

	function renderDate(date) {
		let createdDate = new Date(date);
		return (
			<>{createdDate.toLocaleString()}</>
		)
	}

	function NoRowsOverlay() {
		return (
			<div className="norowsoverlay__container">
				<img
					src={emptyRowImg}
					alt="no rows"
					className="norowsoverlay__img"
				/>
				<p className="norowsoverlay__text">Nothing to show</p>
			</div>
		)
	}

	return (
		<div className="mainbar" style={{ display: "flex", flexDirection: "column", marginLeft: "20vw", width: "80vw", minHeight: "100vh" }}>
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center classNames={{ modal: 'modal__container', closeIcon: 'modal__closeicon' }}>
				<div className="modal__heading">
					Do you want to delete this user?
				</div>
				{
					userToBeDeleted.length != 0 ?
						Object.keys(userToBeDeleted[0]).map(key => (
							<>
								<div>
									<span><b>{key} : </b></span>
									<span>{key == "createdDate" ? renderDate(userToBeDeleted[0][key]) : userToBeDeleted[0][key]}</span>
								</div><br />
							</>
						))
						:
						""
				}
				<div className="modal__btnbox">
					<Button
						className="btn-primary btn-danger"
						size="small"
						variant="contained"
						disabled={userToBeDeleted.length == 0}
						onClick={() => {
							handleDelete(userToBeDeleted[0]);
						}}>
						Ok
					</Button>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseDeleteModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>

			<Modal open={newModal} onClose={onCloseNewModal} classNames={{ modal: 'modal__container', closeIcon: 'modal__closeicon' }}>
				<div className="modal__heading">
					Create new User
				</div>
				<div className="modal__inputbox">
					<input type="text" placeholder="Username" value={username} onChange={(e) => { setUsername(e.target.value) }} /><br />
					<FormControl className="newapplicants__mail-form newapplicants__mail-timerbox-input">
						<InputLabel id="user-role">User Role</InputLabel>
						<Select
							className="remove-bg"
							labelId="user-role"
							label="User Role"
							value={userRole}
							onChange={(e) => { setUserRole(e.target.value) }}
						>
							<MenuItem value={"member"} selected>Member</MenuItem>
							<MenuItem value={"admin"}>Admin</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div className="modal__btnbox">
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={()=>sendBots()}
					>
						Create
					</Button>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseNewModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>

			<div className="notificationbar">
				<button className="btn-primary" onClick={onOpenNewModal}>Create new super user</button>
			</div>
			<div className="actionbar">

			</div>

			<div className="datagrid__container">
				<DataGrid
					sortingOrder={['desc', 'asc']}
					components={{ NoRowsOverlay }}
					getRowId={(row) => row._id}
					rows={superUserList}
					disableSelectionOnClick
					columns={columns}
					autoHeight
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[5, 10, 15]}
					className="datagrid__scroll scroll-blue"
				/>
			</div>

		</div>
	);
}


const mapStateToProps = createStructuredSelector({
	rerender: getRerender(),
	superUserList: getSuperUserList()
});

const mapDispatchToProps = (dispatch) => {
	return {
		resetRerender: () => dispatch(resetRerenderRequest()),
		addSuperUser: (data) => dispatch(addSuperUserRequest(data)),
		deleteSuperUser: (data) => dispatch(deleteSuperUserRequest(data)),
		getSuperUser: () => dispatch(getSuperUserRequest()),
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperUser);