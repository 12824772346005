import { takeLatest, all, put } from 'redux-saga/effects';

import axios from "../axios";
import {
    SET_CONVO,
    GET_CANDIDATES_BY_TYPE,
    SET_CANDIDATES,
    SET_SUCCESS_MSG,
    EDIT_CANDIDATE_NAME,
    DELETE_CANDIDATE,
    EDIT_DRIVE_NAME,
    USER_SIGN_IN,
    AUTH_LOADING,
    IS_AUTHENTICATED,
    AUTH_ERROR_MSG,
    FETCH_NOTIFICATIONS,
    SET_NOTIFICATIONS,
    UPDATE_NOTIFICATION,
    SETTINGS_LOADING,
    FREE_USER_SIGN_IN,
    VERIFY_USER,
    SET_USER_VERIFICATION_LOADING,
    SET_SHOW_VERIFICATION_SUCCESS,
    SEND_FORGOT_PASSWORD_LINK,
    RESET_PASSWORD,
    SET_PASSWORD,
    SUBMIT_ASSESSMENT,
    FETCH_ASSESSMENT_HISTORY,
    SET_ASSESSMENT_HISTORY,
    USER_LOGOUT,
    FREE_USER_SIGN_UP,
    AUTH_SUCCESS_MSG,
    GET_DRIVE_DETAILS,
    SET_DRIVE_DETAILS,
    ADD_USER,
    ADD_COMPANY,
    DELETE_COMPANY,
    GET_COMPANIES,
    SET_COMPANIES,
    ADD_BOTS,
    DELETE_BOTS,
    GET_SUPERUSER,
    SET_SUPERUSER,
    ADD_SUPERUSER,
    DELETE_SUPERUSER,
    GET_FEEDBACK,
    SET_FEEDBACK,
    FETCH_MY_DETAILS,
    SET_MY_DETAILS,
    UPDATE_MY_DETAILS,
    CHANGE_MY_PASSWORD,
    ADD_PRE_SCREENING_BOT,
    DELETE_PRE_SCREENING_BOT,
    FETCH_PRE_SCREENING_BOTS,
    SET_PRE_SCREENING_BOTS,
    FETCH_PRE_SCREENING_CHAT,
    SET_PRE_SCREENING_CHAT,
    SET_CURRENT_BOT_QUESTION,
    VERIFY_LINK_TOKEN,
    FETCH_SAAS_PLAN_LIST,
    SET_SAAS_PLAN_LIST,
    FETCH_SAAS_PLAN_DETAILS,
    SET_SAAS_PLAN_DETAILS,
    UPDATE_SAAS_PLAN_DETAILS,
    EDIT_PRE_SCREENING_BOT,
    UPDATE_ASSESSMENT_BOT_LOGO,
    UPDATE_DASHBOARD_LOGO,
    SET_DASHBOARD_LOGO,
    CREATE_WORKFLOW_JOB_ROLE,
    SET_JOB_ROLE_DETAILS,
    SET_CREATE_JOB_LOADING,
    SEND_PRE_SCREENING_TEST,
    FETCH_TRIAL_USERS_LIST,
    SET_TRIAL_USERS_LIST,
    SEND_UPDATE_ALL,
    FETCH_COMPANY_DETAILS,
    SET_COMPANY_DETAILS,
    UPDATE_COMPANY_FEATURES,
    SET_COMPANY_BOTS,
    FETCH_BOTS,
    FETCH_COMPANY_BOTS,
    UPDATE_BOT_DETAILS,
    UPDATE_COMPANY_DETAILS,
    UPDATE_COMPANY_GUIDELINES,
    FETCH_COMPANIES,
    UPDATE_TRACKER_INFO,
    VERIFY_FREE_USER

} from '../constants/app';
import { redirectUnauthorisedUser } from '../helper/helperFunction';

function* fetchBots(action) {
    const { data } = action;

    try {
        let response = yield axios.get('/bot/all');
        yield put({ type: SET_CONVO, data: response.data });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* fetchCompanyBots(action) {
    const { data } = action;

    try {
        let response = yield axios.get('/company/bots');
        yield put({ type: SET_COMPANY_BOTS, data: response.data });
    } catch (e) {
        console.log(e);
        if(data && data.history) {
            redirectUnauthorisedUser(e, data.history);
        }
    }
}

function* getCandidatesByType(action) {
    const { reqData, history, pageType } = action.data;

    yield put({ type: SETTINGS_LOADING, data: true });

    try {
        let response = yield axios.post('/gettype', reqData);
        yield put({ type: SET_CANDIDATES, data: response.data });

        if(pageType === 'settings') {
            yield put({ type: SETTINGS_LOADING, data: false });
        }
    } catch (e) {
        console.log(e);

        yield put({ type: SETTINGS_LOADING, data: false });
        redirectUnauthorisedUser(e, history);
    }
}

function* editCandidateName(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/update-candidate', { ...data.row, name: data.newName });
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "Name edited successfully" });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* deleteCandidate(action) {
    const { data } = action;
    
    try {
        let response = yield axios.post('/delete-candidate', { id: data.id });
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: `Deleted ${response.data.deletedCount} candidates successfully` });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* editDriveName(action) {
    const { data } = action;

    try {
        let response = yield axios.put('/workflow/' + data.row.workflowId, { ...data.row, name: data.newName });
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "Name edited successfully" });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* userSignIn(action) {
    const { userData, history } = action.data;

    yield put({ type: AUTH_LOADING, data: true });

    try {
        let response = yield axios.post('/auth/login/', userData);
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.accessToken);
        localStorage.setItem('company', response.data.message.company);
        localStorage.setItem('userName', response.data.message.name);
        localStorage.setItem('email', response.data.message.email);
        localStorage.setItem('userRole', response.data.message.role);
        localStorage.setItem('companyFeatures', JSON.stringify(response.data.message.companyFeatures));
        if(response.data.message.plan) {
            localStorage.setItem('userPlan', JSON.stringify(response.data.message.plan));
        }
        localStorage.setItem('totalAssessments', response.data.message.totalAssessments);

        yield put({ type: AUTH_LOADING, data: false });
        yield put({ type: IS_AUTHENTICATED, data: true });
        yield put({ type: ADD_USER, data: {
            userName: response.data.message.name,
            userRole: response.data.message.role,
            userPlan: response.data.message.plan ? response.data.message.plan : {},
            userEmail: response.data.message.email,
            totalAssessments: response.data.message.totalAssessments,
            companyFeatures: response.data.message.companyFeatures
        } });
        yield put({ type: IS_AUTHENTICATED, data: true });

        if (response.data.message.role === "Super Admin")
            history.push('/companies');
        else
            history.push('/workflows');
    } catch (error) {
        console.log(error);
        console.log(error.response);
        yield put({ type: AUTH_LOADING, data: false });
        yield put({ type: IS_AUTHENTICATED, data: false });
        yield put({ type: AUTH_ERROR_MSG, data: error.response.data.message });
    }
}

function* fetchNotifications(action) {
    const { data } = action;

    try {
        let response = yield axios.get('/notification/all/');
        yield put({ type: SET_NOTIFICATIONS, data: response.data });
    } catch (error) {
        console.log(error);
        redirectUnauthorisedUser(error, data.history);
    }
}

function* updateNotification(action) {
    const { notificationData, notifications, history } = action.data;

    try {
        yield axios.post('/notification/edit/', notificationData);

        const newNotificationList = [...notifications];
		newNotificationList.forEach((item) => {
			if(item.notificationId === notificationData.notificationId) {
				item.status = 'Read';
				return;
			}
		});

        yield put({ type: SET_NOTIFICATIONS, data: newNotificationList });
    } catch(error) {
        console.log(error);
        redirectUnauthorisedUser(error, history);
    }
}

function* freeUserSignIn(action) {
    const { userData, history } = action.data;

    yield put({ type: AUTH_LOADING, data: true });

    try {
        let response = yield axios.post('/auth/free/login/', userData);

        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.accessToken);
        localStorage.setItem('email', response.data.message.email);

        yield put({ type: AUTH_LOADING, data: false });

        history.push('/free/create-assessment');
    } catch (error) {
        yield put({ type: AUTH_LOADING, data: false });
        yield put({ type: AUTH_ERROR_MSG, data: error.response.data.message });
    }
}

function* freeUserSignUp(action) {
    const { data } = action;

    yield put({ type: AUTH_LOADING, data: true });

    try {
        let response = yield axios.post('/auth/free/signup/', data);

        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.accessToken);
        localStorage.setItem('email', response.data.message.email);

        yield put({ type: AUTH_LOADING, data: false });
        yield put({ type: AUTH_SUCCESS_MSG, data: 'We have sent an email to your email id for verificiation. Validate and get started' })
    } catch (error) {
        console.log(error);
        console.log(error.response);
        yield put({ type: AUTH_LOADING, data: false });
        yield put({ type: AUTH_ERROR_MSG, data: error.response.data.message });
    }
}

function* verifyUser(action) {
    const { data } = action;

    yield put({ type: SET_USER_VERIFICATION_LOADING, data: true });

    try {
        let response = yield axios.post('/auth/verify/', data);

        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.accessToken);
        localStorage.setItem('email', response.data.message.email);
        localStorage.removeItem('couponCode');

        yield put({ type: SET_USER_VERIFICATION_LOADING, data: false });
        yield put({ type: SET_SHOW_VERIFICATION_SUCCESS, data: true });
    } catch (error) {
        console.log(error);
        console.log(error.response);
        yield put({ type: SET_USER_VERIFICATION_LOADING, data: false });
        yield put({ type: SET_SHOW_VERIFICATION_SUCCESS, data: false });
    }
}

function* verifyFreeUser (action){
    const { userData, history } = action.data;

    yield put({ type: AUTH_LOADING, data: true });

    try {
        let response = yield axios.post('/trial-user/verify-otp', userData);

        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.accessToken);
        localStorage.setItem('company', response.data.message.company);
        localStorage.setItem('userName', response.data.message.name);
        localStorage.setItem('email', response.data.message.email);
        localStorage.setItem('userRole', response.data.message.role);
        localStorage.setItem('companyFeatures', JSON.stringify(response.data.message.companyFeatures));
        if(response.data.message.plan) {
            localStorage.setItem('userPlan', JSON.stringify(response.data.message.plan));
        }
        localStorage.setItem('totalAssessments', response.data.message.totalAssessments);

        yield put({ type: AUTH_LOADING, data: false });
        yield put({ type: IS_AUTHENTICATED, data: true });
        yield put({ type: ADD_USER, data: {
            userName: response.data.message.name,
            userRole: response.data.message.role,
            userPlan: response.data.message.plan ? response.data.message.plan : {},
            userEmail: response.data.message.email,
            totalAssessments: response.data.message.totalAssessments,
            companyFeatures: response.data.message.companyFeatures
        } });
        yield put({ type: IS_AUTHENTICATED, data: true });

        history.push('/workflows');
    } catch (error) {
        console.log(error);
        console.log(error.response);
        yield put({ type: AUTH_LOADING, data: false });
        yield put({ type: IS_AUTHENTICATED, data: false });
        yield put({ type: AUTH_ERROR_MSG, data: error.response.data.message });
    }

}

function* sendForgotPasswordLink(action) {
    const { data } = action;

    try {
        yield axios.post('/auth/forgot-password/', data);
    } catch (error) {
        console.log(error);
        console.log(error.response);
    }
}

function* resetPassword(action) {
    const { userData, history } = action.data;

    try {
        yield axios.post('/auth/reset-password/' + userData.token, userData);

        history.push('/free/signin');
    } catch (error) {
        console.log(error);
        console.log(error.response);
    }
}

function* setPassword(action) {
    const { userData, history } = action.data;

    yield put({ type: SET_USER_VERIFICATION_LOADING, data: true });

    try {
        yield axios.post('/auth/set-password/', userData);

        yield put({ type: SET_USER_VERIFICATION_LOADING, data: false });

        history.push('/free/signin');
    } catch (error) {
        console.log(error);
        console.log(error.response);
        yield put({ type: SET_USER_VERIFICATION_LOADING, data: false });
    }
}

function* submitAssessment(action) {
    const { assessmentData, history } = action.data;

    try {
        yield axios.post('/add-assessment', assessmentData);

        history.push({
            pathname: '/free/home',
            state: {
                id: assessmentData.role,
                wf: 'dummy',
                comp: 'dummy',
                startT: (new Date()).getTime(),
                code: 'dummy',
                heading: assessmentData.role,
                prob: 'na',
                resume: 'false'
            }
        });
    } catch (error) {
        console.log(error);
        console.log(error.response);
    }
}

function* fetchAssessmentHistory() {
    try {
        let response = yield axios.get('/fetch-assessment');

        yield put({ type: SET_ASSESSMENT_HISTORY, data: response.data });
    } catch (error) {
        console.log(error);
        console.log(error.response);
        yield put({ type: SET_ASSESSMENT_HISTORY, data: [] });
    }
}

function* logoutUser(action) {
    const { userType, history } = action.data;

    try {
        yield axios.get('/auth/logout');

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('company');
        localStorage.removeItem('userName');
        localStorage.removeItem('userRole');
        localStorage.removeItem('userPlan');
        localStorage.removeItem('totalAssessments');

        yield put({ type: IS_AUTHENTICATED, data: false });

        if (userType === 'free') {
            history.push('/free/signin');
        } else {
            history.push('/signin');
        }
    } catch (error) {
        console.log(error);
        console.log(error.response);
        if (userType === 'free') {
            history.push('/free/signin');
        } else {
            history.push('/signin');
        }
    }
}

function* fetchDriveDetails(action) {
    const { data } = action;
    
    yield put({ type: SET_DRIVE_DETAILS, data: {} });

    try {
        let response = yield axios.get('/workflow/' + data.workflowId);

        yield put({ type: SET_DRIVE_DETAILS, data: response.data });
    } catch (error) {
        console.log(error);
        yield put({ type: SET_DRIVE_DETAILS, data: {} });
        redirectUnauthorisedUser(error, data.history);
    }
}

function* fetchCompanies() {
    try {
        let response = yield axios.get('/all-company');
        yield put({ type: SET_COMPANIES, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* addCompany(data) {
    try {
        let response = yield axios.post('/company', data.data);
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "Company added successfully" });
    } catch (e) {
        console.log(e);
    }
}

function* deleteCompany(data) {
    try {
        let response = yield axios.post('/delete-company/'+data.data);
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "Company deleted successfully" });
    } catch (e) {
        console.log(e);
    }
}

function* getSuperUser() {
    try {
        let response = yield axios.get('/superUser');
        yield put({ type: SET_SUPERUSER, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* addSuperUser(data) {
    try {
        let response = yield axios.post('/addSuperUser', data.data);
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "User added successfully" });
    } catch (e) {
        console.log(e);
    }
}

function* deleteSuperUser(data) {
    try {
        let response = yield axios.post('/deleteSuperUser', data.data);
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "User deleted successfully" });
    } catch (e) {
        console.log(e);
    }
}

function* addBots(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/bot/add', data);
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "bot added successfully" });

        yield put({ type: FETCH_BOTS });
    } catch (e) {
        console.log(e);
    }
}

function* deleteBots(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/bot/delete', data);
        yield put({ type: SET_SUCCESS_MSG, data: response.data, successMsg: "bot deleted successfully" });

        yield put({ type: FETCH_BOTS });
    } catch (e) {
        console.log(e);
    }
}

function* getFeedback() {
    try {
        let response = yield axios.get('/getAllFeedback');
        yield put({ type: SET_FEEDBACK, data: response.data });
    } catch(error) {
        console.log(error);
    }
}

function* fetchMyDetails(action) {
    const { data } = action;
    try {
        let response = yield axios.get('/my-details');
        yield put({ type: SET_MY_DETAILS, data: response.data });
    } catch(error) {
        console.log(error);
        redirectUnauthorisedUser(error, data.history);
    }
}

function* updateMyDetails(action) {
    const { data } = action;

    try {
        yield axios.post('/update-my-details', data.userData);
        yield put({ type: FETCH_MY_DETAILS });
    } catch(error) {
        console.log(error);
        redirectUnauthorisedUser(error, data.history);
    }
}

function* changeMyPassword(action) {
    const { data } = action;

    try {
        yield axios.post('/auth/change-password', data.changePwdData);
    } catch(error) {
        console.log(error);
        redirectUnauthorisedUser(error, data.history);
    }
}

function* fetchPreScreeningBots(action) {
    const { data } = action;
    try {
        let response = yield axios.get('/pre-screening-bot/all');
        yield put({ type: SET_PRE_SCREENING_BOTS, data: response.data });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* addPreScreeningBot(action) {
    const { data } = action;

    try {
        yield axios.post('/pre-screening-bot/add', data.botData);
        yield put({ type: FETCH_PRE_SCREENING_BOTS });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* editPreScreeningBot(action) {
    const { data } = action;

    try {
        yield axios.post('/pre-screening-bot/edit', data.botData);
        yield put({ type: FETCH_PRE_SCREENING_BOTS });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* deletePreScreeningBot(action) {
    const { data } = action;

    try {
        yield axios.post('/pre-screening-bot/delete', { id: data.id });
        yield put({ type: FETCH_PRE_SCREENING_BOTS });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* fetchPreScreeningChat(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/pre-screening-bot/chat', data);

        yield put({ type: SET_PRE_SCREENING_CHAT, data: response.data });  
        yield put({ type: SET_CURRENT_BOT_QUESTION, data: data.qId });
    } catch (e) {
        console.log(e);
    }
}

function* verifyLinkToken(action) {
    const { userData, history } = action.data;

    yield put({ type: AUTH_LOADING, data: true });

    try {
        let response = yield axios.post('/auth/verify-appsumo', userData);

        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.accessToken);
        localStorage.setItem('email', response.data.message.email);
        localStorage.setItem('couponCode', response.data.message.couponCode);

        yield put({ type: AUTH_LOADING, data: false });

        history.push('/free/create-assessment');
    } catch (error) {
        yield put({ type: AUTH_LOADING, data: false });
        yield put({ type: AUTH_ERROR_MSG, data: error.response.data.message });
    }
}

function* fetchSaasPlanList() {
    try {
        let response = yield axios.get('/user-plan/all');
        yield put({ type: SET_SAAS_PLAN_LIST, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* fetchSaasPlanDetails(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/user-plan/details', data);
        yield put({ type: SET_SAAS_PLAN_DETAILS, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* updateSaasPlanDetails(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/user-plan/edit', data);
        yield put({ type: FETCH_SAAS_PLAN_DETAILS, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* updateAssessmentBotLogo(action) {
    const { data } = action;

    try {
        yield axios.post('/workflow/logo', data.formData, { 'Content-Type': 'multipart/form-data' });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* updateDashboardBotLogo(action) {
    const { data } = action;

    try {
        let response = yield axios.post('/company/logo', data.formData, { 'Content-Type': 'multipart/form-data' });
        localStorage.setItem('dashboardLogo', response.data.url);
        yield put({ type: SET_DASHBOARD_LOGO, data: response.data.url });
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* createWorkflowJobRole(action) {
    const { data } = action;
    yield put({ type: SET_CREATE_JOB_LOADING, data: true });
    try {
        let response = yield axios.post('/workflow/job-role', data.formData);
        yield put({ type: SET_JOB_ROLE_DETAILS, data: response.data.jobRoleDetails });
        yield put({ type: SET_CREATE_JOB_LOADING, data: false });
    } catch (e) {
        console.log(e);
        yield put({ type: SET_CREATE_JOB_LOADING, data: false });
        redirectUnauthorisedUser(e, data.history);
    }
}

function* sendPreScreeningTest(action) {
    const { data } = action;

    try {
        yield axios.post('/pre-screening/test-invite', data.botData );
    } catch (e) {
        console.log(e);
        redirectUnauthorisedUser(e, data.history);
    }
}

function* fetchTrialUsersList(action) {
    try {
        const response = yield axios.get('/jd-user/all' );
        yield put({ type: SET_TRIAL_USERS_LIST, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* sendUpdateAll(action) {
    const { data } = action;

    try {
        yield axios.post('/notification/all', data);
    } catch (e) {
        console.log(e);
    }
}

function* fetchCompanyDetails(action) {
    const { data } = action;

    try {
        let response = yield axios.get('/company/' + data.companyId);
        yield put({ type: SET_COMPANY_DETAILS, data: response.data });
    } catch (e) {
        console.log(e);
    }
}

function* updateCompanyFeatures(action) {
    const { data } = action;

    try {
        yield axios.post('/company/features', data);
    } catch (e) {
        console.log(e);
    }
}

function* updateBotDetails(action) {
    const { data } = action;

    try {
        yield axios.post('/bot/edit', data);
        yield put({ type: FETCH_BOTS });
    } catch (e) {
        console.log(e);
    }
}

function* updateTrackerInfo(action) {
    const { data } = action;
    if (data.detail)
        data['details'] = data.detail

    try {
        yield axios.post('/new-tracker-info', data);
        yield put({ type: UPDATE_TRACKER_INFO });
    } catch (e) {
        console.log(e);
    }
}

function* updateCompanyDetails(action) {
    const { data } = action;

    try {
        yield axios.post('/company/' + data.companyId, { bots: data.bots });
    } catch (e) {
        console.log(e);
    }
}

function* updateCompanyGuidelines(action) {
    const { data } = action;

    try {
        yield axios.post('/company/guidelines', data);
        yield put({ type: SET_SUCCESS_MSG, successMsg: "Guidelines edited successfully" });
        yield put({ type: FETCH_COMPANIES });
    } catch (e) {
        console.log(e);
    }
}

export default function* appSaga() {
    yield all([yield takeLatest(FETCH_BOTS, fetchBots)]);
    yield all([yield takeLatest(FETCH_COMPANY_BOTS, fetchCompanyBots)]);
    yield all([yield takeLatest(GET_CANDIDATES_BY_TYPE, getCandidatesByType)]);
    yield all([yield takeLatest(EDIT_CANDIDATE_NAME, editCandidateName)]);
    yield all([yield takeLatest(DELETE_CANDIDATE, deleteCandidate)]);
    yield all([yield takeLatest(EDIT_DRIVE_NAME, editDriveName)]);
    yield all([yield takeLatest(USER_SIGN_IN, userSignIn)]);
    yield all([yield takeLatest(FETCH_NOTIFICATIONS, fetchNotifications)]);
    yield all([yield takeLatest(UPDATE_NOTIFICATION, updateNotification)]);
    yield all([yield takeLatest(FREE_USER_SIGN_IN, freeUserSignIn)]);
    yield all([yield takeLatest(FREE_USER_SIGN_UP, freeUserSignUp)]);
    yield all([yield takeLatest(VERIFY_USER, verifyUser)]);
    yield all([yield takeLatest(VERIFY_FREE_USER, verifyFreeUser)]);
    yield all([yield takeLatest(SEND_FORGOT_PASSWORD_LINK, sendForgotPasswordLink)]);
    yield all([yield takeLatest(RESET_PASSWORD, resetPassword)]);
    yield all([yield takeLatest(SET_PASSWORD, setPassword)]);
    yield all([yield takeLatest(SUBMIT_ASSESSMENT, submitAssessment)]);
    yield all([yield takeLatest(FETCH_ASSESSMENT_HISTORY, fetchAssessmentHistory)]);
    yield all([yield takeLatest(USER_LOGOUT, logoutUser)]);
    yield all([yield takeLatest(GET_DRIVE_DETAILS, fetchDriveDetails)]);
    yield all([yield takeLatest(FETCH_COMPANIES, fetchCompanies)]);
    yield all([yield takeLatest(ADD_COMPANY, addCompany)]);
    yield all([yield takeLatest(DELETE_COMPANY, deleteCompany)]);
    yield all([yield takeLatest(ADD_BOTS, addBots)]);
    yield all([yield takeLatest(DELETE_BOTS, deleteBots)]);
    yield all([yield takeLatest(GET_SUPERUSER, getSuperUser)]);
    yield all([yield takeLatest(ADD_SUPERUSER, addSuperUser)]);
    yield all([yield takeLatest(DELETE_SUPERUSER, deleteSuperUser)]);
    yield all([yield takeLatest(GET_FEEDBACK, getFeedback)]);
    yield all([yield takeLatest(FETCH_MY_DETAILS, fetchMyDetails)]);
    yield all([yield takeLatest(UPDATE_MY_DETAILS, updateMyDetails)]);
    yield all([yield takeLatest(CHANGE_MY_PASSWORD, changeMyPassword)]);
    yield all([yield takeLatest(ADD_PRE_SCREENING_BOT, addPreScreeningBot)]);
    yield all([yield takeLatest(EDIT_PRE_SCREENING_BOT, editPreScreeningBot)]);
    yield all([yield takeLatest(DELETE_PRE_SCREENING_BOT, deletePreScreeningBot)]);
    yield all([yield takeLatest(FETCH_PRE_SCREENING_BOTS, fetchPreScreeningBots)]);
    yield all([yield takeLatest(FETCH_PRE_SCREENING_CHAT, fetchPreScreeningChat)]);
    yield all([yield takeLatest(VERIFY_LINK_TOKEN, verifyLinkToken)]);
    yield all([yield takeLatest(FETCH_SAAS_PLAN_LIST, fetchSaasPlanList)]);
    yield all([yield takeLatest(FETCH_SAAS_PLAN_DETAILS, fetchSaasPlanDetails)]);
    yield all([yield takeLatest(UPDATE_SAAS_PLAN_DETAILS, updateSaasPlanDetails)]);
    yield all([yield takeLatest(UPDATE_ASSESSMENT_BOT_LOGO, updateAssessmentBotLogo)]);
    yield all([yield takeLatest(UPDATE_DASHBOARD_LOGO, updateDashboardBotLogo)]);
    yield all([yield takeLatest(CREATE_WORKFLOW_JOB_ROLE, createWorkflowJobRole)]);
    yield all([yield takeLatest(SEND_PRE_SCREENING_TEST, sendPreScreeningTest)]);
    yield all([yield takeLatest(FETCH_TRIAL_USERS_LIST, fetchTrialUsersList)]);
    yield all([yield takeLatest(SEND_UPDATE_ALL, sendUpdateAll)]);
    yield all([yield takeLatest(FETCH_COMPANY_DETAILS, fetchCompanyDetails)]);
    yield all([yield takeLatest(UPDATE_COMPANY_FEATURES, updateCompanyFeatures)]);
    yield all([yield takeLatest(UPDATE_BOT_DETAILS, updateBotDetails)]);
    yield all([yield takeLatest(UPDATE_TRACKER_INFO, updateTrackerInfo)]);
    yield all([yield takeLatest(UPDATE_COMPANY_DETAILS, updateCompanyDetails)]);
    yield all([yield takeLatest(UPDATE_COMPANY_GUIDELINES, updateCompanyGuidelines)]);
}
