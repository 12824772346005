import React from 'react';

import "../../../styles/btn.css";

const ChatInput3 = (props) => {
	const { addToMessageBox, nextQues, setNextQues, setSeeQues } = props;

	const continueButtonClick = () => {
		addToMessageBox(nextQues);
		setNextQues([]);
		setSeeQues(true);
	};

	return (
		<div id="chat-form" style={{
			height: "8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display: "flex", alignItems: "center", justifyItems:
				"center"
		}}>
			<button className='btn btn__secondary' style={{ padding: "0.4vh 1.4vw", textTransform: "capitalize", fontSize: "1.1vw", margin: "auto", }} onClick={continueButtonClick()}>
				Continue
			</button>
		</div>
	)
}

export default ChatInput3;