import { Paper, Link } from '@material-ui/core'
import {
  Grid,
//   Select,
//   MenuItem,
  FormControl,
//   InputLabel,
  NativeSelect,
} from '@material-ui/core'
import styles from '../styles/SignUp.module.css'
import {
  PrimaryButton,
  PrimaryInput,
  SecondaryButton,
} from './MaterialComponents'
import React, { useState } from 'react'
import axios from '../axios'
import { useHistory } from 'react-router-dom'
import { validateSignUpInput } from '../utils/validation/validateUtils'
import Loader from '../components/common/Loader'
import Error from '../components/common/Error'
import Sidebar from './sidebar/Sidebar'
import Workflow from '../pages/workflows/Workflow'

function SignUp() {
  // let countries

  // useEffect(async () => {
  //   axios
  //     .get('https://api.first.org/data/v1/countries')
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err))
  // }, [])

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState('India')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [college, setCollege] = useState('')
  const [referralCode, setReferralCode] = useState('')
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  let history = useHistory()

  // const token = localStorage.getItem('accessToken')

  const handleSignUp = (e) => {
    e.preventDefault()

    setIsError(false)
    setIsLoading(true)
    setErrors({})
    const userData = {
      firstName,
      lastName,
      email,
      password,
      institution: college,
      country,
      phone,
    }

    const errorResult = validateSignUpInput(userData)

    if (!errorResult.isValid) {
      setErrors(errorResult.errors)
      setIsLoading(false)
      console.log(errors)
      return
    }

    console.log(userData)

    axios
      .post('/auth/signup', { ...userData })
      .then((response) => {
        setIsLoading(false)
        // localStorage.setItem('accessToken', response.data.accessToken)
        // localStorage.setItem('refreshToken', response.data.accessToken)
        setIsAuthenticated(true)

        console.log(response)
        history.push('/signin')
      })
      .catch((error) => {
        setIsError(true)
        setErrorMsg(error.response.data.message)
        setIsLoading(false)
        setIsAuthenticated(false)
        console.log(error.response)
      })
  }

  const labelStyle = {
    color: '#555',
    fontWeight: '600',
    marginBottom: '0.5rem',
  }

  return isAuthenticated ? (
    <div>
    <Sidebar hidden={true} />
    <Workflow />
    </div>
  ) : (
    <form className={styles.forgotMain}>
      {/* <img
        className={styles.sideImage}
        src='./17876784.jpg'
        height={'50%'}
        alt=''
      ></img> */}
      <Paper elevation={10} className={styles.paper}>
        <div align='center'>
          <h1
            style={{
              fontSize: '2.5rem',
              color: '#0A2463',
            }}
          >
            Sign Up
          </h1>
        </div>
        <div style={{ marginLeft: '2rem' }}>
          <p style={labelStyle}>Name</p>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <PrimaryInput
                error
                placeholder='First name'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                helperText={errors.firstName}
              ></PrimaryInput>
            </Grid>
            <Grid item xs={6}>
              <PrimaryInput
                error
                placeholder='Last name'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                helperText={errors.lastName}
              ></PrimaryInput>
            </Grid>
          </Grid>
          <p style={labelStyle}>Email Address</p>
          <PrimaryInput
            error
            placeholder='Enter your email address'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            helperText={errors.email}
          ></PrimaryInput>
          <p style={labelStyle}>Password</p>
          <PrimaryInput
            error
            placeholder='Set a password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            helperText={errors.password}
          ></PrimaryInput>
          <p style={labelStyle}>College/Company</p>
          <PrimaryInput
            error
            placeholder='Type your college or company name'
            value={college}
            onChange={(e) => setCollege(e.target.value)}
            helperText={errors.institution}
          ></PrimaryInput>
          <p style={labelStyle}>Country</p>

          <FormControl fullWidth>
            <NativeSelect
              defaultValue={country}
              inputProps={{
                name: 'country',
                id: 'uncontrolled-native',
              }}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value={'India'}>India</option>
              <option value={'United States'}>United States</option>
              <option value={'United Kingdom'}>United Kingdom</option>
            </NativeSelect>
          </FormControl>

          <p style={labelStyle}>Phone Number</p>
          <PrimaryInput
            error
            placeholder='Type your phone number'
            type='tel'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            helperText={errors.phone}
          ></PrimaryInput>

          <p style={labelStyle}>Referral Code</p>
          <PrimaryInput
            error
            placeholder='Enter a referral code'
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
            helperText={errors.referralCode}
          ></PrimaryInput>

          <div
            style={{ marginTop: '3rem', color: '#555', fontWeight: '600' }}
            align='center'
          >
            <p style={{ marginBottom: '0' }}>
              By clicking Get Started, you agree to the
            </p>
            <p style={{ marginTop: '0.25rem', color: '#0A2463' }}>
              User Agreement, Privacy and Cookie Policy.
            </p>
          </div>

          <div
            style={{
              marginTop: '2rem',
              paddingBottom: '1.75rem',
            }}
            align='center'
          >
            <PrimaryButton
              type='submit'
              style={{ width: '80%' }}
              onClick={handleSignUp}
            >
              GET STARTED
            </PrimaryButton>
          </div>
          <div
            style={{
              paddingBottom: '1rem',
            }}
            align='center'
          >
            <Link href=''>
              <SecondaryButton style={{ width: '80%' }}>
                CHECK DEMO
              </SecondaryButton>
            </Link>

            {isLoading && <Loader />}
            {isError && <Error errorMsg={errorMsg} />}

            <div
              style={{ marginTop: '3rem', color: '#555', fontWeight: '600' }}
              align='center'
            >
              <p>Already a member?</p>
              <Link href='/signIn'>
                <h3
                  style={{
                    marginTop: '0.25rem',
                    fontWeight: 'bold',
                    color: '#555',
                  }}
                >
                  LOGIN
                </h3>
              </Link>
            </div>
          </div>
        </div>
      </Paper>
    </form>
  )
}

export default SignUp
