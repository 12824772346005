import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import '../../styles/select.scss';

const Select = (props) => {
	const { selectedValue, dropdownData, onChangeSelect, emptyValue, style, dropdownAddItem } = props;

	const [showDropdown, setShowDropdown] = useState(false);

	const dropdownRef = useRef();
	const dropdownContentRef = useRef();

	const toggleDropdown = () => {
		setShowDropdown(!showDropdown);
	}

	useEffect(() => {
		function handleClickOutside(event) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdownRef]);

	const onClickSelectItem = (val) => {
		setShowDropdown(false);
		onChangeSelect(val);
	}

	return (
		<div className='select' ref={dropdownRef} style={style ? style.root : {}}>
			<div className={`wrapper ${emptyValue ? 'empty' : ''} ${showDropdown ? 'open' : ''}`} onClick={toggleDropdown} style={style ? style.wrapper: {}}>{selectedValue}</div>
			{
				showDropdown ? (
					<div className='dropdown' ref={dropdownContentRef} style={style ? style.droppdown : {}}>
						{
							dropdownAddItem && dropdownAddItem.text !== '' ? (
								<Link style={{ display: 'block', textDecoration: 'none' }} className='dropdown-item' to={dropdownAddItem.link}>{dropdownAddItem.text}</Link>
							) : ''
						}
						{
							dropdownData.map((dd) => (
								<div className='dropdown-item' onClick={() => onClickSelectItem(dd)} key={dd.value}>{dd.label}</div>
							))
						}
					</div>
				) : ''
			}
		</div>
	)
}

export default Select;