import "../workflows/Workflow.css";
import React, { useEffect, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { Link } from "react-router-dom";
import "../../styles/table.css"
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useHistory } from 'react-router-dom';
import emptyRowImg from '../../assets/emptyrow1.png';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCompaniesList, getRerender } from '../../selectors/app';
import {
	resetSuccessMsg as resetSuccessMsgRequest,
	resetRerender as resetRerenderRequest,
	addCompany as addCompanyRequest,
	fetchCompanies as fetchCompaniesRequest,
	deleteCompany as deleteCompanyRequest,
	updateCompanyGuidelines as updateCompanyGuidelinesRequest
} from '../../actions/app';
import axios from '../../axios';
import { Line } from "react-chartjs-2";
import { Select } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';

const Company = (props) => {
	const {
		addCompany,
		rerender,
		resetRerender,
		fetchCompanies,
		allCompanies,
		deleteCompany,
		updateCompanyGuidelines
	} = props;

	const history = useHistory();
	const location = {
		state: {
			role: "super-admin"
		}
	}

	const isNotEmpty = (object) => {
		for (const property in object) {
			return true;
		}
		return false;
	}

	const [allUserChart, setAllUserChart] = useState({})
	const [allWfChart, setAllWfChart] = useState({})
	const [change, setChange] = useState(0);
	const [dayWf, setDayWf] = useState(0);
	const [dayUser, setDayUser] = useState(0);
	const [companyName, setCompanyName] = useState('')
	const [compId, setCompId] = useState()
	const [compDetails, setCompDetails] = useState()
	const [firstUserName, setFirstUserName] = useState('')
	const [firstUserEmail, setFirstUserEmail] = useState('')
	const [companyToBeDeleted, setCompanyToBeDeleted] = useState([]);
	const [pageSize, setPageSize] = useState(15);
	const [open, setOpen] = useState(false)
	const [openComp, setOpenComp] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [logDetails, setLogDetails] = useState();
	const [selectedCompany, setSelectedCompany] = useState({});
	const [guidelinesModal, setGuidelinesModal] = useState(false);
	const [companyGuidelines, setCompanyGuidelines] = useState([]);
	const [assessmentWelcomeText, setAssessmentWelcomeText] = useState('');

	const onOpenModal = () => setOpen(true);

	const onCloseModal = () => setOpen(false);

	const onOpenCompModal = () => setOpenComp(true);

	const onCloseCompModal = () => setOpenComp(false);

	const onOpenDeleteModal = () => setDeleteModal(true);

	const onCloseDeleteModal = () => {
		setCompanyToBeDeleted([]);
		setDeleteModal(false);
	}
	const [userChart, setUserChart] = useState({})
	const [wfChart, setWfChart] = useState({})

	useEffect(() => {
		if (location.state.role != "super-admin") {
			history.push("/invalid")
		}
	}, [change])

	useEffect(() => {
		console.log(compId)
		if (isNotEmpty(compId)) {
			axios.get('/admin/getDetail/' + compId._id).then((res) => {
				console.log(res.data)
				setCompDetails(res.data.company)
				setLogDetails(res.data.logs)
				onOpenCompModal();
			}).catch((err) => {
				console.log(err)
			})
		}
	}, [compId])

	useEffect(() => {
		fetchCompanies();
	}, [change]);

	useEffect(() => {
		if (isNotEmpty(allWfChart)) {
			console.log('data nedded', dayWf)
			console.log('total data', allWfChart.labels.length, allWfChart.datasets[0].data.length)
			if (dayWf == 0 || allWfChart.labels.length < dayWf)
				setWfChart({ ...allWfChart })
			else {
				let tmp = JSON.parse(JSON.stringify(allWfChart))
				tmp.labels = tmp.labels.slice(tmp.labels.length - dayWf)
				for (let i = 0; i < tmp.datasets.length; i++)
					tmp.datasets[i].data = tmp.datasets[i].data.slice(tmp.datasets[i].data.length - dayWf)
				setWfChart({ ...tmp })
			}
		}
	}, [dayWf]);

	useEffect(() => {
		if (isNotEmpty(allUserChart)) {
			console.log('data nedded', dayUser)
			console.log('total data', allUserChart.labels.length)
			if (dayUser == 0 || allUserChart.labels.length < dayUser)
				setUserChart({ ...allUserChart })
			else {
				let tmp = JSON.parse(JSON.stringify(allUserChart))
				tmp.labels = tmp.labels.slice(tmp.labels.length - dayUser)
				for (let i = 0; i < tmp.datasets.length; i++)
					tmp.datasets[i].data = tmp.datasets[i].data.slice(tmp.datasets[i].data.length - dayUser)
				setUserChart({ ...tmp })
			}
		}
	}, [dayUser]);

	useEffect(() => {
		if (isNotEmpty(compDetails)) {
			let dat = workflowChart()
			setWfChart(dat)
			setAllWfChart(dat)
		}
	}, [compDetails]);

	useEffect(() => {
		if (isNotEmpty(logDetails)) {
			let dat = logChart
			setUserChart(dat)
			setAllUserChart(dat)
		}
	}, [logDetails]);

	useEffect(() => {
		if (!rerender) return;

		setChange(change + 1);
		resetRerender();

		if (open) onCloseModal();
		if (deleteModal) onCloseDeleteModal();

	}, [rerender])

	useEffect(() => {
		if (companyToBeDeleted.length == 0) return;

		onOpenDeleteModal();
	}, [companyToBeDeleted]);

	const onOpenGuidelinesModal = (comp) => {
		setSelectedCompany({ ...comp });
		setCompanyGuidelines([...comp.guidelines]);
		setAssessmentWelcomeText(comp.assessmentWelcomeText);
		setGuidelinesModal(true);
	}

	const onCloseGuidelinesModal = () => {
		setSelectedCompany({});
		setCompanyGuidelines([]);
		setAssessmentWelcomeText('');
		setGuidelinesModal(false);
	}


	const columns = [
		{
			field: "name",
			headerName: "Name",
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<Link className="datagrid__row datagrid__row-center" to={{ pathname: "/workflows", state: { company: params.row } }}>
							{params.row.name}
						</Link>
					</>
				);
			}
		},
		{
			field: "workflowsCount",
			headerName: "No. of Workflows",
			flex: 0.8,
			valueGetter: getWorkflowsLength,
			renderCell: (params) => {
				return (
					<>
						<Link className="datagrid__row" to={{ pathname: "/workflows", state: { company: params.row } }}>
							{params.value}
						</Link>
					</>
				);
			}
		},
		{
			field: "createdDate",
			headerName: "Date Created",
			flex: 0.8,
			renderCell: (params) => {
				const options = { year: 'numeric', month: 'short', day: 'numeric' };
				let date = new Date(params.row.createdDate).toLocaleDateString("en-US", options);
				return (
					<>
						<Link className="datagrid__row" to={{ pathname: "/workflows", state: { company: params.row } }}>
							{
								date
							}
						</Link>
					</>
				);
			}
		},
		{
			field: "action",
			headerName: "Action",
			headerAlign: "center",
			flex: 1.5,
			renderCell: (params) => {
				return (
					<>
						<Link className="btn-datagrid" style={{ textDecoration: 'none' }} to={{ pathname: "/workflows", state: { company: params.row } }}>
							<Button
								className="btn-datagrid"
								size="small"
								variant="contained">
								View
							</Button>
						</Link>
						<Link className="btn-datagrid" style={{ textDecoration: 'none' }} to={{ pathname: "/manage-bots/", state: { company: params.row } }}>
							<Button
								className="btn-datagrid"
								size="small"
								variant="contained">
								Bots
							</Button>
						</Link>
						<Link className="btn-datagrid" style={{ textDecoration: 'none' }} to={{ pathname: "/manage-features/", state: { company: params.row } }}>
							<Button
								className="btn-datagrid"
								size="small"
								variant="contained">
								Features
							</Button>
						</Link>
						<Button
							className="btn-datagrid"
							size="small"
							variant="contained"
							onClick={() => onOpenGuidelinesModal(params.row)}
						>
							Guidelines
						</Button>
						<Button
							className="btn-datagrid btn-danger"
							size="small"
							variant="contained"
							onClick={() => setCompId(params.row)}
						>
							Info
						</Button>
						<Button
							className="btn-datagrid btn-danger"
							size="small"
							variant="contained"
							onClick={() => setCompanyToBeDeleted([params.row])}
						>
							Delete
						</Button>
					</>
				);
			},
		},
	];
	function getCompanyData(id) {
		axios({
			url: '/admin/alldetails/' + id, //your url
			method: 'GET',
			responseType: 'blob', // important
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', id + '.csv'); //or any other extension
			document.body.appendChild(link);
			link.click();
		});
	}
	function getDate(ms) {
		return new Date(ms).toLocaleDateString()
	}
	function getNextDay(ms) {
		let tmpDate = new Date(ms)
		tmpDate.setDate(tmpDate.getDate() + 1)
		return new Date(tmpDate).getTime()

	}
	function getMs(date) {
		var parts = date.replaceAll('/', '-');
		var mydate = new Date(parts);
		console.log(mydate)
		return mydate.getTime()
	}
	function workflowChart() {
		if (!isNotEmpty(compDetails)) return null
		let tmpInv = {}
		let tmpStart = {}
		let tmpEnd = {}
		let minDate = new Date().getTime()
		for (var i = 0; i < compDetails.workflows.length; i++) {
			for (var j = 0; j < compDetails.workflows[i].candidates.length; j++) {
				let d1 = getDate(compDetails.workflows[i].candidates[j].createdDate)
				tmpInv[d1] = tmpInv[d1] ? tmpInv[d1] + 1 : 1
				if (compDetails.workflows[i].candidates[j].test) {
					let d2 = getDate(compDetails.workflows[i].candidates[j].test.createdDate)
					tmpStart[d2] = tmpStart[d2] ? tmpStart[d2] + 1 : 1
					if (compDetails.workflows[i].candidates[j].test.status == "Complete") {
						let d3 = getDate(compDetails.workflows[i].candidates[j].test.endDate)
						tmpEnd[d3] = tmpEnd[d3] ? tmpEnd[d3] + 1 : 1
					}
				}
				if (minDate > new Date(compDetails.workflows[i].candidates[j].createdDate).getTime()) minDate = new Date(compDetails.workflows[i].candidates[j].createdDate).getTime()
			}
		}
		console.log(minDate, getDate(minDate))
		let labels = []
		let dataInv = []
		let dataStart = []
		let dataEnd = []
		while (minDate <= new Date().getTime()) {
			console.log(getDate(minDate))
			let currdate = getDate(minDate)
			labels.push(currdate)
			dataInv.push(tmpInv[currdate] ? tmpInv[currdate] : 0)
			dataStart.push(tmpStart[currdate] ? tmpStart[currdate] : 0)
			dataEnd.push(tmpEnd[currdate] ? tmpEnd[currdate] : 0)
			minDate = getNextDay(minDate)
			console.log(getDate(minDate))
			if (labels.length <= 1)
				continue
			dataInv[dataInv.length - 1] += dataInv[dataInv.length - 2]
			dataStart[dataStart.length - 1] += dataStart[dataStart.length - 2]
			dataEnd[dataEnd.length - 1] += dataEnd[dataEnd.length - 2]
		}

		const data = {
			labels: labels,
			datasets: [
				{
					label: "Invited",
					data: dataInv,
					fill: false,
					borderColor: "rgba(0,0,255,1)"
				},
				{
					label: "Started",
					data: dataStart,
					fill: false,
					borderColor: "rgba(0,255,0,0.5)"
				},
				{
					label: "End",
					data: dataEnd,
					fill: false,
					borderColor: "rgba(255,0,0,1)"
				},
			]
		}
		console.log(data)
		return data
	}
	function logChart() {
		if (!isNotEmpty(logDetails)) return null
		let tmpLogin = {}
		let tmpInteraction = {}
		let minDate = new Date().getTime()
		for (var i = 0; i < logDetails.length; i++) {
			let d1 = getDate(logDetails[i].createdDate)
			if (logDetails[i].activity == 'Login')
				tmpLogin[d1] = tmpLogin[d1] ? tmpLogin[d1] + 1 : 1
			else
				tmpInteraction[d1] = tmpInteraction[d1] ? tmpInteraction[d1] + 1 : 1
			if (minDate > new Date(logDetails[i].createdDate).getTime()) minDate = new Date(logDetails[i].createdDate).getTime()
		}
		console.log(minDate, getDate(minDate))
		let labels = []
		let dataLogin = []
		let dataInteraction = []
		while (minDate <= new Date().getTime()) {
			console.log(getDate(minDate))
			let currdate = getDate(minDate)
			labels.push(currdate)
			dataLogin.push(tmpLogin[currdate] ? tmpLogin[currdate] : 0)
			dataInteraction.push(tmpInteraction[currdate] ? tmpInteraction[currdate] : 0)
			minDate = getNextDay(minDate)
			console.log(getDate(minDate))
		}

		const data = {
			labels: labels,
			datasets: [
				{
					label: "Login",
					data: dataLogin,
					fill: false,
					borderColor: "rgba(0,0,255,1)"
				},
				{
					label: "Interaction",
					data: dataInteraction,
					fill: false,
					borderColor: "rgba(0,255,0,1)"
				}
			]
		}
		console.log(data)
		return data
	}
	function getWorkflowsLength(params) {
		if (params.row.workflows)
			return params.row.workflows.length;
		else
			return 0;
	}

	function handleDelete(id) {
		deleteCompany(id);
	};

	function renderDate(date) {
		let createdDate = new Date(date);
		return (
			<>{createdDate.toLocaleString()}</>
		)
	}

	function sendCompany() {
		const getData = {
			name: companyName,
			adminName: firstUserName,
			adminEmail: firstUserEmail
		}
		addCompany(getData);
	}

	function NoRowsOverlay() {
		return (
			<div className="norowsoverlay__container">
				<img
					src={emptyRowImg}
					alt="no rows"
					className="norowsoverlay__img"
				/>
				<p className="norowsoverlay__text">Nothing to show</p>
			</div>
		)
	}

	const onSubmitCompanyGuidelines = () => {
		updateCompanyGuidelines({ companyId: selectedCompany._id, guidelines: companyGuidelines, assessmentWelcomeText });
		onCloseGuidelinesModal();
	}

	const changeCompanyGuidelines = (val, i) => {
		const newGuidelines = [...companyGuidelines];
		newGuidelines[i] = val;

		setCompanyGuidelines([...newGuidelines]);
	}

	const deleteCompanyGuidelines = (i) => {
		const newGuidelines = [...companyGuidelines];
		newGuidelines.splice(i, 1);

		setCompanyGuidelines([...newGuidelines]);
	}

	const addCompanyGuidelines = () => {
		const newGuidelines = [...companyGuidelines];
		newGuidelines.push('');

		setCompanyGuidelines([...newGuidelines]);
	}

	return (
		<div className="mainbar" style={{ display: "flex", flexDirection: "column", flex: 1, minHeight: "100vh" }}>
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center classNames={{ modal: 'modal__container', closeIcon: 'modal__closeicon' }}>
				<div className="modal__heading">
					Do you want to delete this company?
				</div>
				{
					companyToBeDeleted.length != 0 ?
						Object.keys(companyToBeDeleted[0]).map(key => (
							<>
								<div>
									<span><b>{key} : </b></span>
									<span>{key == "createdDate" ? renderDate(companyToBeDeleted[0][key]) : companyToBeDeleted[0][key]}</span>
								</div><br />
							</>
						))
						:
						""
				}
				<div className="modal__btnbox">
					<Button
						className="btn-primary btn-danger"
						size="small"
						variant="contained"
						disabled={companyToBeDeleted.length == 0}
						onClick={() => {
							handleDelete(companyToBeDeleted[0]._id);
						}}>
						Ok
					</Button>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseDeleteModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>

			<Modal open={open} onClose={onCloseModal} classNames={{ modal: 'modal__container', closeIcon: 'modal__closeicon' }}>
				<div className="modal__heading">
					Create new company
				</div>
				<div className="modal__inputbox">
					<input type="text" placeholder="Company Name" value={companyName} onChange={(e) => { setCompanyName(e.target.value) }} /><br />
					<input type="text" placeholder="First User Name" value={firstUserName} onChange={(e) => { setFirstUserName(e.target.value) }} /><br />
					<input type="email" placeholder="First User Email" value={firstUserEmail} onChange={(e) => { setFirstUserEmail(e.target.value) }} /><br />
				</div>
				<div className="modal__btnbox">
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={() => sendCompany()}
					>
						Create
					</Button>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>

			<Modal open={openComp} onClose={onCloseCompModal} classNames={{ modal: 'modal__container', closeIcon: 'modal__closeicon' }}>
				{isNotEmpty(wfChart) ?
					<div className="modal__heading">
						{compId.name}
						<Select
							className="remove-bg"
							onChange={(e) => setDayWf(e.target.value)}
							style={{ width: "380px", height: "45px", margin: "10px", "background": "#F2F2F2", "borderRadius": "30px" }}
						>
							<MenuItem value={0} selected>All Days</MenuItem>
							<MenuItem value={7} >Last 7 Days</MenuItem>
							<MenuItem value={30} >Last 30 Days</MenuItem>
						</Select>
						<Line data={wfChart} />
					</div>
					: null}
				{isNotEmpty(userChart) ?
					<div className="modal__heading">
						<Select
							className="remove-bg"
							onChange={(e) => setDayUser(e.target.value)}
							style={{ width: "380px", height: "45px", margin: "10px", "background": "#F2F2F2", "borderRadius": "30px" }}
						>
							<MenuItem value={0} selected>All Days</MenuItem>
							<MenuItem value={7} >Last 7 Days</MenuItem>
							<MenuItem value={30} >Last 30 Days</MenuItem>
						</Select>
						<Line data={userChart} />
					</div>
					: null}
				<div className="modal__btnbox">

					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseCompModal}
					>
						Close
					</Button>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={() => getCompanyData(compId._id)}
					>
						Get Data
					</Button>
				</div>
			</Modal>

			<Modal open={guidelinesModal} onClose={onCloseGuidelinesModal} classNames={{ modal: 'modal__container', closeIcon: 'modal__closeicon' }}>
				<div className="modal__heading">
					{selectedCompany.name}
				</div>
				<div>Change Welcome Text</div>
				<div className="modal__inputbox">
					<textarea rows={2} type="text" placeholder="Welcome Text" value={assessmentWelcomeText} onChange={(e) => setAssessmentWelcomeText(e.target.value)} style={{ width: '90%' }} />
				</div>
				<div>Change Guidelines</div>
				<div className="modal__inputbox">
					{
						companyGuidelines.map((guideline, i) => (
							<div key={i} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<textarea rows={4} type="text" placeholder={`Guidelines ${i}`} value={guideline} onChange={(e) => { changeCompanyGuidelines(e.target.value, i) }} style={{ width: '90%' }} />
								<Button
									className="btn-primary"
									size="small"
									variant="contained"
									onClick={() => deleteCompanyGuidelines(i)}
								>Delete</Button>
							</div>
						))
					}
				</div>
				<div>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={addCompanyGuidelines}
					>Add Guideline</Button>
				</div>
				<div className="modal__btnbox">
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onSubmitCompanyGuidelines}
					>
						Submit
					</Button>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseGuidelinesModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>

			<div className="notificationbar">
				<button className="btn-primary" onClick={onOpenModal}>Create new Company</button>
			</div>
			<div className="actionbar">
			</div>

			<DataGrid
				rows={allCompanies}
				getRowId={(row) => row._id}
				disableSelectionOnClick
				columns={columns}
				components={{ NoRowsOverlay }}
				autoHeight
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 15]}
				className="datagrid"
			/>
		</div>
	);
}


const mapStateToProps = createStructuredSelector({
	rerender: getRerender(),
	allCompanies: getCompaniesList()
});

const mapDispatchToProps = (dispatch) => ({
	resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
	resetRerender: () => dispatch(resetRerenderRequest()),
	addCompany: (data) => dispatch(addCompanyRequest(data)),
	deleteCompany: (data) => dispatch(deleteCompanyRequest(data)),
	fetchCompanies: () => dispatch(fetchCompaniesRequest()),
	updateCompanyGuidelines: (data) => dispatch(updateCompanyGuidelinesRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Company);
