import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { changeMyPassword as changeMyPasswordRequest } from '../../actions/app';

import '../../styles/changePassword.scss';

const ChangePassword = (props) => {
	const { setErrorAlertMsg, onErrorAlertOpen, setSuccessAlertMsg, onSuccessAlertOpen, changeMyPassword } = props;

	const history = useHistory();

	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [reNewPassword, setReNewPassword] = useState('');

	const saveChanges = () => {
		if(currentPassword === '') {
			setErrorAlertMsg('Enter valid current password');
			onErrorAlertOpen();
		} else if(newPassword === '') {
			setErrorAlertMsg('Enter valid new password');
			onErrorAlertOpen();
		} else if(newPassword !== reNewPassword) {
			setErrorAlertMsg('New Passwords are not equal');
			onErrorAlertOpen();
		} else {
			const changePwdData = {
				oldPassword: currentPassword,
				newPassword
			};

			changeMyPassword({ changePwdData, history });
			setSuccessAlertMsg('Password changed successfully');
			onSuccessAlertOpen();
		}
	}

	return (
		<div className='change-password'>
			<div className='input-group'>
				<input type='password' placeholder='Current password' onChange={(e) => setCurrentPassword(e.target.value)} value={currentPassword} />
			</div>
			<div className='input-group'>
				<input type='password' placeholder='New password' onChange={(e) => setNewPassword(e.target.value)} value={newPassword} />
			</div>
			<div className='input-group'>
				<input type='text' placeholder='Re-enter New password' onChange={(e) => setReNewPassword(e.target.value)} value={reNewPassword} />
			</div>
			<div className='change-password-actions'>
				<div onClick={saveChanges}>Save changes</div>
			</div>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
	changeMyPassword: (data) => dispatch(changeMyPasswordRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
