import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Modal from 'react-responsive-modal';
import { useLocation } from 'react-router-dom';

import axios from "../../axios";

import "./ccList.scss";

const CCListEditor = (props) => {
	const { onOpenAssessmentLogoModal, checkCompanyFeatureAccess, companyFeatures } = props;

	const location = useLocation()
	const [ccChanged,setccChanged] = useState(0)
	const [emails, setEmails] = useState([])
	const [oldEmails, setOldEmails] = useState([])
	const [val, setVal] = useState()
	const [error, setError] = useState()
	const [open, setOpen] = useState(false)
	const [openConfirmModal,setOpenConfirmModal] = useState(false)
	const [invite, setInvite] = useState(false)
	const [reject, setReject] = useState(false)
	const [remind, setRemind] = useState(false)
	const [shortlist, setShortlist] = useState(false)
	const [complete, setComplete] = useState(false)
	const handleKeyDown = (evt) => {
		if (["Enter", "Tab", ","].includes(evt.key)) {
			evt.preventDefault();

			var value = val.trim();

			if (value && isValid(value)) {
				setVal("")
				setEmails([...emails, value])
			}
		}
	}
	useEffect(() => {
		console.log(location.state.workflowId)
		axios.get("/workflow/getcclist/" + location.state.workflowId).then((res) => {
			console.log(res.data)
			setInvite(res.data.invite)
			setRemind(res.data.remind)
			setShortlist(res.data.shortlist)
			setReject(res.data.reject)
			setComplete(res.data.complete)
			setEmails(res.data.emails.length ? [] : [localStorage.getItem("email")])
			setOldEmails(res.data.emails.length ? res.data.emails : [])
			setVal("")
		}).catch((err) => {
			console.log(err)
		})
	}, [ccChanged])

	const handleClose=(evt)=>{
		evt.preventDefault()
		if (emails.length || (val && val.trim().length)){
			setOpenConfirmModal(true)
			return
		}
		setOpen(false)
	}
	const onSubmit = (e) => {
		e.preventDefault()
		let data = {
			invite,
			reject,
			remind,
			shortlist,
			complete,
			emails : [...oldEmails,...emails]
		}
		if(val && val.trim().length){
			let value = val.trim()
			if (!isValid(value))
				return
			else
				data['emails'].push(value)
		}
		console.log(data)
		axios.post("/workflow/setcclist/" + location.state.workflowId, data).then((res) => {
			console.log(res)
			setccChanged(ccChanged + 1)
			setOpen(false)
		}).catch(err => {
			console.log(err)
		})
	}
	const handleChange = (evt) => {
		setVal(evt.target.value)
		setError(null)
	};

	const handleDelete = (item) => {
		setEmails(emails.filter(i => i !== item))
	};

	const handleDeleteOld = (item) => {
		setOldEmails(oldEmails.filter(i => i !== item))
	};

	const handlePaste = (evt) => {
		evt.preventDefault();

		var paste = evt.clipboardData.getData("text");
		var newemails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

		if (newemails) {
			var toBeAdded = newemails.filter(email => !isInList(email));
			setEmails([...emails, ...toBeAdded])

		}
	}

	const isValid = (email) => {
		let err = null;

		if (isInList(email)) {
			err = `${email} has already been added.`;
		}
		if (!isEmail(email)) {
			err = `${email} is not a valid email address.`;
		}
		if (err) {
			setError(err);
			return false;
		}
		return true;
	}

	const isInList = (email) => {
		return emails.includes(email);
	}

	const isEmail = (email) => {
		return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
	}

	const onClickAddEmails = () => {
		if(checkCompanyFeatureAccess(companyFeatures, 'ccList') === 1) {
			onOpenAssessmentLogoModal();
		} else {
			setOpen(true);
		}
	}

	return (
		<>
			<div className={`settings-action-btn ${checkCompanyFeatureAccess(companyFeatures, 'ccList') === 1 ? 'locked' : ''}`} onClick={onClickAddEmails}>
				Add Emails
			</div>
			<Modal open={openConfirmModal} onClose={()=>setOpenConfirmModal(false)} closeIcon={() => { }} center styles={{ modal: { borderRadius: "50px", padding: "40px" } }}>
					<p className="modal-text">
						You have unsaved changes, which will be lost. Do you wish to proceed?
					</p>
					<div className="button-div">
						<Button
							size="small"
							variant="contained"
							className="modal-button"
							onClick={() => {
								setOpen(false)
								setOpenConfirmModal(false)
								setccChanged(ccChanged+1)
							}}
							style={{
								background: '#479BD2',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								marginLeft: "10px"
							}}
						>
							<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>Yes</span>
						</Button>
						<Button
							size="small"
							variant="contained"
							className="cancel-modal-button"
							onClick={() => {
								setOpenConfirmModal(false)
							}}
							style={{
								background: '#479BD2',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								textTransform: 'none'
							}}
						>
							<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>No</span>
						</Button>
					</div>
				</Modal>
			<Modal open={open} onClose={(e) => handleClose(e)}>
				<div>
					<div className="modal__heading">
						Add Cc Recipients
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div className="wrapperCC">
							{oldEmails.map(item => (
								<div className="tag-itemCC" key={item}>
									{item}
									<button
										type="button"
										className="button"
										onClick={() => handleDeleteOld(item)}>

										&times;
									</button>
								</div>
							))}
							{emails.map(item => (
								<div className="save-itemCC" key={item}>
									{item}
									<button
										type="button"
										className="button"
										onClick={() => handleDelete(item)}>

										&times;
									</button>
								</div>
							))}

							<input
								className={"inputCC " + (error && " has-error")}
								value={val}
								placeholder="Type or paste email addresses and press `Enter`..."
								onKeyDown={handleKeyDown}
								onChange={handleChange}
								onPaste={handlePaste}
							/>

							{error && <p className="errorCC">{error}</p>}
						</div>
						<FormControl component="fieldset" variant="standard">
							<div className="cc-form-group">
								<div className="modal-checkbox">
									<label>
										Invitation emails
										<input type="checkbox" name="invite" checked={invite} onChange={(e) => setInvite(e.target.checked)} />
										<span className="checkmark"></span>
									</label>
								</div>
								<div className="modal-checkbox">
									<label>
										Reminder emails
										<input type="checkbox" name="remind" checked={remind} onChange={(e) => setRemind(e.target.checked)} />
										<span className="checkmark"></span>
									</label>
								</div>
								<div className="modal-checkbox">
									<label>
										Shortlist emails
										<input type="checkbox" name="shortlist" checked={shortlist} onChange={(e) => setShortlist(e.target.checked)} />
										<span className="checkmark"></span>
									</label>
								</div>
								<div className="modal-checkbox">
									<label>
										Rejection emails
										<input type="checkbox" name="reject" checked={reject} onChange={(e) => setReject(e.target.checked)} />
										<span className="checkmark"></span>
									</label>
								</div>
								<div className="modal-checkbox">
									<label>
										Post-assessment emails
										<input type="checkbox" name="complete" checked={complete} onChange={(e) => setComplete(e.target.checked)} />
										<span className="checkmark"></span>
									</label>
								</div>
							</div>
						</FormControl>
					</div>

					<div className="modal__btnbox" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
						<Button
							className="btn-primary"
							size="small"
							variant="contained"
							onClick={(e) => onSubmit(e)}
						>
							Save changes
						</Button>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default CCListEditor;