import React from 'react';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import BotIcon from '../../assets/bot.png';
import "../../styles/preScreeningBot.scss";

const Message = (props) => {
	const { messageData, submitAnswer } = props;

	return (
		<div className="ps-msg">
			<div className="ps-msg-icon">
				<img src={BotIcon} alt='bot' style={{ height: 50 }} />
			</div>
			<div className="ps-msg-question">
				{
					messageData.questionData ? (
						<MultipleChoiceQuestion mcqData={messageData.questionData} submitAnswer={submitAnswer} />
					) : (
						<div style={{ backgroundColor: '#F8F8F8', padding: '10px 30px', borderRadius: 25, fontSize: 18 }}>{messageData.content}</div>
					)
				}
			</div>
		</div>
	)
}

export default Message;
