import React, { useEffect, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import notificationcircle from '../../assets/notificationcircle.svg';
import notificationcircleGrey from '../../assets/notification-circle-grey.svg';
import notificationsRead from '../../assets/notifications-read.png';

const AllNotificationTab = (props) => {
	const { notifications, updateNotificationStatus } = props;

	const location = useLocation();

	const [submittedCount, setSubmittedCount] = useState(0);
	const [invitedCount, setInvitedCount] = useState(0);
	const [submittedNotificationId, setSubmittedNotificationId] = useState();
	const [invitedNotificationId, setInvitedNotificationId] = useState();
	const [submittedNotificationStatus, setSubmittedNotificationStatus] = useState('Unread');
	const [invitedNotificationStatus, setInvitedNotificationStatus] = useState('Unread');
	const [submittedNotificationTime, setSubmittedNotificationTime] = useState(new Date());
	const [invitedNotificationTime, setInvitedNotificationTime] = useState(new Date());

	useEffect(() => {
		notifications.forEach((item) => {
			if (item.type == "submitCount") {
				setSubmittedCount(item.text);
				setSubmittedNotificationId(item.notificationId);
				setSubmittedNotificationStatus(item.status);
				setSubmittedNotificationTime(item.createdDate);
			} else if (item.type == "inviteCount") {
				setInvitedCount(item.text);
				setInvitedNotificationStatus(item.status);
				setInvitedNotificationTime(item.createdDate);
				setInvitedNotificationId(item.notificationId);
			}
		});
		console.log(notifications);
	}, [notifications]);

	const updateNotification = (notificationId) => {
		updateNotificationStatus(notificationId);
	}

	const renderSubmittedNotification = () => {
		return (
			submittedNotificationId ? (
				<div className='notification-card'>
					<div className='notification-card-topbar'>
						<div style={{ display: "flex", alignItems: "center" }}>
							<img src={submittedNotificationStatus == "read" ? notificationcircleGrey : notificationcircle} alt="" style={{ width: 20, height: 20 }} />
							<p style={{ marginLeft: 10, fontSize: 16, fontWeight: "600" }}>Round up</p>
						</div>
						<div style={{ display: "flex", alignItems: "center" }}>
							<p style={{ marginRight: 20, fontSize: 14 }}>{moment(submittedNotificationTime).fromNow()}</p>
							{
								submittedNotificationStatus === 'Read' ? (
									<IconButton>
										<img src={notificationsRead} style={{ width: 20, height: 20 }} alt="" />
									</IconButton>
								) : (
									<IconButton onClick={() => updateNotification(submittedNotificationId)}>
										<DoneIcon style={{ width: 20, height: 20 }} />
									</IconButton>
								)
							}
						</div>
					</div>
					<div className='notification-card-body'>
						<p style={{ fontSize: 12 }}>{submittedCount}</p>
						<Link
							to={{
								pathname: "/review-applicants",
								state: location.state ? (
									{
										workflowId: location.state.workflowId,
										workflowName: location.state.workflowName,
										sidebar: "reviewApplicants"
									}
								) : {}
							}}>
							View full list
						</Link>
					</div>
				</div>
			) : ''
		)
	}

	const renderInvitedNotification = () => {
		return (
			location.state && location.state.workflowId ? (
				<div className='notification-card'>
					<div className='notification-card-topbar'>
						<div style={{ display: "flex", alignItems: "center" }}>
							<img src={submittedNotificationStatus == "read" ? notificationcircleGrey : notificationcircle} alt="" style={{ width: "20px", height: "20px" }} />
							<p style={{ marginLeft: "10px", fontSize: "18px", fontWeight: "600" }}>Invitations</p>
						</div>
						<div style={{ display: "flex", alignItems: "center" }}>
							<p style={{ marginRight: 50 }}>{moment(invitedNotificationTime).fromNow()}</p>
							{
								invitedNotificationStatus === 'Read' ? (
									<IconButton>
										<img src={notificationsRead} style={{ width: 20, height: 20 }} alt="" />
									</IconButton>
								) : (
									<IconButton onClick={() => updateNotification(invitedNotificationId)}>
										<DoneIcon style={{ width: 20, height: 20 }} />
									</IconButton>
								)
							}
						</div>
					</div>
					<div className='notification-card-body'>
						<p>{invitedCount}</p>
						<Link
							to={{
								pathname: "/new-applicants",
								state: location.state ? (
									{
										workflowId: location.state.workflowId,
										workflowName: location.state.workflowName,
										sidebar: "invitations"
									}
								) : {}
							}}>
							View full list
						</Link>
					</div>
				</div>
			) : ''
		)
	}

	return (
		<>
			{
				notifications.length > 0 ? (notifications.map((item) => (
					item.type === 'submitCount' ? (
						renderSubmittedNotification()
					) : (
						item.type === 'inviteCount' ? (
							renderInvitedNotification()
						) : (
							<div className='notification-card' key={item.notificationId}>
								<div className='notification-card-topbar'>
									<div style={{ display: "flex", alignItems: "center" }}>
										<img src={item.status == "read" ? notificationcircleGrey : notificationcircle} alt="" style={{ width: 20, height: 20 }} />
										<p style={{ marginLeft: 10, fontSize: 16, fontWeight: "600" }}>
											{
												item.type === 'candidateSubmit' ? 'Submission Received' : 'New Message'
											}
										</p>
									</div>
									<div style={{ display: "flex", alignItems: "center" }}>
										<p style={{ marginRight: 20, fontSize: 14 }}>{moment(item.createdDate).fromNow()}</p>
										{
											item.status === 'Read' ? (
												<IconButton style={{ width: 36, height: 36 }}>
													<img src={notificationsRead} style={{ width: 20 }} alt="" />
												</IconButton>
											) : (
												<IconButton onClick={() => updateNotification(item.notificationId)}>
													<DoneIcon style={{ width: 20, height: 20 }} />
												</IconButton>
											)
										}
									</div>
								</div>
								<div className='notification-card-body'>
									<p style={{ fontSize: 12 }}>{item.message || item.text}</p>
								</div>
							</div>
						)
					)
				))) : (
					<div>No notifications</div>
				)
			}
		</>
	)
}

AllNotificationTab.propTypes = {
	notifications: PropTypes.array,
	updateNotificationStatus: PropTypes.func
}

export default AllNotificationTab;
