import React, { useState } from 'react';

import '../../styles/toggleBtn.scss';

const SwitchBtn = (props) => {
    const { onChangeSwitch, activeBtn } = props;

    const onClickToggle = () => {
        onChangeSwitch(!activeBtn);
    }

    return (
        <div className={`toggle-btn ${activeBtn ? 'active' : ''}`} onClick={onClickToggle}>
            <div className="toggle-dot"></div>
        </div>
    )
}

export default SwitchBtn;