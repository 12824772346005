import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Grid } from '@material-ui/core';
import { Modal } from 'react-responsive-modal';

import DragAndDrop from './DragAndDrop';

import "../../../../styles/btn.css";
import "../../../../styles/modal.css";


let typoStyles = {
	color: "#000",
	fontSize: "16px",
	height: "100%",
	borderBottom: "",
	padding: "0px",
	margin: "2vh",
	lineHeight: "20px",
	userSelect: "none",
	MSUserSelect: "none",
	MozUserSelect: "none",
	WebkitUserSelect: "none",
	display: "flex",
	alignItems: 'center'
};

const MultiSelect = (props) => {
	const { messages, addToMessageBox, setNextQues, seeQues, setSeeQues, setProgress, addToExhibitObjs } = props;

	let maxNo = Number(messages.messages[0].qtype.replace(/\D/g, ''));
	if (maxNo === 0)
		maxNo = Number.MAX_VALUE;

	const [modal, setModal] = useState(false);
	const [optionCountModal, setOptionCountModal] = useState(false);
	const [confirmation] = useState(false);
	const [appState, changeAppState] = useState({
		activeObjects: new Set(),
		objects: messages.messages[0].options
	});
	const [dndMessages, setDndMessages] = useState({});
	const [dndMap, setDndMap] = useState([]);

	const onCloseModal = () => setModal(false);

	const onOpenOptionCountModal = () => setOptionCountModal(true);

	const onCloseOptionCountModal = () => setOptionCountModal(false);

	const sendToChatBox = (msglist, x, ques) => {
		addToMessageBox([msglist[x]]);

		if (x > 0)
			setTimeout(() => { sendToChatBox(msglist, x - 1, ques) }, 1500);
		else
			setNextQues(ques.reverse());
	}

	const toggleActive = (index) => {
		if (appState.activeObjects.has(index)) {
			let set = appState.activeObjects;
			set.delete(index);

			changeAppState({ ...appState, activeObjects: set });
			return "";
		}
		else {
			if (appState.activeObjects.size === maxNo && maxNo === 1) {
				let set = new Set();
				set.add(index);

				changeAppState({ objects: appState.objects, activeObjects: set });
				return "changed option";
			}
			else if (appState.activeObjects.size === maxNo) {
				onOpenOptionCountModal();
				return null;
			}

			let set = appState.activeObjects;
			set.add(index);

			changeAppState({ ...appState, activeObjects: set });
			return "selected"
		}
	}

	useEffect(() => {
		changeAppState({
			activeObjects: new Set(),
			objects: messages.messages[0].options
		})
	}, [messages]);

	useEffect(() => {
		console.log(appState);
		const newDnDMap = [];
		const newDndMessages = JSON.parse(JSON.stringify(messages));
		newDndMessages.messages[0].options = [];
		messages.messages[0].options.forEach((item, index) => {
			if(appState.activeObjects.has(index)) {
				newDndMessages.messages[0].options.push(item);
				newDnDMap.push({ "option": item, "index": index });
			}
		});
		console.log(dndMessages);
		console.log(newDnDMap);
		setDndMap([ ...newDnDMap ]);
		setDndMessages({ ...newDndMessages });
	}, [appState]);

	return (
		<div style={{ background: "#fff", borderRadius: "2vh", boxShadow: "0.1rem 0.5rem 1rem grey", width: "50vw", marginTop: "1vh" }}>
			<div style={{ display: seeQues ? "none" : "flex", flexDirection: "column" }}>
				{
					confirmation ?
						<Grid container spacing={6} className="options" style={{ listStyle: "none", padding: "2vh 10vw 5vh", display: "flex", justifyContent: 'center' }}>
							<div style={{ padding: "10px", cursor: "pointer", width: "100%", display: "flex", flexWrap: "wrap", justifyContent: 'center' }}>
								<p style={{ width: "100%", textAlign: "center", fontSize: "1.3vw", color: "#000", fontWeight: "bold" }}>Do you want to confirm these options? </p>
								{appState.objects.map((element, index = { index }) => {
									if (appState.activeObjects.has(index)) {
										return (
											<Grid
												key={index}
												xs={6}
												style={{ margin: "2vh 0 0" }}
											>
												<div style={typoStyles}>
													{element}
												</div>
											</Grid>
										)
									}

									else {
										return null;
									}
								})
								}</div>
						</Grid>
						:
						<Grid container spacing={6} className="options" style={{ listStyle: "none", padding: "2vh 4vw 5vh", display: "flex", marginTop: 0 }}>
							{appState.objects.map((element, index = { index }) => {
								return (
									<Grid
										key={index}
										xs={6}
										style={{ margin: "0" }}
									>
										<div style={{ ...typoStyles, borderBottom: index < appState.objects.length - 2 ? "1.5px solid #dfdfdf" : null }} onClick={() => { toggleActive(index) }}>
											{<img src={appState.activeObjects.has(index) ? "Eo_circle_light-blue_checkmark.svg.png" : "unselected.png"} width="25px" style={{ marginRight: "12.5px" }} alt='' />}
											{element}
										</div>
									</Grid>
								)
							})
							}
						</Grid>
				}
				{
					appState.activeObjects.size >= maxNo ? (
						<DragAndDrop
							messages={dndMessages}
							addToMessageBox={addToMessageBox}
							setNextQues={setNextQues}
							seeQues={false}
							setSeeQues={setSeeQues}
							setProgress={setProgress}
							addToExhibitObjs={addToExhibitObjs}
							map={dndMap}
						/>
					) : ''
				}
			</div>
			<div id="chat-form" className='justify-content-end' style={{ height: "70px", display: seeQues ? "none" : "flex", alignItems: "center", justifyContent: "flex-end" }}>
				<Modal open={modal} onClose={onCloseModal} center styles={{ modal: { borderRadius: "50px", padding: "40px" } }}>
					<p className="modal-text">
						Kindly select appropriate number of options.
					</p>
					<div className="button-div">
						<Button
							variant="contained"
							size="small"
							className="modal-button"
							onClick={onCloseModal}
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								fontSize: '16px',
								textTransform: 'none',
								marginLeft: "10px"
							}}
						>
							OK
						</Button>
					</div>
				</Modal>
				<Modal open={optionCountModal} onClose={onCloseOptionCountModal} center styles={{ modal: { borderRadius: "50px", padding: "40px" } }}>
					<p className="modal-text">
						Cannot select more than {maxNo}, first deselect an option.
					</p>
					<div className="button-div">
						<Button
							variant="contained"
							size="small"
							className="modal-button"
							onClick={onCloseOptionCountModal}
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								fontSize: '16px',
								textTransform: 'none',
								marginLeft: "10px"
							}}
						>
							OK
						</Button>
					</div>
				</Modal>
			</div>
			<div id="chat-form" style={{
				display: seeQues ? "flex" : "none", height: "70px", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", alignItems: "center", justifyItems:
					"center"
			}}>
				<button className='btn btn__secondary' onClick={() => { setSeeQues(false) }}>
					See Options
				</button>
			</div>
		</div>
	)
}

export default MultiSelect;
