import React from 'react';

const EmptyTab = (props) => {
	const { text } = props;
	
	return (
		<div
			style={{
				minHeight: "350px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}>
			<div
				style={{
					fontSize: "20px",
					fontWeight: "600",
					color: "#8F8F92",
					textAlign: "center",
					padding: "20px",
					boxShadow: "rgb(0 0 0 / 20%) 5px 5px 10px 5px",
					borderRadius: "30px"
				}}>
				{text}
			</div>
		</div>
	)
}

export default EmptyTab;
