import React, { useState } from 'react';
import { Button } from '@mui/material';

import axios from '../../../axios';
import { validateCreateNewDrive } from '../../../utils/validations';

const AddDefaultDrive = (props) => {
	const { goBack, bot, companyName, change, setChange, onClose, setSnackErr, handleSnackClick } = props;

	const [name, setName] = useState('');
	const [desc, setDesc] = useState('');

	const onChangeName = (e) => {
        setName(e.target.value);
    }

    const onChangeDesc = (e) => {
        setDesc(e.target.value);
    }

	const sendWorkflowDefault = () => {
        const errorResult = validateCreateNewDrive(name, desc);

        if (errorResult.isValid) {
            const uri = "/company/workflow/";
            const getData = {
                workflowName: name,
                workflowDescription: desc,
                productId: {
                    isMegacase: false,
                    isCustom: true,
                    customId: bot.productId
                },
                problem: bot.description,
                title: bot.name,
                companyName: companyName,
                assessmentTime: bot.assessmentTime
            }

            axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
                .then(() => {
                    setChange(change + 1);
                })
                .catch((err) => {
                    console.log(err);
                });

            onClose();
        } else {
            Object.keys(errorResult.errors).forEach((item) => {
                setSnackErr(errorResult.errors[item]);
            });
            handleSnackClick();
        }
    }

	return (
		<div className='default-form'>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
				<div className='back-btn' onClick={goBack}>
					<div></div>
				</div>
				<div className='title'>Create new drive</div>
			</div>
			<div>
				<input type="text" onChange={onChangeName} value={name} placeholder='Name of drive' id="testNewDriveFlow-selector-3" />
			</div>
			<div>
				<textarea onChange={onChangeDesc} value={desc} placeholder='Add description' id="testNewDriveFlow-selector-4" />
			</div>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button size="small" variant="contained" className='action' onClick={sendWorkflowDefault} style={{ padding: '10px 40px', borderRadius: 40 }} id="testNewDriveFlow-selector-5">Create</Button>
			</div>
		</div>
	)
}

export default AddDefaultDrive;
