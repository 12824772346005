import React, { useCallback, useRef, useState } from "react";
import { TourProvider } from '@reactour/tour'
import Button from '@mui/material/Button';
import Chatbox from '../components/chatbox/Chatbox'

const btnStyle = {
	background: '#fff',
	fontFamily: "Poppins",
	letterSpacing: "0.01em",
	borderRadius: '25px',
	padding: "2px 25px"
}

const baseStyle = {
	color: "#eee",
	fontSize: "12px",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	marginBottom: "10px"
}

function ChatboxPage() {
	const [current, setCurrent] = useState(0);

	const chatBoxRef = useRef();

	const styles = {
		popover: (base, state) => {
			return {
				...base,
				background: "linear-gradient(180deg, #215E80 0%, #077991 100%)",
				borderRadius: 40,
				paddingLeft: 35,
				paddingRight: 35,
				paddingTop: 20,
				paddingBottom: 20,
				maxWidth: 550,
				margin: 30,
				marginTop : current === 6 ? -20 : (current === 0 ? -25 : (current === 4 ? 15 : 30)),
				marginLeft : current === 0 ? -20 : 30,
				...doArrow(state.position, state.verticalAlign, state.horizontalAlign),
			}
		},
	}

	const opositeSide = {
		top: 'bottom',
		bottom: 'top',
		right: 'left',
		left: 'right',
	}

	function doArrow(position, verticalAlign, horizontalAlign) {
		if (!position || position === 'custom') {
			return {}
		}
		const side = 30
		const isVertical = position === 'top' || position === 'bottom'
		const width = isVertical ? side : side * 0.866
		const height = isVertical ? side * 0.866 : side

		const color = 'rgba(33, 95, 129, 1)'

		const spaceFromSide = 10

		const obj = isVertical ? {
			'borderLeft': `${width / 2}px solid transparent`, // CSS Triangle width
			'borderRight': `${width / 2}px solid transparent`, // CSS Triangle width
			[`border${position[0].toUpperCase()}${position.substring(1)}`]:
				`${height}px solid ${color}`, // CSS Triangle height
			[isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
				width + spaceFromSide, // space from side
			[opositeSide[position]]: -height,
		} : {
			'borderTop': `${height / 2}px solid transparent`, // CSS Triangle width
			'borderBottom': `${height / 2}px solid transparent`, // CSS Triangle width
			[`border${position[0].toUpperCase()}${position.substring(1)}`]:
				`${width}px solid ${color}`, // CSS Triangle height
			[isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
				height + spaceFromSide, // space from side
			[opositeSide[position]]: -width,
		};

		return {
			'&::after': {
				content: "''",
				width: 0,
				height: 0,
				position: 'absolute',
				...obj,
			},
		}
	}

	const generateStepContent = function (setCurrentStep, setIsOpen, heading, text, nextStep, prevStep) {
		return (
			<div style={{ color: "#fff" }}>
				<div
					style={baseStyle}>
					<h4 style={{ fontSize: '20px', marginRight: '200px' }}>{heading}</h4>
					<p>Step {nextStep} of 8</p>
				</div>
				<p>{text}</p>
				<br />
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<div
						className="focus-border-none"
						style={{
							color: "#eee",
							cursor: 'pointer',
							textDecoration: 'underline'
						}}
						onClick={() => {
							setIsOpen(false);
							chatBoxRef.current.getVideo();
						}}
					>
						Skip tour
					</div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{
							prevStep == -1 ? '' :
								<div
									style={{
										color: "#eee",
										marginRight: "10px",
										textDecoration: "none",
										fontWeight: "600",
										cursor: 'pointer'
									}}
									onClick={() => { setCurrentStep(prevStep); setCurrent(prevStep); }}
								>
									Back
								</div>
						}
						{
							nextStep == 8 ?
								<Button
									size="small"
									variant="contained"
									style={btnStyle}
									onClick={() => {
										setIsOpen(false);
										chatBoxRef.current.openTourFinishModal();
									}}
								>
									<span style={{ color: '#479BD2', fontSize: '16px', fontWeight: 'bold' }}>Finish</span>
								</Button>
								:
								<Button
									size="small"
									variant="contained"
									style={btnStyle}
									onClick={() => { setCurrentStep(nextStep); setCurrent(nextStep); }}
								>
									<span style={{ color: '#479BD2', fontSize: '16px', fontWeight: 'bold' }}>Next</span>
								</Button>
						}
					</div>
				</div>
			</div>
		)
	}

	const steps = [
		{
			selector: '.bot-first-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return generateStepContent(setCurrentStep, setIsOpen, 'Start the assessment', "Once you press 'Accept', your timer will start.", 1, -1)
			},
		},
		{
			selector: '.bot-second-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return generateStepContent(setCurrentStep, setIsOpen, 'Proctored Video', 'Your video is being recorded for proctoring purpose. Kindly allow access to your webcam when prompted by your browser.', 2, 0)
			},
		},
		{
			selector: '.bot-third-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return generateStepContent(setCurrentStep, setIsOpen, 'Exhibits', 'Shows the images, graphs and data tables shown to you throughout the assessment. You may need to refer to them more than once while answering the questions.', 3, 1)
			},
		},
		{
			selector: '.bot-forth-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return generateStepContent(setCurrentStep, setIsOpen, 'Progress bar', 'Helps you keep a track of your progress, which is based on time allocation per question, and not number of questions.', 4, 2)
			},
		},
		{
			selector: '.bot-fifth-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return generateStepContent(setCurrentStep, setIsOpen, 'Time left', 'The duration of this assessment is 30 minutes. Refer to the timer to keep track of the time remaining.', 5, 3)
			},
		},
		{
			selector: '.bot-sixth-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return generateStepContent(setCurrentStep, setIsOpen, 'Voice-enabled questions', 'Click unmute if you would like our bot to read the questions out loud.', 6, 4)
			},
		},
		{
			selector: '.bot-seventh-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return generateStepContent(setCurrentStep, setIsOpen, 'Support', 'Click here if you require any technical support.', 7, 5)
			},
		},
		{
			selector: '.bot-eighth-step',
			content: ({ setCurrentStep, setIsOpen }) => {
				return generateStepContent(setCurrentStep, setIsOpen, 'Submit assessment', 'If you wish to end the assessment early, you can “Submit”.Please note that you will not be able to take this assessment again.', 8, 6)
			},
		},
	]

	const removeSampleVideoExhibits = useCallback(() => {
		const sampleTourExhibit = document.getElementById('sample-tour-exhibits');
		if (sampleTourExhibit) {
			sampleTourExhibit.remove();
		}
		const sampleTourVideo = document.getElementById('sample-tour-video');
		if (sampleTourVideo) {
			sampleTourVideo.remove();
		}
		const userVideo = document.getElementById('user-video-flex');
		if (userVideo) {
			userVideo.style.display = "flex";
		}
	}, [])

	return (
		<div style={{ width: "100%", height: "100%", fontFamily: "Mulish" }}>
			<TourProvider
				steps={steps}
				showNavigation={false}
				showCloseButton={false}
				showButtons={false}
				showNumber={false}
				disableInteraction={true}
				showBadge={false}
				onClickMask={() => { }}
				styles={styles}
				beforeClose={removeSampleVideoExhibits}
			>
				<Chatbox removeSampleVideoExhibits={removeSampleVideoExhibits} ref={chatBoxRef} />
			</TourProvider>
		</div>
	);
}

export default ChatboxPage