import { withStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Button, Drawer, TextField } from '@material-ui/core'

// <======== Root Colors ========>
const backgroundPrimary = '#0A2463'
// const colorPrimary = '#FFFFFF'
// const backgroundSecondary = '#E3F8FD'
// const colorSecondary = '#0A2463'
// const textWhite = '#FFFFFF'
// const textPrimary = '#FDFDFD'
// const textHeading = '#FD5457'
// const textSecondary = '#CCCCCC'

//  /  <---------------------------------------------------------NAVBAR------------------------------------------------------------------->

// MAIN NAVBAR
export const NavAppBar = withStyles({
  root: {
    position: 'static',
    background:
      'linear-gradient(294.61deg, #2F55AF 2.5%, #0C95BA 29.8%, #10054D 101.57%);',
    // boxShadow: '0 5px 10px rgba(0, 0, 0, 0.4)',
    ['@media (max-width:992px)']: {
      position: 'relative',
      boxShadow: 'none',
    },
  },
})(AppBar)

// Secondary NavBar
// export const SecondaryNavAppBar = withStyles({
//   root: {
//     position: 'sticky',
//     backgroundColor: '#fff',
//     boxShadow: '0 5px 10px rgba(0, 0, 0, 0.4)',
//     ['@media (max-width:768px)']: {
//       position: 'relative',
//       boxShadow: 'none',
//     },
//   },
// })(AppBar)

export const NavToolBar = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})(Toolbar)

// NAVBAR DRAWER
export const NavDrawer = withStyles({
  root: {
    width: '30vw',
    textAlign: 'center',
    ['@media (max-width:600px)']: {
      width: '50vw',
    },
  },
  paper: {
    backgroundColor: backgroundPrimary,
    width: '30vw',
    borderRadius: '20px 20px 0 0',
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.4)',
    ['@media (max-width:600px)']: {
      width: '50vw',
    },
  },
})(Drawer)

// BUTTIONS
export const PrimaryButton = withStyles({
  root: {
    display: 'flex',
    padding: '10px 32px',
    backgroundColor: '#0A2463',
    fontWeight: '600',
    borderRadius: '50px',
    color: '#FFFFFF',
    fontSize: '1rem',
    filter: 'drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.35))',
    '&:hover': {
      backgroundColor: '#0b2e84',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

export const SecondaryButton = withStyles({
  root: {
    display: 'flex',
    padding: '10px 32px',
    backgroundColor: '#E3F8FD',
    fontWeight: '600',
    borderRadius: '50px',
    color: '#0A2463',
    filter: 'drop-shadow(3px 3px 3px rgba(10, 36, 99, 0.35))',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#badee6',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

// FORM INPUTS
export const PrimaryInput = withStyles({
  root: {
    width: '100%',

    '& .MuiInputBase-input': {
      padding: '1rem',
      paddingLeft: '0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: ' #cacaca',
    },

    // '&:hover fieldset': {
    //   borderColor: colorBranding,
    // },
    // '&.Mui-focused fieldset': {
    //   borderColor: colorBranding,
    // },

    // '& .MuiFormHelperText-root': {
    //   color: textHeading,
    // },
  },
})(TextField)
export const SecondaryInput = withStyles({
  root: {
    width: '40%',

    '& .MuiInputBase-input': {
      padding: '1rem',
      paddingLeft: '0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: ' #cacaca',
    },

    // '&:hover fieldset': {
    //   borderColor: colorBranding,
    // },
    // '&.Mui-focused fieldset': {
    //   borderColor: colorBranding,
    // },

    // '& .MuiFormHelperText-root': {
    //   color: textHeading,
    // },
  },
})(TextField)
