import React, {useRef, useEffect} from "react";

export default function VideoPreview({stream, status} ) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return <video ref={videoRef} width={300} height={300} autoPlay style={{display: status==="recording"?"flex":"none"}} />;
};